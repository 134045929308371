import React from 'react'
import styles from './Positions.module.css'

import PropTypes from 'prop-types'

import {
  TextField,
  Button,
  Grid
} from '@material-ui/core'

import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";

class PositionsForm extends React.Component {
  render() {
    return (
      <div>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12}>
            <Autocomplete
              value={this.props.updateBranchDept}
              id="department_id"
              size="small"
              disabled={!this.props.branchDepartments.length > 0}
              fullWidth
              name="branch_department_id"
              options={this.props.branchDepartments}
              getOptionLabel={(option) => option.branch_department_name || ''}
              getOptionSelected={(option, value) => {
                return option.branch_department_name === value.branch_department_name
              }}
              onChange={this.props.handleAutocomplete}
              renderInput={(params) => (
                <TextField {...params} name="user_ids" variant="outlined" label="Branch - Department" required />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              autoComplete="off"
              variant="outlined"
              required
              size="small"
              name="name"
              fullWidth="true"
              label="Name"
              onChange={this.props.onchange}
              value={this.props.item.name}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              autoComplete="off"
              variant="outlined"
              size="small"
              name="short_name"
              fullWidth="true"
              label="Short Name"
              onChange={this.props.onchange}
              value={this.props.item.short_name}
              id="short_name"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              autoComplete="off"
              // className={styles.textFields}
              variant="outlined"
              size="small"
              name="description"
              fullWidth="true"
              label="Description"
              onChange={this.props.onchange}
              value={this.props.item.description}
              id="description"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              autoComplete="off"
              variant="outlined"
              size="small"
              name="rank"
              fullWidth="true"
              type="number"
              label="Rank"
              onChange={this.props.onchange}
              value={this.props.item.rank}
              id="rank"
            />
          </Grid>
        </Grid>

        <div className={styles.actionButton}>
          <Button variant="outlined"
            disabled={!this.props.item.branch_department_id || !this.props.item.name || !this.props.item.short_name || !this.props.item.rank}
            id="position-submit" color="primary"
            onClick={() => this.props.submit()}>Save</Button>
        </div>
      </div>
    )
  }
}

export default PositionsForm

PositionsForm.propTypes = {
  onchange: PropTypes.func,
  handleAutocomplete: PropTypes.func,
  handleSwitch: PropTypes.func,
  item: PropTypes.object,
  submit: PropTypes.func,
  branchDepartments: PropTypes.array,
  updateBranchDept: PropTypes.object,
}
