import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import style from "./Annualization.module.css";
import SimplePopUp from "../../shared/SimplePopUp/SimplePopUp";
import PayrollSlip from "./PayrollSlip";

class AnnualizationTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPayrollModalOpen: false,
      modalTitle: "",
      selectedPayroll: {},
    };
    this.numberFormatter = this.numberFormatter.bind(this);
    this.togglePayrollModal = this.togglePayrollModal.bind(this);
  }

  numberFormatter(value) {
    return value
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  togglePayrollModal() {
    this.setState({
      isPayrollModalOpen: !this.state.isPayrollModalOpen,
    });
  }

  render() {
    const { isPayrollModalOpen, modalTitle, selectedPayroll } = this.state;
    const { payroll, rawAnnualPayroll, isSummary } = this.props;
    const employee = payroll[0];

    const tableHeaders = [
      "Inclusive Dates",
      "Basic",
      "Other Taxable",
      "Other Non-Taxable",
      "Gross Income",
      "Taxable Income",
      "SSS",
      "Pag-ibig",
      "PhilHealth",
      "Total Taxable",
      "Tax Withheld",
    ];

    const tableRows = payroll
      .slice(1)
      .sort((a, b) => a.payroll.date_from > b.payroll.date_from)
      .map((payrollRecord) => {
        const {
          id,
          other_deductions,
          other_income,
          employee_sss,
          employee_pagibig,
          employee_philhealth,
          employee_bir,
          taxable_income,
          total_deductions,
          payroll: employeePayroll,
        } = payrollRecord;

        return {
          id,
          inclusiveDates: `${moment(employeePayroll.date_from).format(
            "MMM DD"
          )} - ${moment(employeePayroll.date_to).format("DD, YYYY")}`,
          basic: this.numberFormatter(Number(taxable_income)),
          otherTaxable: this.numberFormatter(Number(other_deductions)),
          otherNonTaxable: this.numberFormatter(Number(other_income)),
          grossIncome: this.numberFormatter(
            Number(taxable_income) +
              Number(other_income) -
              Number(other_deductions)
          ),
          taxableIncome: this.numberFormatter(
            Number(taxable_income) - Number(other_deductions)
          ),
          sss: this.numberFormatter(Number(employee_sss)),
          pagIbig: this.numberFormatter(Number(employee_pagibig)),
          philHealth: this.numberFormatter(Number(employee_philhealth)),
          totalTaxable: this.numberFormatter(
            Number(taxable_income) - Number(total_deductions),
            2
          ),
          taxWithheld: this.numberFormatter(Number(employee_bir)),
        };
      });

    const tableFooter = [
      {
        inclusiveDates: "Year 2022",
        basic: this.numberFormatter(
          tableRows.reduce((prevVal, currentVal) => {
            return Number(prevVal) + Number(currentVal.basic.replace(",", ""));
          }, 0)
        ),
        otherTaxable: this.numberFormatter(
          tableRows.reduce((prevVal, currentVal) => {
            return (
              Number(prevVal) + Number(currentVal.otherTaxable.replace(",", ""))
            );
          }, 0)
        ),
        otherNonTaxable: this.numberFormatter(
          tableRows.reduce((prevVal, currentVal) => {
            return (
              Number(prevVal) +
              Number(currentVal.otherNonTaxable.replace(",", ""))
            );
          }, 0)
        ),
        grossIncome: this.numberFormatter(
          tableRows.reduce((prevVal, currentVal) => {
            return (
              Number(prevVal) + Number(currentVal.grossIncome.replace(",", ""))
            );
          }, 0)
        ),
        taxableIncome: this.numberFormatter(
          tableRows.reduce((prevVal, currentVal) => {
            return (
              Number(prevVal) +
              Number(currentVal.taxableIncome.replace(",", ""))
            );
          }, 0)
        ),
        sss: this.numberFormatter(
          tableRows.reduce((prevVal, currentVal) => {
            return Number(prevVal) + Number(currentVal.sss.replace(",", ""));
          }, 0)
        ),
        pagIbig: this.numberFormatter(
          tableRows.reduce((prevVal, currentVal) => {
            return (
              Number(prevVal) + Number(currentVal.pagIbig.replace(",", ""))
            );
          }, 0)
        ),
        philHealth: this.numberFormatter(
          tableRows.reduce((prevVal, currentVal) => {
            return (
              Number(prevVal) + Number(currentVal.philHealth.replace(",", ""))
            );
          }, 0)
        ),
        totalTaxable: this.numberFormatter(
          tableRows.reduce((prevVal, currentVal) => {
            return (
              Number(prevVal) + Number(currentVal.totalTaxable.replace(",", ""))
            );
          }, 0)
        ),
        taxWithheld: this.numberFormatter(
          tableRows.reduce((prevVal, currentVal) => {
            return (
              Number(prevVal) + Number(currentVal.taxWithheld.replace(",", ""))
            );
          }, 0)
        )
      },
    ];

    return (
      <>
        <Typography variant="h2" className={style.tableTitle}>
          {`${employee.referenceId} ${employee.name}`}
        </Typography>
        <Table>
          <TableHead>
            <TableRow style={{ borderBlock: "1px solid #e9e9e9" }}>
              {tableHeaders.map((header, idx) => (
                <TableCell key={idx} className={style.theadCell}>
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableRows.length ? (
              isSummary ? (
                <TableRow />
              ) : (
                tableRows.map((cutOff, idx) => {
                  return (
                    <TableRow
                      key={idx}
                      className={style.tableRows}
                      onClick={() => {
                        this.togglePayrollModal();
                        this.setState({
                          modalTitle: `${employee.name} / ${employee.referenceId}`,
                          selectedPayroll: rawAnnualPayroll.find(
                            (payroll) => payroll.id === cutOff.id
                          ),
                        });
                      }}
                    >
                      {Object.entries(cutOff).map((value, idy) => {
                        if (value[0] === "id") return null;

                        return (
                          <TableCell
                            align="right"
                            key={idy}
                            style={{ paddingBlock: "5px", fontSize: "13px" }}
                          >
                            {value[1]}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })
              )
            ) : (
              <TableRow>
                <TableCell align="left" colSpan={12}>
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            {tableFooter.map((footer, idx) => {
              return (
                <TableRow key={idx}>
                  {Object.values(footer).map((value, idy) => {
                    return (
                      <TableCell
                        align="right"
                        key={idy}
                        className={isSummary ? style.summaryCell : style.importantTableCell}
                      >
                        {value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
            {isSummary ? (
              ""
            ) : (
              <>
                <TableRow>
                  <TableCell
                    align="right"
                    colSpan={9}
                    className={style.cellLabel}
                  >
                    Less 50,000
                  </TableCell>
                  <TableCell align="right" className={style.cellLabel}>
                    -50,000.00
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    align="right"
                    colSpan={9}
                    className={style.cellLabel}
                  >
                    Sums up to
                  </TableCell>
                  <TableCell
                    align="right"
                    className={style.cellComputationValue}
                  >
                    0.00
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    align="right"
                    colSpan={9}
                    className={style.emphasizedTableCell}
                  >
                    Annual Tax Due vs Tax withheld
                  </TableCell>
                  <TableCell align="right" className={style.importantTableCell}>
                    0.00
                  </TableCell>
                  <TableCell align="right" className={style.importantTableCell}>
                    0.00
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    align="right"
                    colSpan={9}
                    className={style.emphasizedTableCell}
                  >
                    Income Tax Return
                  </TableCell>
                  <TableCell align="right" className={style.importantTableCell}>
                    0.00
                  </TableCell>
                </TableRow>
              </>
            )}
          </TableFooter>
        </Table>
        <SimplePopUp
          openPopup={isPayrollModalOpen}
          title={modalTitle}
          handleClose={this.togglePayrollModal}
          maxWidth={"md"}
          withPrint={true}
          print={this.printEmployeePayslip}
        >
          <PayrollSlip selectedPayroll={selectedPayroll} />
        </SimplePopUp>
      </>
    );
  }
}

export default AnnualizationTable;

AnnualizationTable.propTypes = {
  payroll: PropTypes.array,
  rawAnnualPayroll: PropTypes.array,
  isSummary: PropTypes.bool,
};
