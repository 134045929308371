import React from 'react'
import styles from './Accounts.module.css'

import PropTypes from 'prop-types'

import {
  TextField,
  Button,
  MenuItem, Grid, InputAdornment
} from '@material-ui/core'

class AccountsForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      statuses: [
        {
          name: 'Active',
          value: 'active'
        },
        {
          name: 'Receivable',
          value: 'receivable'
        },
        {
          name: 'Installment',
          value: 'installment'
        },
        {
          name: 'Cash',
          value: 'cash'
        },
        {
          name: 'Input Tax',
          value: 'input_tax'
        },
        {
          name: 'Output Tax',
          value: 'output_tax'
        },
        {
          name: 'Withholding Tax',
          value: 'with_holding_tax'
        },
        {
          name: 'Inactive',
          value: 'inactive'
        },
      ]
    }
  }

  render() {
    return (
      <div>
        <form>
          <Grid container spacing={1}>
            <Grid item xs={12} lg={12}>
            <TextField
                autoComplete="off"
                className={styles.textFields}
                variant="outlined"
                size="small"
                name="name"
                fullWidth="true"
                label="Name"
                onChange={this.props.onchange}
                value={this.props.item.name}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
            <TextField
              autoComplete="off"
              className={styles.codeTextField}
              variant="outlined"
              size="small"
              name="code"
              align="right"
              fullWidth="true"
              label="Code"
              onChange={this.props.onchange}
              value={this.props.item.code}
              InputProps={{
                endAdornment: <InputAdornment className={styles.refInput} ></InputAdornment>,
              }}
            />
          </Grid>
            <Grid item xs={12} lg={6}>
            <TextField
                autoComplete="off"
                select
                className={styles.textFields}
                variant="outlined"
                size="small"
                name="type"
                fullWidth={true}
                label="Account Type"
                id="accounts_name"
                onChange={this.props.onchange}
                value={this.props.item.type}
                required={true}
              >
                <MenuItem value="Caes::Asset">Asset</MenuItem>
                <MenuItem value="Caes::Expense">Expense</MenuItem>
                <MenuItem value="Caes::Liability">Liability</MenuItem>
                <MenuItem value="Caes::Revenue">Revenue</MenuItem>
                <MenuItem value="Caes::Capital">Capital</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                autoComplete="off"
                select
                className={styles.textFields}
                variant="outlined"
                size="small"
                name="status"
                id="accounts_status"
                fullWidth={true}
                label="Status"
                onChange={this.props.onchange}
                value={this.props.item.status}
                defaultValue={'active'}
              >
                {
                  this.state.statuses.map((status, idx) => {
                    const { name, value } = status

                    return <MenuItem key={idx} value={value}>{name}</MenuItem>
                  })
                }
              </TextField>
            </Grid>
          </Grid>
        </form>

        <div className={styles.actionButton}>
          <Button variant="outlined"
                  disabled={!this.props.item.name || !this.props.item.type}
                  id="account-submit" color="primary"
                  onClick={() => this.props.submit()}>Save</Button>
        </div>
      </div>
    )
  }
}

export default AccountsForm

AccountsForm.propTypes = {
  onchange: PropTypes.func,
  handleAutocomplete: PropTypes.func,
  handleSwitch: PropTypes.func,
  accounts: PropTypes.array,
  productTypes: PropTypes.array,
  item: PropTypes.object,
  updateAccount: PropTypes.object,
  amountChange: PropTypes.func,
  submit: PropTypes.func,
  zeroFill: PropTypes.string
}
