import React from "react";
import PropTypes from "prop-types";
import ScrollToTop from "react-scroll-to-top";
import styles from "./Scroll.module.css";
import PublishIcon from "@material-ui/icons/Publish";

class Scroll extends React.Component {
  render() {
    return (
      <div className={this.props.forPrint ? styles.noDisplay : styles.scroll}>
        <ScrollToTop smooth component={<PublishIcon />} />
      </div>
    );
  }
}

export default Scroll;

Scroll.propTypes = {
  forPrint: PropTypes.bool,
};
