import * as React from 'react';
import {
  TextField,
  Button,
} from '@material-ui/core'
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import styles from './Permision.module.css'
import Autocomplete from "@material-ui/lab/Autocomplete";

import PropTypes from 'prop-types'
class PermissionNewForm extends React.Component {
  render() {
    return (
      <div>
        <Grid container spacing={1}>
          <Grid item xs={6} >
            <TextField
              autoComplete="off"
              variant="outlined"
              className={styles.textFields}
              size="small"
              name="name"
              fullWidth
              label="Name"
              onChange={this.props.handleInputChange}
              value={this.props.item.name}
              id="department-name"
            />
          </Grid>
          <Grid item xs={6} >
            <Autocomplete
              className={styles.autocomplete}
              value={this.props.updateUsers}
              multiple
              id="user_ids"
              size="small"
              options={this.props.users}
              getOptionLabel={(option) => option.name}
              getOptionSelected={(option, value) => option.uid === value.uid}
              onChange={this.props.handleUsersAutocomplete}

              renderInput={(params) => (
                <TextField {...params} name="User" variant="outlined" label="Users" />
              )}
            />
          </Grid>
          {Object.keys(this.props.nameModel).map((item) => (
            <Grid item xs={4} key={item}>
              <div className={styles.modelBody}>

                <fieldset className={item === 'null' ? styles.displayNone : styles.formFieldBody}>
                  <legend> <b>{item === "null" ? 'Manage All' : item.replace("Caes::", '').replace("::", ' ').replace(/([A-Z])/g, ' $1')}</b>
                    {item === "null" ? null :
                      <FormControlLabel key={item}
                        control={<Checkbox
                          checked={this.props.selectedRoles.filter(roles => roles.resource_type === item).length === this.props.nameModel[item].length}
                          onClick={(event) => this.props.handleChangeParentCheck(event, item)}
                        />}
                        className={styles.parentLabel}
                      />
                    }
                  </legend>
                  <Grid container>
                    {this.props.nameModel[item].map((test, i) => (
                      <Grid item xs={6} key={i}>
                        <FormControlLabel key={item.id} style={{ textTransform: 'capitalize' }}
                          control={<Checkbox
                            checked={this.props.selectedRoles.indexOf(test) > -1}
                            onClick={(event) => this.props.handleToggle(event, test)}
                          />}
                          label={test.name.replace("_", " ").replace("_", " ")}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </fieldset>
              </div>

            </Grid>
          ))}
        </Grid>
        <div className={styles.actionButton}>
          <a href="/permissions/">
            <Button variant="outlined"> Back </Button>
          </a>
          <Button variant="outlined" id="department-submit" color="primary"
            onClick={() => this.props.handleSubmit()}>Save</Button>
        </div>
      </div>
    )
  }
}

export default PermissionNewForm

PermissionNewForm.propTypes = {
  users: PropTypes.array,
  roles: PropTypes.array,
  updateRoles: PropTypes.array,
  updateUsers: PropTypes.array,
  nameModel: PropTypes.array,
  selectedRoles: PropTypes.array,
  handleUsersAutocomplete: PropTypes.func,
  handleInputChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  handleRolesAutocomplete: PropTypes.func,
  handleChangeParentCheck: PropTypes.func,
  handleToggle: PropTypes.func,
  item: PropTypes.object,
}
