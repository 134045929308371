import React from 'react'

import PropTypes from 'prop-types'
import styles from './Attendances.module.css'
import {
    Button
} from '@material-ui/core'
import VerticalAlignBottomIcon from '@material-ui/icons/VerticalAlignBottom';


class ImportAttendanceForm extends React.Component {
    render() {
        const { file } = this.props.attachment;
        let filename = null;
        filename = file
            ? (<span><b>File Selected</b> - {file.name}</span>)
            : (<span>Drag your file here or click in this area to browse files.</span>);

        return (

            <div>
                <form>
                  <div className={styles.formBody}>
                    <input
                        id="file"
                        type="file"
                        name="file"
                        accept=".csv"
                        className={styles.input}
                        onChange={(e) => this.props.onFileChange(e)}
                    />
                    <label className={styles.filelabel}
                        htmlFor="file">
                        <VerticalAlignBottomIcon fontSize="large" />
                        {filename}
                    </label>
                    <p ><b>{this.props.error}</b></p>
                  </div>
                </form>
                <div className={styles.actionButton} >
                    <Button name="photo-submit" id="photo-submit"
                        variant="outlined" color="primary" onClick={() => this.props.submit()}>Save</Button>
                </div>
            </div>
        )
    }
}

export default ImportAttendanceForm

ImportAttendanceForm.propTypes = {
    onFileChange: PropTypes.func,
    attachment: PropTypes.object,
    children: PropTypes.object,
    submit: PropTypes.func,
    error: PropTypes.string,
    handleFileInputChange: PropTypes.func,
    onchange: PropTypes.func,
    handleCheck: PropTypes.func
}
