
import React from 'react'
import styles from './Employees.module.css'

import PropTypes from 'prop-types'

import {
    TextField,
    Button,MenuItem
} from '@material-ui/core'
class EmploymentStatusForm extends React.Component {
    render() {
        return (
            <div>
                <form>
                    <div className={styles.flex}>
                        <TextField
                            type="date"
                            autoComplete="off"
                            className={styles.leftTextField}
                            variant="outlined"
                            size="small"
                            name="date_effective"
                            fullWidth={true}
                            label="Date Effective"
                            onChange={this.props.handleEmploymentChange}
                            value={this.props.employment_statuses_attributes.date_effective}
                            id="employment_statuses_attributes-date_effective"
                            InputLabelProps={{
                                shrink: true
                            }}
                        />
                        <TextField
                            autoComplete="off"
                            className={styles.rightTextField}
                            variant="outlined"
                            size="small"
                            name="kind"
                            fullWidth
                            select
                            label="Kind"
                            onChange={this.props.handleEmploymentChange}
                            value={this.props.employment_statuses_attributes.kind}
                            id="employee-kind"
                        >
                            {
                                this.props.kinds.length > 0 ? (
                                    this.props.kinds.map((kind, idx) => (
                                        <MenuItem className={styles.capitalize} value={kind} key={idx}>{kind}</MenuItem>
                                    ))
                                ) : null
                            }
                        </TextField>
                    </div>
                    <div className={styles.flex}>
                        <TextField
                            autoComplete="off"
                            className={styles.leftTextField}
                            variant="outlined"
                            size="small"
                            name="state"
                            fullWidth
                            select
                            label="State"
                            onChange={this.props.handleEmploymentChange}
                            value={this.props.employment_statuses_attributes.state}
                            id="employee-state"
                        >
                            {
                                this.props.states.length > 0 ? (
                                    this.props.states.map((state, idx) => (
                                        <MenuItem className={styles.capitalize} value={state} key={idx}>{state}</MenuItem>
                                    ))
                                ) : null
                            }

                        </TextField>
                        <TextField
                            autoComplete="off"
                            className={styles.rightTextField}
                            variant="outlined"
                            size="small"
                            name="remarks"
                            fullWidth
                            label="Remarks"
                            onChange={this.props.handleEmploymentChange}
                            value={this.props.employment_statuses_attributes.remarks}
                            id="employment_statuses_attributes-remarks"
                        />
                    </div>
                </form>
                <div className={styles.actionButton}>
                    <Button variant="outlined" id="ApprovingOfficer-submit" color="primary" onClick={() => this.props.submit()}
                     disabled={!this.props.employment_statuses_attributes.date_effective || !this.props.employment_statuses_attributes.kind || !this.props.employment_statuses_attributes.state ||  this.props.employment_statuses_attributes.remarks}>Save</Button>
                </div>
            </div>
        )
    }
}
export default EmploymentStatusForm

EmploymentStatusForm.propTypes = {
    employment_statuses_attributes: PropTypes.object,
    submit: PropTypes.func,
    handleEmploymentChange: PropTypes.func,
    kinds: PropTypes.array,
    states: PropTypes.array,
}
