// eslint-disable-next-line

import React from 'react'

import SimpleTable from '../../shared/SimpleTable/SimpleTable'
import SimplePopUp from '../../shared/SimplePopUp/SimplePopUp'
import SimpleDeletePopUp from '../../shared/SimpleDeletePopUp/SimpleDeletePopUp'
import PayrollListForm from './PayrollListForm'
import ListAltOutlinedIcon from '@material-ui/icons/ListAltOutlined';
import axios from 'axios'
import {Redirect} from 'react-router'
import AlertMessage from "../../shared/Notify/AlertMessage";
import ProgressBar from "../../shared/ProgressBar/ProgressBar";
import moment from "moment";
class PayrollList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      model: 'Payroll',
      payrollLists: [],
      updateAccount: {},
      banks: [],
      bank: {},
      branches: [],
      branch: {},
      payrollTypes: [],
      openPopup: false,
      openDeletePopup: false,
      payrollList: {},
      initialPayrollList: {id: '', date_from: '', date_to: '', bir: true, pagibig: false, philhealth: false, sss: false, bank_id: '', branch_id: ''
      },
      redirect: false,
      payrollId: '',
      title: '',
      load: false,
      tableHead: ['date_from', 'date_to', 'payroll_type', 'status'],
      withShow: false,
      withEdit: true,
      banksAutoCompleteLoading: false,
      branchesAutoCompleteLoading: false,
      maxWidth: 'sm',
      error_messages: '',
      notify: {},
      isOpen: false,
      message: '',
      type: ''
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleDeleteItem = this.handleDeleteItem.bind(this)
    this.handleCreateorUpdateItem = this.handleCreateorUpdateItem.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleOpenDeletePopup = this.handleOpenDeletePopup.bind(this)
    this.handleDeleteClose = this.handleDeleteClose.bind(this)
    this.handleCloseAlert = this.handleCloseAlert.bind(this)
    this.handleSwitch = this.handleSwitch.bind(this)
    this.handleAutocomplete = this.handleAutocomplete.bind(this)
    this.handleAutocompleteBank = this.handleAutocompleteBank.bind(this)
    this.loadBanksAutoCompleteOptions = this.loadBanksAutoCompleteOptions.bind(this)
    this.handleAutocompleteOnInputChange = this.handleAutocompleteOnInputChange.bind(this)
    this.handleAutocompleteBranch = this.handleAutocompleteBranch.bind(this)
  }

  componentDidMount() {
    this.loadpayrollLists('/v1/payrolls')
  }

  loadpayrollLists(url) {
    axios({
      method: 'get',
      url: url,
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then(resp => {
      this.setState({payrollLists: resp.data.sort(function (a, b) {
          if (a.created_at < b.created_at) return -1;
          if (a.created_at > b.created_at) return 1;
          return 0;
        }) })
      this.setState({load: true})
    }).catch(error => console.log(error.response))

    axios({
      method: 'get',
      url: '/v1/payrolls/types',
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {
      this.setState({ payrollTypes: resp.data })
    }).catch(error => {
      if (error.response.status === 422) {
        this.setState({ error_messages: 'Name ' + error.response.data.errors.name[0] })
      } else {
        this.setState({
          isOpen: true,
          message: error.response.status.toString() + " Unexpected Error Problem Occurred",
          type: 'error'
        })
        this.setState({ openPopup: false })
      }
    })
  }

  handleSubmit() {
    const item = this.state.payrollList
    var method = ''
    var url = ''
    if (item.id === '') {
      method = 'post'
      url = '/v1/payrolls/'
    } else {
      method = 'put'
      url = '/v1/payrolls/' + item.id
    }
    
    axios({
      method: method,
      url: url,
      data: (item),
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then(resp => {

      const newData = resp.data
      this.setState({
        payrollLists: this.state.payrollLists.filter(payrollList => payrollList.id !== item.id)
      })
      this.setState({payrollLists: [...this.state.payrollLists, newData]})
      this.setState({payrollId: newData.id})
      this.setState({redirect: true})
      this.setState({openPopup: false})
      this.setState({
        isOpen: true,
        message: 'Submitted Successfully',
        type: 'success'
      })
    }).catch(error => {
      if (error.response.status === 422) {
        this.setState({error_messages: 'Name ' + error.response.data.errors.name[0]})
      } else {
        this.setState({
          isOpen: true,
          message: error.response.status.toString() + " Unexpected Error Problem Occurred",
          type: 'error'
        })
        this.setState({openPopup: false})
      }
    })
  }

  handleDeleteItem() {
    const DeleteItemId = this.state.payrollList.id
    axios({
      method: 'delete',
      url: '/v1/payrolls/' + DeleteItemId,
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then(() => {
      this.setState({
        payrollLists: this.state.payrollLists.filter(item => item.id !== DeleteItemId)
      })
      this.setState({openDeletePopup: false})
      this.setState({
        isOpen: true,
        message: 'Deleted Successfully',
        type: 'error'
      })
    }).catch(error => console.log(error.response))
  }

  handleClose() {
    this.setState({openPopup: false})
    this.setState({openDeletePopup: false})
    this.setState({error_messages: '', bank: {}, banks: [], branch: {}, branches: []})
  }

  handleCreateorUpdateItem(item, isAdd, model) {
    var textTitle = ''
    var newItem = item
    var sortedArray = []
    var lastObj = {}
    var lastDay = ''
    if (isAdd) {
      textTitle = 'Create ' + model
      this.loadBanksAutoCompleteOptions()
      this.loadBranchesAutoCompleteOptions()
      sortedArray = this.state.payrollLists.sort(function (a, b) {
        if (a.created_at > b.created_at) return -1;
        if (a.created_at < b.created_at) return 1;
        return 0;
      })

      lastObj = sortedArray[0] !== undefined ? sortedArray[0] :  {date_to: ''}
      lastDay = moment(lastObj.date_to).add(1, 'days').format("YYYY-MM-DD")
      if(parseInt(new Date(lastDay).toLocaleDateString('en-Us',{day:'numeric'})) === 16 ){
        newItem["date_from"] =lastDay
        newItem["date_to"] = moment(lastDay).endOf('month').format("YYYY-MM-DD")
      }
      else{
        newItem["date_from"] =  moment(new Date(lastDay)).startOf('month').format('YYYY-MM-DD')
        newItem["date_to"] = moment(new Date(lastDay)).add(0, 'months').startOf('month').add(14,'days').format('YYYY-MM-DD')
        newItem["pagibig"]=true
        newItem["philhealth"]=true
        newItem["sss"]=true
      }
    } else {
      textTitle = 'Edit ' + model
      this.loadBanksAutoCompleteOptions()
      this.loadBranchesAutoCompleteOptions()
      axios({
        method: 'get',
        url: '/v1/banks/' + item.bank_id,
        headers: { 'X-API-ACCESS-TOKEN':  localStorage.getItem('api_key')}
      }).then(resp => {
        this.setState({ bank: resp.data })
      }).catch(error => console.log(error.response))
      axios({
        method: 'get',
        url: '/v1/branches/' + item.branch_id,
        headers: { 'X-API-ACCESS-TOKEN':  localStorage.getItem('api_key')}
      }).then(resp => {
        this.setState({ branch: resp.data })
      }).catch(error => console.log(error.response))
      
    }
    this.setState({openPopup: true})
    this.setState({payrollList: newItem})
    this.setState({title: textTitle})
  }

  handleInputChange(e) {
    if (e.target.name === 'date_from') {
      this.setState({
        payrollList: {
          ...this.state.payrollList,
          [e.target.name]: e.target.value
        }
      })
    } else {

      this.setState({
        payrollList: {
          ...this.state.payrollList,
          [e.target.name]: e.target.value
        }
      })
    }
  }

  handleOpenDeletePopup(item, model) {
    this.setState({model: model})
    this.setState({payrollList: item})
    this.setState({openDeletePopup: true})
  }

  handleDeleteClose() {
    this.setState({openDeletePopup: false})
  }

  handleCloseAlert(event, reason) {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({isOpen: false})
  }

  handleSwitch(event) {
    const value = event.target.checked
    this.setState({
      payrollList: {
        ...this.state.payrollList,
        [event.target.name]: value
      }
    })
  }

  handleAutocomplete(event, values) {
    if (values !== null) {
      this.setState({updateAccount: values})

      this.setState({
        payrollList: {
          ...this.state.payrollList,
          account_id: values.id
        }
      })
    }
  }

  loadBanksAutoCompleteOptions(searchText = '') {
    axios({
      method: 'get',
      url: '/v1/banks/search',
      headers: { 'X-API-ACCESS-TOKEN':  localStorage.getItem('api_key')},
      params: { name: searchText }
    }).then(resp => {
      this.setState({ banks: resp.data.banks, banksAutoCompleteLoading: false })
    }).catch(error => console.log(error.response))
  }

  handleAutocompleteBank(event, value) {
    if (event.target.id.includes("bank")) {
      this.setState({ bank: value || {}, payrollList: { ...this.state.payrollList, bank_id: ![null, undefined].includes(value) ? (value.id) : '' } })
    }
  }

  loadBranchesAutoCompleteOptions(searchText = '') {
    axios({
      method: 'get',
      url: '/v1/branches',
      headers: { 'X-API-ACCESS-TOKEN':  localStorage.getItem('api_key')},
      params: { name: searchText }
    }).then(resp => {
      this.setState({ branches: resp.data, branchesAutoCompleteLoading: false })
    }).catch(error => console.log(error.response))
  }

  handleAutocompleteBranch(event, value) {
    if (event.target.id.includes("branch")) {
      this.setState({ branch: value || {}, payrollList: { ...this.state.payrollList, branch_id: ![null, undefined].includes(value) ? (value.id) : '' } })
    }
  }

  handleAutocompleteOnInputChange(event, value, reason) {
    if (reason === 'input') {
      if (event.target.id.includes("bank")) {
        this.setState({ banksAutoCompleteLoading: true })
        this.loadBanksAutoCompleteOptions(value || '')
      }
      if (event.target.id.includes("branch")) {
        this.setState({ branchesAutoCompleteLoading: true })
        this.loadBranchesAutoCompleteOptions(value || '')
      }
    }
  }

  render() {
    const {redirect} = this.state
    if (redirect)
      return (
        <Redirect to={{
          pathname: '/payrolls/' + this.state.payrollId + '/payroll_details'
        }}/>)
    return (
      <>
        {
          this.state.load ? (
            <div>
              <SimpleTable
                handleClick={this.handleCreateorUpdateItem}
                initialItem={this.state.initialPayrollList}
                items={this.state.payrollLists.sort(
                  (a, b) => new Date(a.date_from).getTime() - new Date(b.date_from).getTime()
              ).reverse()}
                model={this.state.model}
                onOpenDeletePopup={this.handleOpenDeletePopup}
                headers={this.state.tableHead}
                withShow={this.state.withShow}
                withEdit={this.state.withEdit}
                icon={<ListAltOutlinedIcon fontSize="large"/>}
              />

              <SimplePopUp
                openPopup={this.state.openPopup}
                title={this.state.title}
                handleClose={this.handleClose}
                maxWidth={this.state.maxWidth}
              >
                <PayrollListForm
                  error={this.state.error_messages}
                  item={this.state.payrollList}
                  accounts={this.state.accounts}
                  updateAccount={this.state.updateAccount}
                  registryTypes={this.state.registryTypes}
                  branches={this.state.branches}
                  branch={this.state.branch}
                  branchesAutoCompleteLoading={this.state.branchesAutoCompleteLoading}
                  handleAutocompleteBranch={this.handleAutocompleteBranch}
                  banks={this.state.banks}
                  bank={this.state.bank}
                  banksAutoCompleteLoading={this.state.banksAutoCompleteLoading}
                  handleSwitch={this.handleSwitch}
                  handleAutocomplete={this.handleAutocomplete}
                  handleAutocompleteBank={this.handleAutocompleteBank}
                  handleAutocompleteOnInputChange={this.handleAutocompleteOnInputChange}
                  submit={this.handleSubmit}
                  onchange={this.handleInputChange}
                  payrollTypes={this.state.payrollTypes}
                  />
              </SimplePopUp>
              <SimpleDeletePopUp
                openDeletePopup={this.state.openDeletePopup}
                item={this.state.payrollList}
                delete={this.handleDeleteItem}
                handleDeleteClose={this.handleClose}
                model={this.state.model}
              />
              <AlertMessage
                notify={this.state.notify}
                handleCloseAlert={this.handleCloseAlert}
                isOpen={this.state.isOpen}
                type={this.state.type}
                message={this.state.message}
              />
            </div>
          ) : (
            <ProgressBar model={this.state.model}/>
          )}
      </>
    )
  }
}

export default PayrollList
