// eslint-disable-next-line


import React from 'react'

import SimpleTable from '../../shared/SimpleTable/SimpleTable'
import SimplePopUp from '../../shared/SimplePopUp/SimplePopUp'
import SimpleDeletePopUp from '../../shared/SimpleDeletePopUp/SimpleDeletePopUp'
import PositionsForm from './PositionsForm'
import RecentActorsOutlinedIcon from '@material-ui/icons/RecentActorsOutlined';
import axios from 'axios'
import AlertMessage from "../../shared/Notify/AlertMessage";
import ProgressBar from "../../shared/ProgressBar/ProgressBar";

class Positions extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      model: 'Position',
      positions: [],
      branches: [],
      departments: [],
      branchDepartments: [],
      updateBranchDept: {},
      openPopup: false,
      openDeletePopup: false,
      position: {},
      initialPositions: {
        id: '',
        name: '',
        short_name: '',
        description: '',
        rank: '',
        branch_department_id: ''
      },
      title: '',
      load: false,
      tableHead: ['branch.name', 'department.name', 'Name', 'short_name', 'description', 'Rank'],
      withShow: false,
      withEdit: true,
      maxWidth: 'sm',
      error_messages: '',
      notify: {},
      isOpen: false,
      message: '',
      type: ''
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleDeleteItem = this.handleDeleteItem.bind(this)
    this.handleCreateorUpdateItem = this.handleCreateorUpdateItem.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleOpenDeletePopup = this.handleOpenDeletePopup.bind(this)
    this.handleDeleteClose = this.handleDeleteClose.bind(this)
    this.handleCloseAlert = this.handleCloseAlert.bind(this)
    this.handleAutocomplete = this.handleAutocomplete.bind(this)
  }

  componentDidMount() {
    this.loadPositions('/v1/positions')
  }

  loadPositions(url) {
    if(localStorage.getItem('positions') !== null){
      this.setState({positions: JSON.parse(localStorage.getItem('positions')), load: true})
    }else{
    axios({
      method: 'get',
      url: url,
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then(resp => {
      this.setState({positions: resp.data})
      localStorage.setItem('positions',JSON.stringify(resp.data))   
    }).catch(error => console.log(error.response))
  }

  if(localStorage.getItem('branches') !== null){
    this.setState({branches: JSON.parse(localStorage.getItem('branches')), load: true})
  }else{
    axios({
      method: 'get',
      url: '/v1/branches',
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then(resp => {
      this.setState({branches: resp.data})
      localStorage.setItem('branches',JSON.stringify(resp.data))
    }).catch(error => console.log(error.response))
  }

  if(localStorage.getItem('branchDepartments') !== null){
    this.setState({ branchDepartments: JSON.parse(localStorage.getItem('branchDepartments')), load: true })
  } else {
      axios({
        method: 'get',
        url: '/v1/branch_departments',
        headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
      }).then(resp => {
        this.setState({ branchDepartments: resp.data, load: true})
        localStorage.setItem('branchDepartments', JSON.stringify(resp.data))
      }).catch(error => console.log(error.response))
  }    
  }

  handleSubmit() {
    const item = this.state.position
    var method = ''
    var url = ''
    if (item.id === '') {
      method = 'post'
      url = '/v1/positions/'
    } else {
      method = 'put'
      url = '/v1/positions/' + item.id
    }

    axios({
      method: method,
      url: url,
      data: (item),
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {

      const newData = resp.data
      this.setState({
        positions: this.state.positions.filter(position => position.id !== item.id)
      })
      this.setState({ positions: [...this.state.positions, newData] })
      localStorage.setItem('positions', JSON.stringify([...this.state.positions, newData]))
      this.setState({ openPopup: false })
      this.setState({
        isOpen: true,
        message: 'Submitted Successfully',
        type: 'success'
      })
    }).catch(error => {
      if (error.response.status === 422) {
        this.setState({ error_messages: 'Name ' + error.response.data.errors.name[0] })
      } else {
        this.setState({
          isOpen: true,
          message: error.response.status.toString() + " Unexpected Error Problem Occurred",
          type: 'error'
        })
        this.setState({ openPopup: false })
      }
    })
  }

  handleDeleteItem() {
    const DeleteItemId = this.state.position.id
    axios({
      method: 'delete',
      url: '/v1/positions/' + DeleteItemId,
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(() => {
      this.setState({
        positions: this.state.positions.filter(item => item.id !== DeleteItemId)
      })
      this.setState({ openDeletePopup: false })
      this.setState({
        isOpen: true,
        message: 'Deleted Successfully',
        type: 'error'
      })
    }).catch(error => console.log(error.response))
  }

  handleClose() {
    this.setState({ openPopup: false })
    this.setState({ openDeletePopup: false })
    this.setState({ error_messages: '' })
  }

  handleCreateorUpdateItem(item, isAdd, model) {
    var textTitle = ''
    if (isAdd) {
      textTitle = 'Create ' + model
      this.setState({ updateBranchDept: {} })
    } else {
      textTitle = 'Edit ' + model
      this.setState({ 
        updateBranchDept: item
      })
    }
    this.setState({ openPopup: true })
    this.setState({ position: item })
    this.setState({ title: textTitle })
  }

  handleInputChange(e) {
    this.setState({
      position: {
        ...this.state.position,
        [e.target.name]: e.target.value
      }
    })
  }

  handleOpenDeletePopup(item, model) {
    this.setState({ model: model })
    this.setState({ position: item })
    this.setState({ openDeletePopup: true })
  }

  handleDeleteClose() {
    this.setState({ openDeletePopup: false })
  }

  handleCloseAlert(event, reason) {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ isOpen: false })
  }

  handleAutocomplete(event, values) {
    if(values) {
      this.setState({
        position: {
            ...this.state.position,
            branch_department_id: values.id
        },
        updateBranchDept: values
      })
    }
  }

  render() {
    return (
      <>
        {
          this.state.load ? (
            <div>
              <SimpleTable
                handleClick={this.handleCreateorUpdateItem}
                initialItem={this.state.initialPositions}
                items={this.state.positions}
                model={this.state.model}
                onOpenDeletePopup={this.handleOpenDeletePopup}
                headers={this.state.tableHead}
                withShow={this.state.withShow}
                withEdit={this.state.withEdit}
                icon={<RecentActorsOutlinedIcon fontSize="large" />}
              />

              <SimplePopUp
                openPopup={this.state.openPopup}
                title={this.state.title}
                handleClose={this.handleClose}
                maxWidth={this.state.maxWidth}
              >
                <PositionsForm
                  error={this.state.error_messages}
                  item={this.state.position}
                  handleAutocomplete={this.handleAutocomplete}
                  submit={this.handleSubmit}
                  onchange={this.handleInputChange}
                  branchDepartments={this.state.branchDepartments}
                  updateBranchDept={this.state.updateBranchDept}
                  />
              </SimplePopUp>

              <SimpleDeletePopUp
                openDeletePopup={this.state.openDeletePopup}
                item={this.state.position}
                delete={this.handleDeleteItem}
                handleDeleteClose={this.handleClose}
                model={this.state.model}
              />
              <AlertMessage
                notify={this.state.notify}
                handleCloseAlert={this.handleCloseAlert}
                isOpen={this.state.isOpen}
                type={this.state.type}
                message={this.state.message}
              />
            </div>
          ) : (
            <ProgressBar model={this.state.model} />
          )}
      </>
    )
  }
}

export default Positions
