import React from 'react'
import PropTypes from 'prop-types'
import styles from './TaxTables.module.css'
import {
    Table, TableBody, TableCell, TableRow, Button, NativeSelect
} from '@material-ui/core'

class TableTax extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        function groupArrayOfObjects(list, key) {
            return list.reduce(function (rv, x) {
                (rv[x[key]] = rv[x[key]] || []).push(x);
                return rv;
            }, {});
        }
        var dataArray = this.props.items.filter((item) => new Date(item.valid_at).getFullYear() === Number(this.props.selectedYear))
        var clientsGroupBy = groupArrayOfObjects(dataArray, "payroll_type");
        return (
            <div>
                <div className={styles.groupTileSearch}>
                    <div className={styles.tileBody}>
                        <h2 className={styles.title}>
                            <div className={styles.head}>
                                <div>Tax Tables</div>
                                <div className={styles.headCtas}>
                                <NativeSelect
                                    value={this.props.selectedYear}
                                    onChange={this.props.handleSelectYearChange}
                                    name="year"
                                    inputProps={{ "aria-label": "year" }}
                                    className={styles.selectStyles}
                                >
                                    {this.props.selectYearOptions.map((year, idx) => (
                                        <option value={year} key={idx}>
                                            {year}
                                        </option>
                                    ))}
                                </NativeSelect>
                                <Button variant="contained" color="primary" onClick={this.props.switchTable} className={styles.switchButton}>Switch Table</Button>
                                </div>
                            </div>
                        </h2>
                    </div>
                </div>
                <hr />
                <Table size="small">
                    <TableBody>
                        {
                        Object.keys(clientsGroupBy).length ?
                        Object.keys(clientsGroupBy).map((item) => (
                            <>
                                <TableRow>
                                    <TableCell className={styles.textLabel}><b>{item.replace('_', ' ')}</b></TableCell>
                                    {Object.keys(groupArrayOfObjects(clientsGroupBy[item], 'column_no')).map((test, i) => (
                                        <TableCell key={i} className={styles.tableCellbody} align="right">
                                            <b>{test}</b></TableCell>
                                    ))}
                                </TableRow>
                                <TableRow>
                                    <TableCell>Amount</TableCell>
                                    {Object.keys(groupArrayOfObjects(clientsGroupBy[item], 'column_no')).map((test, i) => (
                                        <TableCell key={i} className={styles.tableCell} align="right">
                                            {Object.keys(groupArrayOfObjects(groupArrayOfObjects(clientsGroupBy[item], 'column_no')[test], 'amount')).map((amount, ix) => (
                                                <div key={ix}>{parseFloat(amount).toFixed(2)}</div>
                                            ))}
                                        </TableCell>
                                    ))}
                                </TableRow>
                                <TableRow>
                                    <TableCell>Exemption</TableCell>
                                    {Object.keys(groupArrayOfObjects(clientsGroupBy[item], 'column_no')).map((test, i) => (
                                        <TableCell key={i} className={styles.tableCell} align="right">
                                            {Object.keys(groupArrayOfObjects(groupArrayOfObjects(clientsGroupBy[item], 'column_no')[test], 'exemption')).map((exempt, ix) => (
                                                <div key={ix}>{parseFloat(exempt).toFixed(2)}</div>
                                            ))}
                                        </TableCell>
                                    ))}
                                </TableRow>
                                <TableRow>
                                    <TableCell>Status</TableCell>
                                    {Object.keys(groupArrayOfObjects(clientsGroupBy[item], 'column_no')).map((test, i) => (
                                        <TableCell key={i} className={styles.tableCell} align="right">
                                            {Object.keys(groupArrayOfObjects(groupArrayOfObjects(clientsGroupBy[item], 'column_no')[test], 'over_percentage')).map((exempt, ix) => (
                                                <div key={ix}>{parseFloat(exempt).toFixed(2)}</div>
                                            ))}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </>
                        )) :
                        <TableRow>
                            <TableCell colSpan={6} className={styles.noResultRow}>
                            No result.
                            </TableCell>
                        </TableRow>
                        }
                    </TableBody>
                </Table>

            </div>
        )
    }
}

export default TableTax

TableTax.propTypes = {
    handleSetItems: PropTypes.func,
    items: PropTypes.array,
    handleClick: PropTypes.func,
    initialItem: PropTypes.object,
    onOpenDeletePopup: PropTypes.func,
    switchTable: PropTypes.func,
    handleSelectYearChange: PropTypes.func,
    selectedYear: PropTypes.number,
    selectYearOptions: PropTypes.array
}
