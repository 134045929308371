import React from 'react'
import styles from './LoanReports.module.css'

import PropTypes from 'prop-types'

import {
	TextField,
	Button
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Grid from '@material-ui/core/Grid'

class LoanReportSearchForm extends React.Component {
	render() {
		return (
			<div>
				<Grid container spacing={1}>
					<Grid item xs={12} sm={3}>
						<TextField
							type="date"
							autoComplete="off"
							variant="outlined"
							size="small"
							name="start_date"
							fullWidth={true}
							label="Start Date"
							onChange={this.props.handleChange}
							value={this.props.item.start_date}
							id="loanReport-start_date"
							onKeyPress={this.props.handleKeyPress}
							InputLabelProps={{
								shrink: true
							}}
						/>
					</Grid>
					<Grid item xs={12} sm={3}>
						<TextField
							type="date"
							autoComplete="off"
							variant="outlined"
							size="small"
							name="end_date"
							fullWidth={true}
							label="End Date"
							onChange={this.props.handleChange}
							value={this.props.item.end_date}
							onKeyPress={this.props.handleKeyPress}
							id="loanReport-end_date"
							inputProps={{ min: new Date(this.props.item.start_date).toLocaleDateString('fr-Ca') }}
							InputLabelProps={{
								shrink: true,
							}}
						/>
					</Grid>
					<Grid item xs={12} sm={3}>
						<Autocomplete
							value={this.props.item.payrollAccount || {}}
							id="payrollAccounts"
							name="payrollAccounts"
							fullWidth
							size="small"
							options={(this.props.payrollAccounts).sort(function (a, b) {
								if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
								if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
								return 0;
							})}
							getOptionLabel={(option) => option.name || ''}
							getOptionSelected={(option, value) => option.name === value.name}
							onChange={this.props.handleAutocomplete}
							renderInput={(params) => (
								<TextField {...params} name="payrollAccount" variant="outlined" label="Account" />
							)}
						/>
					</Grid>
					<Grid item xs={12} sm={3}>
						<Autocomplete
							value={this.props.item.employees || []}
							id="employees"
							name="employees"
							fullWidth
							multiple

							size="small"
							options={(this.props.employees).sort(function (a, b) {
								if (a.name_formal.toLowerCase() < b.name_formal.toLowerCase()) return -1;
								if (a.name_formal.toLowerCase() > b.name_formal.toLowerCase()) return 1;
								return 0;
							})}
							getOptionLabel={(option) => option.name_formal || ''}
							getOptionSelected={(option, value) => option.name_formal === value.name_formal}
							onChange={this.props.handleAutocomplete}
							renderInput={(params) => (
								<TextField {...params} name="employees" variant="outlined" label="Employee(s)" />
							)}
						/>
					</Grid>
				</Grid>
				<div className={styles.actionButton}>
					<Button variant="outlined" id="loanReport-search" color="primary" onClick={() => this.props.search()} >Search</Button>
					<Button variant="outlined" id="loanReport-clearSearch" color="secondary" onClick={() => this.props.clearSearch()} >Clear</Button>
				</div>
			</div>
		)
	}
}
export default LoanReportSearchForm

LoanReportSearchForm.propTypes = {
	handleChange: PropTypes.func,
	item: PropTypes.object,
	children: PropTypes.object,
	search: PropTypes.func,
	clearSearch: PropTypes.func,
	selectedEmployees: PropTypes.array,
	employees: PropTypes.array,
	handleAutocomplete: PropTypes.func,
	handleKeyPress: PropTypes.func,
	payrollAccounts: PropTypes.array
}
