// eslint-disable-next-line
import React from 'react'
import SimplePopUp from '../../shared/SimplePopUp/SimplePopUp'
import SimpleDeletePopUp from '../../shared/SimpleDeletePopUp/SimpleDeletePopUp'
import AccountBoxOutlinedIcon from '@material-ui/icons/AccountBoxOutlined';
import axios from 'axios'
import AlertMessage from "../../shared/Notify/AlertMessage";
import ProgressBar from "../../shared/ProgressBar/ProgressBar";
import TableTax from './TableTax'
import SimpleTable from '../../shared/SimpleTable/SimpleTable';
import TaxForm from './TaxForm'

class TaxTables extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            model: 'Tax Table',
            taxTables: [],
            branches: [],
            departments: [],
            updateBranch: {},
            updateDepartment: {},
            openPopup: false,
            openDeletePopup: false,
            taxTable: {},
            initialTaxTable: {
                id: ''
            },
            title: '',
            load: false,
            tableHead: ['payroll_type','column_no','amount','exemption', 'over_percentage', 'starts_at', 'valid_at'],
            withShow: false,
            withEdit: true,
            maxWidth: 'sm',
            error_messages: '',
            notify: {},
            isOpen: false,
            message: '',
            type: '',
            isSimpleTable: true,
            selectedYear: new Date().getFullYear()
        }
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleDeleteItem = this.handleDeleteItem.bind(this)
        this.handleCreateorUpdateItem = this.handleCreateorUpdateItem.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this)
        this.handleOpenDeletePopup = this.handleOpenDeletePopup.bind(this)
        this.handleDeleteClose = this.handleDeleteClose.bind(this)
        this.handleCloseAlert = this.handleCloseAlert.bind(this)
        this.handleSetItems = this.handleSetItems.bind(this)
        this.handleAccountChange = this.handleAccountChange.bind(this)
        this.handleAmountChanges = this.handleAmountChanges.bind(this)
        this.switchTable = this.switchTable.bind(this)
        this.handleSelectYearChange = this.handleSelectYearChange.bind(this)
    }

    componentDidMount() {
        this.loadTaxTables('/v1/table_taxes')
    }

    loadTaxTables(url) {
        axios({
            method: 'get',
            url: url,
            headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
        }).then(resp => {
            this.setState({ taxTables: resp.data ,load: true})
        }).catch(error => {
                this.setState({isOpen: true,message: error.response.status.toString() + " Unexpected Error Problem Occurred",
                    type: 'error'
                })
        })
    }

    handleSubmit() {
        const item ={}
        item["column_no"] = parseInt(this.state.taxTable.column_no)
        item['amount'] = parseFloat(this.state.taxTable.amount).toFixed(2)
        item['exemption'] = parseFloat(this.state.taxTable.exemption).toFixed(2)
        item['over_percentage'] = parseFloat(this.state.taxTable.over_percentage).toFixed(2)
        item['payroll_type'] = this.state.taxTable.payroll_no
        item['valid_at'] = this.state.taxTable.valid_at
        item['starts_at'] = this.state.taxTable.starts_at
        item['id']=this.state.taxTable.id
        var method = ''
        var url = ''
        if (item.id === '' || item.id === undefined) {
            method = 'post'
            url = '/v1/table_taxes'

        } else {
            method = 'put'
            url = '/v1/table_taxes/' + item.id
        }

        axios({
            method: method,
            url: url,
            data: (item),
            headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
        }).then(resp => {
            const newData = resp.data
            this.setState({
                taxTables: this.state.taxTables.filter(taxTable => taxTable.id !== item.id)
            })
            this.setState({ taxTables: [...this.state.taxTables, newData], openPopup: false })
        }).catch(error => {
            if (error.response.status === 422) {
                this.setState({ error_messages: 'Name ' + error.response.data.errors.name[0] })
            } else {
                this.setState({
                    isOpen: true,
                    message: error.response.status.toString() + " Unexpected Error Problem Occurred",
                    type: 'error'
                })
                this.setState({ openPopup: false })
            }
        })


    }
    
    handleDeleteItem() {
        const acc = this.state.taxTable
        axios({
            method: 'delete',
            url: '/v1/table_taxes/' + acc.id,
            headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
        }).then(() => {
            this.setState({
                taxTables: this.state.taxTables.filter(item => item.id !== acc.id)
            })
            this.setState({ openDeletePopup: false })
            this.setState({
                isOpen: true,
                message: 'Deleted Successfully',
                type: 'error'
            })
        }).catch(error => {
            if (error.response.status === 422) {
                this.setState({ error_messages: 'Name ' + error.response.data.errors.name[0] })
            } else {
                this.setState({isOpen: true,message: error.response.status.toString() + " Unexpected Error Problem Occurred",
                    type: 'error'
                })
                this.setState({ openPopup: false })
            }
        })
    }

    handleClose() {
        this.setState({ openPopup: false })
        this.setState({ openDeletePopup: false })
        this.setState({ error_messages: '' })
        this.setState({ updateDepartment: [] })
        this.setState({ updateBranch: [] })
    }

    handleCreateorUpdateItem(item, isAdd, model) {
        var payType = null
        var newItem = item
        var textTitle = ''
        if (isAdd) {
            textTitle = 'Create ' + model + ' Record'
        } else {
            textTitle = 'Edit ' + model+ ' Record'
            if(item.payroll_type === 'daily'){
                payType = 0
            }else if(item.payroll_type === 'weekly'){
                payType=1
            }else if(item.payroll_type === 'semi_monthly'){
                     payType = 2
            }else if(item.payroll_type === 'monthly'){
                payType=3
            }
            item["payroll_no"] = payType
        }
        this.setState({ openPopup: true })
        this.setState({ taxTable: newItem })
        this.setState({ title: textTitle })
    }

    handleInputChange(e) {
        this.setState({
            taxTable: {
                ...this.state.taxTable,
                [e.target.name]: e.target.value
            }
        })
    }

    handleOpenDeletePopup(item) {
        this.setState({ taxTable: item })
        this.setState({ openDeletePopup: true })
    }

    handleDeleteClose() {
        this.setState({ openDeletePopup: false })
    }

    handleCloseAlert(event, reason) {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ isOpen: false })
    }


    groupArrayOfObjects(list, key) {
        return list.reduce(function (rv, x) {
            (rv[x[key]] = rv[x[key]] || []).push(x);
            return rv;
        }, {});
    }
    handleSetItems(addState, item, test) {
        var textTitle = ''
        if (addState) {
            textTitle = 'Create Tax Table'
        } else {
            textTitle = 'Edit Tax Table'
        }
        var account = {}
        var clientsGroupBy = []
        var paytype = ''
        if (!addState) {
            if (item === 'daily') {
                paytype = 0
            }
            if (item === 'weekly') {
                paytype = 1
            }
            if (item === 'semi_monthly') {
                paytype = 2
            }
            if (item === 'monthly') {
                paytype = 3
            }
            clientsGroupBy = this.groupArrayOfObjects(this.groupArrayOfObjects(this.state.taxTables, "payroll_type")[item], 'column_no')[test]

            account = {
                id: clientsGroupBy[0].id,
                column_no: test,
                payroll_type: paytype,
                exemption: clientsGroupBy[0].exemption,
                over_percentage: clientsGroupBy[0].over_percentage
            }
        }
        this.setState({ taxTable: account, openPopup: true, title: textTitle })

    }

    handleAccountChange(e, values) {
        var account = e
        this.setState({
            [account]: {
                ...this.state[account],
                amount: values.floatValue
            }
        })
    }

    handleAmountChanges(e, values) {
        this.setState({
            taxTable: {
                ...this.state.taxTable,
                [e]: values.floatValue
            }
        })
    }
    switchTable(){
     this.setState({isSimpleTable: !this.state.isSimpleTable})
    }

    handleSelectYearChange(event) {
        this.setState({
            selectedYear: event.target.value,
        });
    }

    render() {
        const { selectedYear } = this.state
        var daily,weekly,semi_monthly,monthly = []
        daily = this.state.taxTables.filter(item => item.payroll_type === "daily").sort(function (a, b) {
            if (a.column_no < b.column_no) return -1;
            if (a.column_no > b.column_no) return 1;
            return 0;
          })
        weekly = this.state.taxTables.filter(item => item.payroll_type === "weekly").sort(function (a, b) {
            if (a.column_no < b.column_no) return -1;
            if (a.column_no > b.column_no) return 1;
            return 0;
          })
        semi_monthly = this.state.taxTables.filter(item => item.payroll_type === "semi_monthly").sort(function (a, b) {
            if (a.column_no < b.column_no) return -1;
            if (a.column_no > b.column_no) return 1;
            return 0;
          })
        monthly = this.state.taxTables.filter(item => item.payroll_type === "monthly").sort(function (a, b) {
            if (a.column_no < b.column_no) return -1;
            if (a.column_no > b.column_no) return 1;
            return 0;
          })

        const simpleTableItems = [...daily,...weekly,...semi_monthly,...monthly]
        const filtered = simpleTableItems.filter((item) => new Date(item.valid_at).getFullYear() === Number(selectedYear))

        const selectYearOptions = []
        for (
            let yearStart = 2018;
            yearStart <= new Date().getFullYear();
            yearStart++
            ) {
            selectYearOptions.push(yearStart)
        }

        return (
            <>
                {
                    this.state.load ? (
                        <div>
                            {
                                this.state.isSimpleTable?(
                                    <SimpleTable
                                    items={filtered}
                                    headers={this.state.tableHead}
                                    model="Tax Table"
                                    handleClick={this.handleCreateorUpdateItem}
                                    withEdit={this.state.withEdit}
                                    initialItem={this.state.initialTaxTable}
                                    switchTable = {this.switchTable}
                                    onOpenDeletePopup={this.handleOpenDeletePopup}
                                    handleSelectYearChange={this.handleSelectYearChange}
                                    selectedYear={selectedYear}
                                    selectYearOptions={selectYearOptions}
                                />
                                ):(
                                    <TableTax
                                    handleClick={this.handleCreateorUpdateItem}
                                    initialItem={this.state.initialTaxTable}
                                    items={this.state.taxTables}
                                    model={this.state.model}
                                    onOpenDeletePopup={this.handleOpenDeletePopup}
                                    headers={this.state.tableHead}
                                    withShow={this.state.withShow}
                                    withEdit={this.state.withEdit}
                                    icon={<AccountBoxOutlinedIcon fontSize="large" />}
                                    handleSetItems={this.handleSetItems}
                                    switchTable = {this.switchTable}
                                    handleSelectYearChange={this.handleSelectYearChange}
                                    selectedYear={selectedYear}
                                    selectYearOptions={selectYearOptions}
                                />
                                )
                            }
                            <SimplePopUp
                                openPopup={this.state.openPopup}
                                title={this.state.title}
                                handleClose={this.handleClose}
                                maxWidth={this.state.maxWidth}
                            >
                                <TaxForm
                                    item={this.state.taxTable}
                                    submit={this.handleSubmit}
                                    onchange={this.handleInputChange}
                                    handleAccountChange={this.handleAccountChange}
                                    handleAmountChanges={this.handleAmountChanges}
                                />
                            </SimplePopUp>

                            <SimpleDeletePopUp
                                openDeletePopup={this.state.openDeletePopup}
                                item={this.state.taxTable}
                                delete={this.handleDeleteItem}
                                handleDeleteClose={this.handleClose}
                                model="tax table records"
                            />
                            <AlertMessage
                                notify={this.state.notify}
                                handleCloseAlert={this.handleCloseAlert}
                                isOpen={this.state.isOpen}
                                type={this.state.type}
                                message={this.state.message}
                            />
                        </div>
                    ) : (
                        <ProgressBar model={this.state.model} />
                    )}
            </>
        )
    }
}

export default TaxTables

