// eslint-disable-next-line
import React from 'react'
import axios from 'axios'
import ProgressBar from "../../shared/ProgressBar/ProgressBar";
import SimpleTableGroupBy from "../../shared/SimpleTable/SimpleTableGroupBy";
import PermMediaOutlinedIcon from '@material-ui/icons/PermMediaOutlined';
class directory extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      model: 'Directory',
      directory: [],
      tableHead: ['name_formal', 'position.name', 'office_no', 'email'],
      groupLabel: 'Department',
    }
  }

  componentDidMount() {
    this.loadDirectory('/v1/employees/directory')
  }

  loadDirectory(url) {
    axios({
      method: 'get',
      url: url,
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then(resp => {
      this.setState({directory: resp.data})
      this.setState({load: true})
    }).catch(error => console.log(error.response))
  }

  render() {
    let groupByArray = this.state.directory.reduce((r, a) => {
      r[a.department.name] = [...r[a.department.name] || [], a];
      return r;
    }, {});
    return (
      <>
        {
          this.state.load ? (
            <div>
              <SimpleTableGroupBy
                model="Diretory"
                headers={this.state.tableHead}
                items={groupByArray}
                groupLabel={this.state.groupLabel}
                directoryClassName={true}
                icon={<PermMediaOutlinedIcon/>}
                nodelete={true}
              />
            </div>
          ) : (
            <ProgressBar model={this.state.model}/>
          )}
      </>
    )
  }
}

export default directory
