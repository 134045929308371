import React from 'react'
import styles from './PayrollAccounts.module.css'

import PropTypes from 'prop-types'

import {
  TextField,
  Button,
  MenuItem,
  Grid
} from '@material-ui/core'

import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import Switch from '@material-ui/core/Switch'
import FormControlLabel from '@material-ui/core/FormControlLabel'

class PayrollAccountsForm extends React.Component {
  render() {
    return (
      <div>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Autocomplete
              value={this.props.updateAccount}
              id="account_id"
              size="small"
              fullWidth
              name="account_id"
              className={styles.formTextfield}
              options={this.props.accounts.sort(function (a, b) {
                if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
                if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
                return 0;
              })}
              getOptionLabel={(option) => option.name || ''}
              getOptionSelected={(option, value) => option.name === value.name}
              onChange={this.props.handleAutocomplete}
              renderInput={(params) => (
                <TextField {...params} name="account_id" variant="outlined" label="Account Name" required />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              autoComplete='off'
              select
              size="small"
              label="Registry Type"
              variant="outlined"
              fullWidth
              value={this.props.item.registry_type}
              onChange={this.props.onchange}
              name="registry_type"
            >
              {
                this.props.registryTypes.length > 0 ? (
                  this.props.registryTypes.map((type) => (
                    <MenuItem
                      key={type}
                      value={type}
                    >{type}
                    </MenuItem>
                  ))
                ) : (null)
              }
            </TextField>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              autoComplete="off"
              required
              variant="outlined"
              size="small"
              name="name"
              fullWidth="true"
              label="Name"
              onChange={this.props.onchange}
              value={this.props.item.name}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              autoComplete="off"
              required
              variant="outlined"
              size="small"
              name="rate"
              type="number"
              fullWidth="true"
              label="Rate"
              onChange={this.props.onchange}
              value={this.props.item.rate}
              id="rate"
            />
          </Grid>
          <Grid item xs={6} sm={4}>
            <FormControlLabel
              control={<Switch
                checked={this.props.item.is_deduction}
                onChange={this.props.handleSwitch}
                name="is_deduction" />}
              label="Deduction ?"
              labelPlacement="right"
            />
          </Grid>
          <Grid item xs={6} sm={4}>
            <FormControlLabel
              control={<Switch
                checked={this.props.item.is_taxable}
                onChange={this.props.handleSwitch}
                name="is_taxable" />}
              label="Taxable ?"
              labelPlacement="right"
            />

          </Grid>
        </Grid>

        <div className={styles.actionButton}>
          <Button variant="outlined"
            disabled={!this.props.item.name && !this.props.item.rate && !this.props.item.account_id && !this.props.item.registry_type}
            id="payrollAccount-submit" color="primary"
            onClick={() => this.props.submit()}>Save</Button>
        </div>
      </div >
    )
  }
}

export default PayrollAccountsForm

PayrollAccountsForm.propTypes = {
  onchange: PropTypes.func,
  handleAutocomplete: PropTypes.func,
  handleSwitch: PropTypes.func,
  accounts: PropTypes.array,
  registryTypes: PropTypes.array,
  item: PropTypes.object,
  updateAccount: PropTypes.object,
  submit: PropTypes.func
}
