import React from 'react'
import styles from './PayrollList.module.css'

import PropTypes from 'prop-types'

import CloseIcon from '@material-ui/icons/Close'
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import {
  TextField,
  Button, Grid, MenuItem
} from '@material-ui/core'

import Switch from '@material-ui/core/Switch'
import FormControlLabel from '@material-ui/core/FormControlLabel'

class PayrollListForm extends React.Component {
  render() {
    return (
      <div>
        <form>
          <Grid container spacing={2}>
          <Grid item xs={12}>
            <Autocomplete
              id="branch"
              name="branch"
              fullWidth
              className={styles.textFields}
              size="small"
              options={(this.props.branches).sort(function (a, b) {
                if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
                if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
                return 0;
              })}
              loading={this.props.branchesAutoCompleteLoading}
              getOptionLabel={(option) => option.name || ''}
              getOptionSelected={(option, value) => option.name === value.name}
              onChange={this.props.handleAutocompleteBranch}
              onInputChange={(event, value, reason) => this.props.handleAutocompleteOnInputChange(event, value, reason)}
              value={this.props.branch}
              closeIcon={<CloseIcon fontSize="small" id="clear_branch_autocomplete" />}
              renderInput={(params) => (
                <TextField {...params} name="branch" variant="outlined" label="Branch Name" />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              id="bank"
              name="bank"
              fullWidth
              size="small"
              options={(this.props.banks).sort(function (a, b) {
                if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
                if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
                return 0;
              })}
              loading={this.props.banksAutoCompleteLoading}
              getOptionLabel={(option) => option.name || ''}
              getOptionSelected={(option, value) => option.name === value.name}
              onChange={this.props.handleAutocompleteBank}
              onInputChange={(event, value, reason) => this.props.handleAutocompleteOnInputChange(event, value, reason)}
              value={this.props.bank}
              closeIcon={<CloseIcon fontSize="small" id="clear_banks_autocomplete" />}
              renderInput={(params) => (
                <TextField {...params} name="bank" variant="outlined" label="Bank Name" />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              className={styles.capitalize}
              autoComplete="off"
              variant="outlined"
              size="small"
              name="payroll_type"
              fullWidth
              select
              label="Payroll Type"
              required
              onChange={this.props.onchange}
              value={this.props.item.payroll_type}
              id="employee-payroll_type"
            >
                {
                    this.props.payrollTypes.length > 0 ? (
                        this.props.payrollTypes.map((state, idx) => (
                            <MenuItem className={styles.capitalize} value={state} key={idx}>{state.replace(/_/g, ' ')}</MenuItem>
                        ))
                    ) : null
                }

            </TextField>
          </Grid>
            <Grid item xs={6}>
              <TextField
                autoComplete="off"
                required
                className={styles.textFields}
                variant="outlined"
                size="small"
                name="date_from"
                type="date"
                fullWidth="true"
                label="Date From"
                onChange={this.props.onchange}
                value={this.props.item.date_from}
                id="rate"
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                autoComplete="off"
                required
                className={styles.textFields}
                variant="outlined"
                size="small"
                name="date_to"
                type="date"
                fullWidth="true"
                label="Date To"
                onChange={this.props.onchange}
                value={this.props.item.date_to}
                id="rate"
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={<Switch
                  checked={this.props.item.philhealth}
                  onChange={this.props.handleSwitch}
                  name="philhealth"/>}
                label="PhilHealth ?"
                labelPlacement="bottom"
              />
              <FormControlLabel
                control={<Switch
                  checked={this.props.item.pagibig}
                  onChange={this.props.handleSwitch}
                  name="pagibig"/>}
                label="Pag-Ibig ?"
                labelPlacement="bottom"
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={<Switch
                  checked={this.props.item.sss}
                  onChange={this.props.handleSwitch}
                  name="sss"/>}
                label="SSS ?"
                labelPlacement="bottom"
              />
              <FormControlLabel
                control={<Switch
                  checked={this.props.item.bir}
                  onChange={this.props.handleSwitch}
                  name="bir"/>}
                label="Withholding Tax ?"
                labelPlacement="bottom"
              />
            </Grid>
          </Grid>
        </form>

        <div className={styles.actionButton}>
          <Button 
            variant="outlined"
            id="payrollAccount-submit"
            color="primary"
            onClick={() => this.props.submit()} 
            disabled={!this.props.item.branch_id || !this.props.item.bank_id || !this.props.item.payroll_type}
          >Save</Button>
        </div>
      </div>
    )
  }
}

export default PayrollListForm

PayrollListForm.propTypes = {
  onchange: PropTypes.func,
  handleAutocomplete: PropTypes.func,
  handleSwitch: PropTypes.func,
  accounts: PropTypes.array,
  registryTypes: PropTypes.array,
  item: PropTypes.object,
  updateAccount: PropTypes.object,
  bank: PropTypes.object,
  submit: PropTypes.func,
  banksAutoCompleteLoading: PropTypes.func,
  handleAutocompleteOnInputChange: PropTypes.func,
  handleAutocompleteBank: PropTypes.func,
  banks: PropTypes.array,
  branch: PropTypes.object,
  branches: PropTypes.array,
  branchesAutoCompleteLoading: PropTypes.bool,
  handleAutocompleteBranch: PropTypes.func,
  payrollTypes: PropTypes.array,
}
