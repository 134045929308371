import React from 'react'
import axios from 'axios'
import SimpleDeletePopUp from '../../shared/SimpleDeletePopUp/SimpleDeletePopUp';
import SimplePopUp from '../../shared/SimplePopUp/SimplePopUp';
import LeaveCreditsForm from './LeaveCreditsForm'
import moment from 'moment';
import SimpleTable from '../../shared/SimpleTable/SimpleTable';
import AlertMessage from '../../shared/Notify/AlertMessage';

class LeaveCredits extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			leaveCredits: [],
			leaveCreditsData: { leave_type: {}, no_of_days: '' },
			tableHead: ['employee_name'],
			groupLabel: 'Employee',
			openPopup: false,
			openDeletePopup: false,
			employee: {},
			employees: [],
			leaveTypes: [],
			leaveType: {},
			load: false,
			employeeCredits: [],
			years: [],
			year: '',
			title: '',
			itemToDelete: {},
			isOpenAlertMessage: false,
			alertMessageType: '',
			alertMessage: ''
		}

		this.handleChange = this.handleChange.bind(this)
		this.handleSubmit = this.handleSubmit.bind(this)
		this.handleDelete = this.handleDelete.bind(this)
		this.handleCreateOrUpdate = this.handleCreateOrUpdate.bind(this)
		this.handleClose = this.handleClose.bind(this)
		this.handleAutoCompleteChange = this.handleAutoCompleteChange.bind(this)
		this.addLeaveCredit = this.addLeaveCredit.bind(this)
		this.handleLeaveCredit = this.handleLeaveCredit.bind(this)
		this.handleLeaveTypeAutocompleteChange = this.handleLeaveTypeAutocompleteChange.bind(this)
		this.onOpenDeletePopup = this.onOpenDeletePopup.bind(this)
		this.generateAnnualLeaves = this.generateAnnualLeaves.bind(this)
		this.handleCloseAlert = this.handleCloseAlert.bind(this)
	}
	componentDidMount() {
		this.getLeaveCredits()
	}

	getLeaveCredits() {
		axios({
			method: 'get',
			url: '/v1/leave_credits',
			headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
		}).then(resp => {
			this.setState({
				leaveCredits: resp.data
			})
		})
		axios({
			method: 'get',
			url: '/v1/leave_types',
			headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
		}).then(resp => {
			this.setState({
				leaveTypes: resp.data
			})
			var headers = []
			resp.data.map((leaveType) => {
				headers.push(leaveType.name)

			})
			this.setState({
				tableHead: this.state.tableHead.concat(headers)
			})
		})
		axios({
			method: 'get',
			url: '/v1/employees/search?is_active=true&per_page=10000',
			headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
		}).then(resp => {
			this.setState({
				employees: resp.data.employees,
				load: true
			})
		})
		axios({
			method: 'get',
			url: '/v1/leave_credits/years',
			headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
		}).then(resp => {
			this.setState({
				years: resp.data
			})
		})
	}

	handleCreateOrUpdate(items, isAdd) {
		var textTitle = ''
		if (isAdd) {
			textTitle = "Add"
			this.setState({ employee: {}, employeeCredits: [], year_of_effect: '', openPopup: true, title: textTitle })
		}
		else {
			let groupByArray = this.state.leaveCredits.reduce((r, a) => {
				r[a.employee_name] = [...r[a.employee_name] || [], a];
				return r;
			}, {});
			let leaveCredits = groupByArray[items.employee_name]
			textTitle = "Update"
			var newItems = []
			leaveCredits.map((item) => {
				var newItem = item
				newItem['leave_type'] = this.state.leaveTypes.find(leaveType => leaveType.id === item.leave_type_id)
				newItems.push(newItem)
			})
			this.setState({
				employee: this.state.employees.find(employee => employee.id === leaveCredits[0].employee_id),
				openPopup: true,
				title: textTitle,
				year_of_effect: leaveCredits[0].year_of_effect,
				employeeCredits: newItems

			})
		}
	}

	handleChange(e) {
		e.preventDefault()
		this.setState({
			year_of_effect: e.target.value
		})
	}

	handleSubmit(e) {
		e.preventDefault()
		if (this.state.employeeCredits.length > 0) {
			this.state.employeeCredits.map((item) => {
				item['year_of_effect'] = this.state.year_of_effect
				item['employee_id'] = this.state.employee.id
				item['leave_type_id'] = item.leave_type.id
				var method = ''
				var url = ''
				if (item.id === '' || item.id === undefined) {
					method = 'post'
					url = '/v1/leave_credits'
				} else {
					method = 'put'
					url = '/v1/leave_credits/' + item.id
				}
				axios({
					method: method,
					url: url,
					data: (item),
					headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
				}).then(resp => {

					const newData = resp.data
					this.setState({
						leaveCredits: this.state.leaveCredits.filter(leaveCredits => leaveCredits.id !== item.id)
					})
					this.setState({ leaveCredits: [...this.state.leaveCredits, newData] })

					this.setState({
						isOpen: true,
						message: 'Submitted Successfully',
						type: 'success'
					})
				}).catch(error => {
					if (error.response.status === 422) {
						this.setState({ error_messages: 'Name ' + error.response.data.errors.name[0] })
					} else {
						this.setState({
							isOpen: true,
							message: error.response.status.toString() + " Unexpected Error Problem Occurred",
							type: 'error'
						})
						this.setState({ openPopup: false })
					}
				})
			})
		}
		this.setState({ openPopup: false })

	}


	handleDelete() {
		axios({
			method: 'delete',
			url: '/v1/leave_credits/' + this.state.itemToDelete.id,
			headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
		}).then(() => {
			this.setState({
				leaveCredits: this.state.leaveCredits.filter(leaveCredit => leaveCredit.id !== this.state.itemToDelete.id)
			})
      this.setState({
				openDeletePopup: false,
        isOpenAlertMessage: true,
        alertMessage: 'Deleted Successfully',
        alertMessageType: 'success'
      })
		}).catch(() => {
			this.setState({
				openDeletePopup: true,
        isOpenAlertMessage: true,
        alertMessage: 'Error occured while deleting record..',
        alertMessageType: 'error'
      })
		})
	}

	handleClose() {
		this.setState({ openPopup: false, openDeletePopup: false })
	}

	handleAutoCompleteChange(event, values) {

		if (event.target.id.includes('employee')) {
			this.setState({ employee: values })
		}
	}

	handleLeaveCredit(event, idx) {
		const employeeCredits = this.state.employeeCredits
		employeeCredits[idx][event.target.name] = event.target.value
		this.setState({ employeeCredits: employeeCredits })

	}

	addLeaveCredit() {
		this.setState({ employeeCredits: [...this.state.employeeCredits, { leave_type: {}, no_of_days: '' }] })
	}

	handleLeaveTypeAutocompleteChange(idx, value) {
		const employeeCredits = this.state.employeeCredits
		employeeCredits[idx]['leave_type'] = value
		this.setState({ employeeCredits: employeeCredits })
	}

	onOpenDeletePopup(item) {
		this.setState({ openDeletePopup: true })
		this.setState({ itemToDelete: item })
	}

	generateAnnualLeaves() {
		this.setState({ load: false })
		axios({
			method: 'get',
			url: '/v1/leave_credits/generate_leave',
			params: { year_of_effect: moment().format('YYYY') },
			headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
		}).then(resp => {
			this.setState({ load: true, leaveCredits: resp.data })
			this.setState({
				isOpen: true,
				message: 'Submitted Successfully',
				type: 'success'
			})
		}).catch(error => {
			if (error.response.status === 422) {
				this.setState({ error_messages: 'Name ' + error.response.data.errors.name[0] })
			} else {
				this.setState({
					isOpen: true,
					message: error.response.status.toString() + " Unexpected Error Problem Occurred",
					type: 'error'
				})
				this.setState({ openPopup: false })
			}
		})
	}

	flattenObject(key, items, leave_types) {
		const x = {};
		x['id'] = items[0].id
		x['employee_name'] = key
		x['employee_id'] = items[0].employee_id
		x['can_edit'] = items[0].can_edit
		x['can_delete'] = items[0].can_delete

		leave_types.forEach(leave_type => {
			items.forEach(item => {
				if (item.leave_type_id === leave_type.id) {
					x[leave_type.name.toLowerCase()] = item['remaining'].toString() + '.0' + ' / ' + item['no_of_days'].toString()
				}
			})
		})

		return x
	}

	handleCloseAlert(event, reason) {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ isOpenAlertMessage: false })
  }

	render() {
		let groupByArray = this.state.leaveCredits.reduce((r, a) => {
			r[a.employee_name] = [...r[a.employee_name] || [], a];
			return r;
		}, {});
		var leave_types = this.state.leaveTypes
		let asd = Object.keys(groupByArray).map(key => ({
			item: this.flattenObject(key, groupByArray[key], leave_types)
		}));

		const items = asd.map((item) => item.item)

		return (
			<>
				{this.state.load ?
					<div>
						<SimpleTable
							model={"Available Leave"}
							headers={this.state.tableHead}
							items={items}
							withEdit={true}
							handleClick={this.handleCreateOrUpdate}
							withDelete={true}
							onOpenDeletePopup={this.onOpenDeletePopup}
							withAdd={true}
							withGenerateLeave={true}
							generateAnnualLeaves={this.generateAnnualLeaves}
						/>

						<SimplePopUp
							openPopup={this.state.openPopup}
							title={this.state.title}
							handleClose={this.handleClose}
							maxWidth='sm'
						>
							<LeaveCreditsForm
								employee={this.state.employee}
								employees={this.state.employees}
								leaveTypes={this.state.leaveTypes}
								leaveCredits={this.state.employeeCredits}
								addLeaveCredit={this.addLeaveCredit}
								years={this.state.years}
								handleLeaveCredit={this.handleLeaveCredit}
								handleLeaveTypeAutocompleteChange={this.handleLeaveTypeAutocompleteChange}
								handleSubmit={this.handleSubmit}
								handleAutoCompleteChange={this.handleAutoCompleteChange}
								year_of_effect={this.state.year_of_effect}
								handleChange={this.handleChange}
							/>
						</SimplePopUp>

						<SimpleDeletePopUp
							openDeletePopup={this.state.openDeletePopup}
							item={this.state.itemToDelete}
							delete={this.handleDelete}
							handleDeleteClose={this.handleClose}
							model="leave credit"
						/>
						
						<AlertMessage
							handleCloseAlert={this.handleCloseAlert}
							isOpen={this.state.isOpenAlertMessage}
							type={this.state.alertMessageType}
							message={this.state.alertMessage}
						/>
					</div>
					: null}
			</>
		)
	}
}

export default LeaveCredits