// eslint-disable-next-line
// import styles from './Announcement.module.css'

import React from 'react'

import SimpleDeletePopUp from '../../shared/SimpleDeletePopUp/SimpleDeletePopUp'
import axios from 'axios'
import AlertMessage from "../../shared/Notify/AlertMessage";
import ProgressBar from "../../shared/ProgressBar/ProgressBar";
import AnnouncementTable from './AnnouncementTable'
import FeedbackOutlinedIcon from '@material-ui/icons/FeedbackOutlined'
import SimplePopUp from '../../shared/SimplePopUp/SimplePopUp';
import AnnouncementForm from './AnnouncementForm';
import Announcement from './Announcement';
import PropTypes from 'prop-types'
class Announcements extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      model: 'Announcement',
      announcements: [],
      openPopup: false,
      openDeletePopup: false,
      announcement: {},
      employees: [],
      selectedEmployees: [],
      initialAnnouncement: { id: '', content: '', title: '' },
      title: '',
      load: false,
      tableHead: ['title', 'employee.name'],
      withShow: false,
      withEdit: true,
      maxWidth: 'md',
      error_messages: '',
      notify: {},
      isOpen: false,
      message: '',
      type: '',
      redirect: false,
      redirectToShow: false,
      selectedEmployee: {},
    }
    this.handleDeleteItem = this.handleDeleteItem.bind(this)
    this.handleCreateorUpdateItem = this.handleCreateorUpdateItem.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleOpenDeletePopup = this.handleOpenDeletePopup.bind(this)
    this.handleDeleteClose = this.handleDeleteClose.bind(this)
    this.handleCloseAlert = this.handleCloseAlert.bind(this)
    this.handleAutocomplete = this.handleAutocomplete.bind(this)
    this.rowClick = this.rowClick.bind(this)
    this.onAttachmentAdd = this.onAttachmentAdd.bind(this)
    this.handleEditor = this.handleEditor.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleShowPopup = this.handleShowPopup.bind(this)
  }

  componentDidMount() {
    this.loadAnnouncements( '/v1/announcements')
  }

  loadAnnouncements(url) {
    axios({
      method: 'get',
      url: url,
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {
      this.setState({ announcements: resp.data })
      axios({
        method: 'get',
        url:  '/v1/employees/search?is_active=true&per_page=50000',
        headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
      }).then(resp => {
        this.setState({ employees: resp.data.employees })
        this.setState({ load: true })
      }).catch(error => console.log(error.response))
    }).catch(error => console.log(error.response))
  }



  handleDeleteItem() {
    const DeleteItemId = this.state.announcement.id
    axios({
      method: 'delete',
      url:  '/v1/announcements/' + DeleteItemId,
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(() => {
      this.setState({
        announcements: this.state.announcements.filter(item => item.id !== DeleteItemId)
      })
      this.setState({ openDeletePopup: false })
      this.setState({
        isOpen: true,
        message: 'Deleted Successfully',
        type: 'error'
      })
    }).catch(error => console.log(error.response))
  }

  handleClose() {
    this.setState({ openPopup: false, openShowPopup: false })
    this.setState({ openDeletePopup: false })
    this.setState({ error_messages: '' })
  }

  handleCreateorUpdateItem(item) {
    this.setState({ announcement: item })
    this.setState({ openPopup: true })
  }

  handleInputChange(e) {
    this.setState({
      announcement: {
        ...this.state.announcement,
        [e.target.name]: e.target.value
      }
    })
  }

  handleOpenDeletePopup(item, model) {
    this.setState({ model: model })
    this.setState({ announcement: item })
    this.setState({ openDeletePopup: true })
  }

  handleDeleteClose() {
    this.setState({ openDeletePopup: false })
  }

  handleCloseAlert(event, reason) {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ isOpen: false })
  }

  handleAutocomplete(event, values) {
    this.setState({ selectedEmployee: values })
  }

  rowClick(item) {
    this.setState({ redirectToShow: true })
    this.setState({ announcement: item })

  }

  handleEditor(event, editor) {
    this.setState({
      announcement: {
        ...this.state.announcement,
        content: editor
      }
    })


  }

  uploadAttachment(attachment) {

    var file = attachment.file
    var form = new FormData;
    var endpoint =  '/v1/photos/'
    form.append("Content-Type", file.type);
    form.append("image", file);

    form.append('title', file.name)
    form.append('description', 'test2')
    form.append('rank', Math.floor(Math.random() * 100))
    form.append('publish', false)

    const xhr = new XMLHttpRequest;
    xhr.open("POST", endpoint, true);
    xhr.setRequestHeader('X-API-ACCESS-TOKEN', localStorage.getItem('api_key'));

    xhr.upload.onprogress = function (event) {
      var progress = event.loaded / event.total * 100;
      return attachment.setUploadProgress(progress);
    };

    xhr.onload = function () {
      if (this.status >= 200 && this.status < 300) {
        var data = JSON.parse(this.responseText);
        return attachment.setAttributes({
          url: data.image.normal,
          href: data.image.normal
        });
      }
    }
    return xhr.send(form)
  }
  onAttachmentAdd(event) {
    var attachment = event.attachment;
    if (attachment.file) {
      return this.uploadAttachment(attachment);
    }
  }

  handleSubmit() {
    const item = this.state.announcement
    item['employee_id']= this.state.selectedEmployee.id
    var method = ''
    var url = ''

    if (item.id === '') {
      method = 'post'
      url =  '/v1/announcements'
    } else {
      method = 'put'
      url =  '/v1/announcements/' + item.id
    }
    axios({
      method: method,
      url: url,
      data: (item),
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then((resp) => {
      const newData = resp.data
      this.setState({
        announcements: this.state.announcements.filter(announcement => announcement.id !== item.id)
      })
      this.setState({ announcements: [...this.state.announcements, newData] })
      this.setState({ openPopup: false })
      this.setState({
        isOpen: true,
        message: 'Submitted Successfully',
        type: 'success'
      })
    }).catch(error => {
      if (error.response.status === 422) {
        this.setState({ error_messages: 'Name ' + error.response.data.errors.name[0] })
      } else {
        this.setState({
          isOpen: true,
          message: error.response.status.toString() + " Unexpected Error Problem Occurred",
          type: 'error'
        })
        this.setState({ openPopup: false })
      }
    })
  }

  handleShowPopup(item) {
    this.setState({ openShowPopup: true })
    this.setState({ announcement: item })
  }

  render() {

    return (
      <>
        {
          this.state.load ? (
            <div>
              <AnnouncementTable
                items={this.state.announcements}
                icon={<FeedbackOutlinedIcon fontSize="large" />}
                model={"Announcement"}
                handleClick={this.handleCreateorUpdateItem}
                initialItem={this.state.initialAnnouncement}
                onOpenDeletePopup={this.handleOpenDeletePopup}
                rowClick={this.rowClick}
                handleShowPopup={this.handleShowPopup}

              />
              <SimplePopUp
                openPopup={this.state.openPopup}
                title={this.state.announcement.id === "" ? "Create Announcement" : "Update Announcement"}
                items={this.state.announcement}
                handleClose={this.handleClose}
                maxWidth="xl"
              >
                <AnnouncementForm
                  error={this.state.error_messages}
                  item={this.state.announcement}
                  submit={this.handleSubmit}
                  handleChange={this.handleInputChange}
                  employees={this.state.employees}
                  selectedEmployees={this.state.selectedEmployees}
                  handleAutocomplete={this.handleAutocomplete}
                  handleEditor={this.handleEditor}
                  onAttachmentAdd={this.onAttachmentAdd}
                  currentUser={this.props.currentUser}
                  selectedEmployee={this.state.selectedEmployee}

                />
              </SimplePopUp>
              <SimplePopUp
                openPopup={this.state.openShowPopup}
                title={this.state.announcement.title}
                handleClose={this.handleClose}
                maxWidth="lg"
                >
                  <Announcement item={this.state.announcement} />
                </SimplePopUp>


              <SimpleDeletePopUp
                openDeletePopup={this.state.openDeletePopup}
                item={this.state.announcement}
                delete={this.handleDeleteItem}
                handleDeleteClose={this.handleClose}
                model={this.state.model}
              />
              <AlertMessage
                notify={this.state.notify}
                handleCloseAlert={this.handleCloseAlert}
                isOpen={this.state.isOpen}
                type={this.state.type}
                message={this.state.message}
              />
            </div>
          ) : (
            <ProgressBar model={this.state.model} />
          )}
      </>
    )
  }
}

export default Announcements

Announcements.propTypes = {
  currentUser: PropTypes.object.isRequired,
}
