// eslint-disable-next-line


import React from 'react'
import PropTypes from 'prop-types'
import SimpleTableGroupBy from '../../shared/SimpleTable/SimpleTableGroupBy'
import SimplePopUp from '../../shared/SimplePopUp/SimplePopUp'
import SimpleDeletePopUp from '../../shared/SimpleDeletePopUp/SimpleDeletePopUp'
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import axios from 'axios'
import AlertMessage from "../../shared/Notify/AlertMessage";
import ProgressBar from "../../shared/ProgressBar/ProgressBar";
import { withRouter } from 'react-router-dom'
import moment from "moment";

export const Component = withRouter(() => {
})
import LeaveRecordsSearch from './LeaveRecordsSearch'
import SimpleTable from "../../shared/SimpleTable/SimpleTable";
import ApprovalForm from '../LeaveManagement/LeaveApplication/ApprovalForm'
class LeaveRecords extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      model: 'Leave Record',
      leaveApplications: [],
      accounts: [],
      leaveApplicationTypes: [],
      showLeaveRecordHistory: [],
      branches: [],
      officers: [],
      leaveTypes: [],
      employees: [],
      departments: [],
      updateAccount: {},
      openPopup: false,
      showPopUp: false,
      openDeletePopup: false,
      leaveApplication: {},
      initialProduct: {
        id: '',
        account_id: '',
        name: '',
        short_name: '',
        amount: '',
        unit: '',
        leaveApplication_type_id: '',
        expirable: false,
        tax_exempt: false,
      },
      title: '',
      searchLeave: {
        starts_on_from: moment().startOf('year').format("YYYY-MM-DD"),
        starts_on_to: moment().endOf('year').format("YYYY-MM-DD"),
      },
      withResultsLabel: false,
      withPagination: true,
      withShowPopUp: true,
      load: false,
      tableHead: ['leave_type.name', 'inclusive_dates', 'without_pay', 'reason', 'officer.name', 'request_status'],
      groupLabel: 'Employee Name',
      maxWidth: 'sm',
      error_messages: '',
      notify: {},
      isOpen: false,
      message: '',
      type: '',
      appliedDays: 0,
      attachments: [],
      leaveCredits: [],
      approvingOfficers: [],
      searchUrl: '/v1/leave_applications/search'
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleDeleteItem = this.handleDeleteItem.bind(this)
    this.handleCreateorUpdateItem = this.handleCreateorUpdateItem.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleOpenDeletePopup = this.handleOpenDeletePopup.bind(this)
    this.handleDeleteClose = this.handleDeleteClose.bind(this)
    this.handleCloseAlert = this.handleCloseAlert.bind(this)
    this.handleSwitch = this.handleSwitch.bind(this)
    this.handleAutocomplete = this.handleAutocomplete.bind(this)
    this.amountChange = this.amountChange.bind(this)
    this.handleSearch = this.handleSearch.bind(this)
    this.handleSearchInputChange = this.handleSearchInputChange.bind(this)
    this.handleKeyPress = this.handleKeyPress.bind(this)
    this.clearSearch = this.clearSearch.bind(this)
    this.handlePageChange = this.handlePageChange.bind(this)
    this.handleAutocompleteSearch = this.handleAutocompleteSearch.bind(this)
    this.handleShowPopUp = this.handleShowPopUp.bind(this)
    this.cancel = this.cancel.bind(this)
    this.handleShowRecord = this.handleShowRecord.bind(this)
  }

  

  loadBranch(){
    if (localStorage.getItem('branches')) {
      this.setState({ branches: JSON.parse(localStorage.getItem('branches')) })
    } else {
      axios({
        method: 'get',
        url: '/v1/branches',
        headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
      }).then(resp => {
        this.setState({ branches: resp.data })
        localStorage.setItem('branches', JSON.stringify(resp.data))
      })
    }
  }

  
   componentDidMount() {
    this.loadBranch()
    if (localStorage.getItem('departments')) {
      this.setState({ departments: JSON.parse(localStorage.getItem('departments')) })
    } else {
       axios({
        method: 'get',
        url: '/v1/departments',
        headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
      }).then(resp => {
        this.setState({ departments: resp.data })
        localStorage.setItem('departments', JSON.stringify(resp.data))
      })
    }
    var searchUrl = this.state.searchUrl + this.props.location.search
    var searchParams = {}
    const params = new URLSearchParams(this.props.location.search)
    // This is to load all params based on URLSearchParams
    params.forEach(function(value, key) {
      if (key !== 'page') {
        if(key === 'name' || key === 'content'|| key === 'title') {
          searchParams[key] = value.replace(/\*/g,'')
        }else if(key === 'employee_id'){
          axios({
            method: 'get',
            url: '/v1/employees/'+value,
            headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
          }).then(resp => {
            searchParams["employee_id"]=resp.data
          })
        }else if(key === 'branch_id'){
          axios({
            method: 'get',
            url: '/v1/branches/'+value,
            headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
          }).then(resp => {
            searchParams[key]=resp.data
          })
        }else if(key === 'department_id'){
          axios({
            method: 'get',
            url: '/v1/departments/'+value,
            headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
          }).then(resp => {
            searchParams[key]=resp.data
          })
        }else if(key === 'officer_id'){
          axios({
            method: 'get',
            url: '/v1/employees/'+value,
            headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
          }).then(resp => {
            searchParams[key]=resp.data
          })
        }
        
        else {
          searchParams[key] = value
        }
      }
    })

    this.setState({ searchLeave: searchParams })
    this.loadLeaveRecords(searchUrl)
  }

  loadLeaveRecords(url) {
    axios({
      method: 'get',
      url: '/v1/employees/search?per_page=10000',
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {
      this.setState({ officers: resp.data.employees, employees: resp.data.employees, approvingOfficers: resp.data.employees })
    }).catch(error => console.log(error.response))
    
    axios({
      method: 'get',
      url: url,
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {
      var leaveRecord = resp.data.leave_applications,
        result = leaveRecord.reduce(function (r, a) {
          r[a.employee_name] = r[a.employee_name] || [];
          r[a.employee_name].push(a);
          return r;
        }, Object.create(null));
      this.setState({ leaveApplications: result })
      this.setState({ totalPages: resp.data.meta.total_pages, load: true })
    }).catch(error => console.log(error.response))

    if (localStorage.getItem('leave_types')) {
      this.setState({ leaveTypes: JSON.parse(localStorage.getItem('leave_types')) })
    } else {
      axios({
        method: 'get',
        url: '/v1/leave_types',
        headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
      }).then(resp => {
        this.setState({ leaveTypes: resp.data })
        localStorage.setItem('leave_types', JSON.stringify(resp.data))
      })
    }
    
  }

  handleSubmit() {
    const item = this.state.leaveApplication
    var method = ''
    var url = ''
    if (item.id === '') {
      method = 'post'
      url = '/v1/leave_applications/'
    } else {
      method = 'put'
      url = '/v1/leave_applications/' + item.id
    }
    axios({
      method: method,
      url: url,
      data: (item),
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {

      const newData = resp.data
      this.setState({
        leaveApplications: this.state.leaveApplications.filter(leaveApplication => leaveApplication.id !== item.id)
      })
      this.setState({ leaveApplications: [...this.state.leaveApplications, newData] })
      this.setState({ openPopup: false })
      this.setState({
        isOpen: true,
        message: 'Submitted Successfully',
        type: 'success'
      })
    }).catch(error => {
      if (error.response.status === 422) {
        this.setState({ error_messages: 'Name ' + error.response.data.errors.name[0] })
      } else {
        this.setState({
          isOpen: true,
          message: error.response.status.toString() + " Unexpected Error Problem Occurred",
          type: 'error'
        })
        this.setState({ openPopup: false })
      }
    })
  }

  handleDeleteItem() {
    const DeleteItemId = this.state.leaveApplication.id
    axios({
      method: 'delete',
      url: '/v1/leave_applications/' + DeleteItemId,
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(() => {
      this.setState({
        leaveApplications: this.state.leaveApplications.filter(item => item.id !== DeleteItemId)
      })
      this.setState({ openDeletePopup: false })
      this.setState({
        isOpen: true,
        message: 'Deleted Successfully',
        type: 'error'
      })
    }).catch(error => console.log(error.response))
  }

  handleClose() {
    this.setState({ openPopup: false })
    this.setState({ openDeletePopup: false })
    this.setState({ error_messages: '' })
    this.setState({ showPopUp: false, showRecordPopup: false })
  }

  handleCreateorUpdateItem(item, isAdd, model) {
    var textTitle = ''
    if (isAdd) {
      textTitle = 'Create ' + model
    } else {
      textTitle = 'Edit ' + model
    }
    this.setState({ updateAccount: item.account })
    this.setState({ openPopup: true })
    this.setState({ leaveApplication: item })
    this.setState({ title: textTitle })
  }

  handleInputChange(e) {
    this.setState({
      leaveApplication: {
        ...this.state.leaveApplication,
        [e.target.name]: e.target.value
      }
    })
  }

  handleOpenDeletePopup(item, model) {
    this.setState({ model: model })
    this.setState({ leaveApplication: item })
    this.setState({ openDeletePopup: true })
  }

  handleDeleteClose() {
    this.setState({ openDeletePopup: false })
  }

  handleCloseAlert(event, reason) {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ isOpen: false })
  }

  handleSwitch(event) {
    const value = event.target.checked
    this.setState({
      leaveApplication: {
        ...this.state.leaveApplication,
        [event.target.name]: value
      }
    })
  }

  handleAutocomplete(event, values) {
    if (values !== null) {
      this.setState({ updateAccount: values })
      this.setState({
        leaveApplication: {
          ...this.state.leaveApplication,
          account_id: values.id
        }
      })
    }
  }

  handleShowPopUp(item) {
    var Id = item.employee.id
    axios({
      method: 'get',
      url: '/v1/employees/' + Id + '/leave_applications',
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then((resp) => {
      this.setState({ showLeaveRecordHistory: resp.data })
      this.setState({ showPopUp: true })
    }).catch(error => console.log(error.response))
  }

  handleShowRecord(item) {
    axios({
      method: 'get',
      url: '/v1/employees/' + item.employee_id + '/leave_credits',
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {
      this.setState({ leaveCredits: resp.data })
    }).catch(error => { this.setState({ isOpen: true, message: error.response.status.toString() + " Unexpected Error Problem Occurred", type: 'error' }) })
    this.setState({ leaveApplication: item })
    this.setState({ appliedDays: item.no_of_days })
    axios({
      method: 'get',
      url: '/v1/leave_applications/' + item.id + '/attachments',
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {
      this.setState({ attachments: resp.data })
    })
    item['from_morning'] = item.from_morning ? "true" : "false"
    item['to_afternoon'] = item.to_afternoon ? "true" : "false"
    this.setState({ showRecordPopup: true })
  }

  amountChange(e) {
    this.setState({
      leaveApplication: {
        ...this.state.leaveApplication,
        amount: e.floatValue
      }
    })
  }
  
  getSearchParams() {
    var searchParams = []
    // This is to load all params based on searchParams state.
    Object.entries(this.state.searchLeave).map(([key, value]) => {
      if (value != '' && value != undefined && value != null) {
        if(key.includes('_id') && value.id !== undefined && value.id !== null) {
          searchParams.push([key, value.id].join("="))
        }else {
          searchParams.push([key, value].join("="))
        }
      }
    })
    return searchParams
  }

  handleSearch() {
    this.setState({load: false})
    const { history } = this.props
    var searchParams = this.getSearchParams().join("&")
    const params = new URLSearchParams()
    params.append("/search", searchParams)
    history.push({ search: searchParams })
    this.loadLeaveRecords([this.state.searchUrl, searchParams].join("?"))

  }

  handleSearchInputChange(e) {
    var lastDay = null
    if (e.target.name === 'starts_on_from') {
      lastDay = moment(e.target.value).endOf('month').format("YYYY-MM-DD")
      this.setState({
        searchLeave: {
          ...this.state.searchLeave,
          [e.target.name]: e.target.value,
          starts_on_to: lastDay
        }
      })
    } else {
      this.setState({
        searchLeave: {
          ...this.state.searchLeave,
          [e.target.name]: e.target.value
        }
      })
    }
  }

  handleKeyPress(e) {
    if (e.key === 'Enter') {
      this.handleSearch()
    }
  }

  clearSearch() {
    const { history } = this.props
    history.push({ search: '' })
    this.setState({ withResultsLabel: false })
    this.loadLeaveRecords('/v1/leave_applications/search')
    this.setState({ searchLeave: { request_status: '', branch_id: null, leave_type_id: null, employee_id: null, officer_id: null, department_id: null, starts_on_from: '',starts_on_to: '' } })
    this.setState({ leaveApplicationTypes: [] })
    this.setState({ branches: [] })
    this.setState({ employees: [] })
    this.setState({ officers: [] })
  }

  handlePageChange(event, value) {
    var searchParams = this.getSearchParams()
    searchParams.push(["page", value].join("="))
    searchParams = searchParams.join("&")

    const { history } = this.props
    const params = new URLSearchParams()

    params.append("/search", searchParams)
    history.push({ search: searchParams })
    this.setState({ currentPage: value })
    this.loadLeaveRecords([this.state.searchUrl, searchParams].join("?"))
  }

  handleAutocompleteSearch(event, values) {
    if (event.target.id.includes("branch_id")) {
      this.setState({
        searchLeave: {
          ...this.state.searchLeave,
          branch_id: values
        }
      })
    } else if (event.target.id.includes("employee_id")) {
      this.setState({
        searchLeave: {
          ...this.state.searchLeave,
          employee_id: values
        }
      })
    }
    else if (event.target.id.includes("officer_id")) {
      this.setState({
        searchLeave: {
          ...this.state.searchLeave,
          officer_id: values
        }
      })
    }
    else {
      if (values !== null) {
        this.setState({
          searchLeave: {
            ...this.state.searchLeave,
            department_id: values
          }
        })
      }
    }
  }

  cancel() {
    axios({
      method: 'put',
      url: '/v1/leave_applications/' + this.state.leaveApplication.id + '/cancel',
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(() => {
      this.loadLeaveRecords('/v1/leave_applications/search')
      this.setState({ showPopUp: false })
    }).catch(() => { this.setState({ isOpen: true, message: " Unexpected Error Problem Occurred", type: 'error' }) })
  }


  render() {
    return (
      <>
        {
          this.state.load ? (
            <div>

              <LeaveRecordsSearch
                clearSearch={this.clearSearch}
                item={this.state.searchLeave}
                branches={this.state.branches}
                leaveTypes={this.state.leaveTypes}
                employees={this.state.employees}
                officers={this.state.officers}
                departments={this.state.departments}
                search={this.handleSearch}
                handleChange={this.handleSearchInputChange}
                handleAutocompleteSearch={this.handleAutocompleteSearch}
                handleKeyPress={this.handleKeyPress}
              />

              <SimpleTableGroupBy
                initialItem={this.state.initialProduct}
                items={this.state.leaveApplications}
                model={this.state.model}
                onOpenDeletePopup={this.handleOpenDeletePopup}
                headers={this.state.tableHead}
                withShowPopUp={this.state.withShowPopUp}
                handleShowPopUp={this.handleShowPopUp}
                withRecordPopup={true}
                handleShowRecord={this.handleShowRecord}
                withEdit={this.state.withEdit}
                totalPages={this.state.totalPages}
                currentPage={this.state.currentPage}
                withPagination={this.state.withPagination}
                groupLabel={this.state.groupLabel}
                handlePageChange={this.handlePageChange}
                icon={<ExitToAppOutlinedIcon fontSize="large" />}
              />

              <SimplePopUp
                openPopup={this.state.showPopUp}
                title="Leave History"
                handleClose={this.handleClose}
                maxWidth='md'
              >
                <SimpleTable
                  model={this.state.model} headers={['leave_type.name', 'inclusive_dates', 'without_pay', 'reason', 'request_status']}
                  items={this.state.showLeaveRecordHistory.sort(
                    (a, b) => new Date(a.starts_on).getTime() - new Date(b.ends_on).getTime()
                  ).reverse()}
                  removeLabel={true}
                />

              </SimplePopUp>


              <SimplePopUp
                openPopup={this.state.showRecordPopup}
                title="Leave Record"
                handleClose={this.handleClose}
                maxWidth='sm'
              >
                <ApprovalForm
                  item={this.state.leaveApplication}
                  attachments={this.state.attachments}
                  approvingOfficers={this.state.approvingOfficers}
                  admin={true}
                  cancel={this.cancel}
                />

              </SimplePopUp>

              <SimpleDeletePopUp
                openDeletePopup={this.state.openDeletePopup}
                item={this.state.leaveApplication}
                delete={this.handleDeleteItem}
                handleDeleteClose={this.handleClose}
                model={this.state.model}
              />
              <AlertMessage
                notify={this.state.notify}
                handleCloseAlert={this.handleCloseAlert}
                isOpen={this.state.isOpen}
                type={this.state.type}
                message={this.state.message}
              />
            </div>
          ) : (
            <ProgressBar model={this.state.model} />
          )}
      </>
    )
  }
}

export default withRouter(LeaveRecords)
LeaveRecords.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object
}
