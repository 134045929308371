import React from 'react'
import styles from './Attendances.module.css'

import PropTypes from 'prop-types'

import {
  TextField,
  Button,
  Grid
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab';
class AttendanceSearchForm extends React.Component {
  render() {
    return (
      <div>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Autocomplete
              value={this.props.item.employee}
              id="employee"
              size="small"
              fullWidth
              name="employee"
              options={this.props.employees}
              getOptionLabel={(option) => option.name_formal || ''}
              getOptionSelected={(option, value) => option.id === value.id}
              onChange={this.props.handleAutocompleteChange}
              renderInput={(params) => (
                <TextField {...params} name="employee" variant="outlined" label="Employee" required />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              type="date"
              autoComplete="off"
              variant="outlined"
              size="small"
              name="date_worked"
              required
              fullWidth={true}
              label="Date Worked"
              onChange={this.props.handleChange}
              value={this.props.item.date_worked}
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>
        </Grid>

        <div className={styles.actionButton}>
          <Button variant="outlined" id="attendance-search" color="primary"
            onClick={() => this.props.search()}>Search</Button>
          <Button variant="outlined" id="attendance-clearSearch" color="secondary"
            onClick={() => this.props.clearSearch()}>Clear</Button>
        </div>

      </div>
    )
  }
}

export default AttendanceSearchForm

AttendanceSearchForm.propTypes = {
  handleChange: PropTypes.func,
  item: PropTypes.object,
  employees: PropTypes.array,
  search: PropTypes.func,
  clearSearch: PropTypes.func,
  handleAutocompleteChange: PropTypes.func,
}
