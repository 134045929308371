import React from 'react'

import PropTypes from 'prop-types'
import styles from './Attendances.module.css'
import {
  Button, Grid, TextField
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab';

class AttendanceForm extends React.Component {
  render() {


    return (
      <>
        <Grid container spacing={1}>
          {
            this.props.employeeView ? null :
              <Grid item xs={12}>
                <Autocomplete
                  value={this.props.employee}
                  id="employee"
                  size="small"
                  fullWidth
                  name="employee"
                  options={this.props.employees}
                  getOptionLabel={(option) => option.name_formal || ''}
                  getOptionSelected={(option, value) => option.id === value.id}
                  onChange={this.props.handleAutocompleteChange}
                  renderInput={(params) => (
                    <TextField {...params} name="employee" variant="outlined" label="Employee" required />
                  )}
                />
              </Grid>
          }


          <Grid item xs={12} sm={4}>
            <TextField
              type="date"
              autoComplete="off"
              variant="outlined"
              size="small"
              name="date_worked"
              required
              fullWidth={true}
              label="Date Worked"
              onChange={this.props.handleChange}
              value={this.props.item.date_worked}

              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              type="time"
              autoComplete="off"
              variant="outlined"
              size="small"
              name="time_in"
              required
              fullWidth={true}
              label="Time In"
              onChange={this.props.handleChange}
              value={this.props.item.time_in}
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              type="time"
              autoComplete="off"
              variant="outlined"
              size="small"
              name="time_out"
              required
              fullWidth={true}
              label="Time Out"
              onChange={this.props.handleChange}
              value={this.props.item.time_out}
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              autoComplete="off"
              variant="outlined"
              size="small"
              name="description"
              fullWidth={true}
              label="Description"
              onChange={this.props.handleChange}
              value={this.props.item.description}
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              autoComplete="off"
              variant="outlined"
              size="small"
              name="remarks"
              fullWidth={true}
              label="Remarks"
              onChange={this.props.handleChange}
              value={this.props.item.remarks}
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>

        </Grid>
        <div className={styles.actionButton} >
          <Button name="photo-submit" id="photo-submit"
            variant="outlined" color="primary" onClick={() => this.props.submit()}>Save</Button>
        </div>
      </>
    )
  }
}

export default AttendanceForm

AttendanceForm.propTypes = {

  submit: PropTypes.func,
  handleAutocompleteChange: PropTypes.func,
  employee: PropTypes.object,
  item: PropTypes.object,
  employees: PropTypes.array,
  handleChange: PropTypes.func,
  employeeView: PropTypes.bool,
}
