import React from 'react'
import PropTypes from 'prop-types'
import styles from './LeaveCredits.module.css'

import {
  TextField,
  Button,
  MenuItem, 
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'

class LeaveCreditsForm extends React.Component {
  render() {
    return (
      <div>
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <Autocomplete
          value={this.props.employee}
          id="employee"
          size="small"
          fullWidth
          name="employee"
          options={this.props.employees}
          getOptionLabel={(option) => option.name_formal || ''}
          getOptionSelected={(option, value) => option.id === value.id}
          onChange={this.props.handleAutoCompleteChange}
          renderInput={(params) => (
            <TextField {...params} name="employee" variant="outlined" label="Employee" required />
          )}
        />
        <TextField
          select
          className={styles.yearTextField}
          fullWidth
          size="small"
          name='year_of_effect'
          value={this.props.year_of_effect}
          onChange={this.props.handleChange}
          variant="outlined"
          label="Year of Effect"
          required

        >
          {
            this.props.years.map((year, idx) => (
              <MenuItem key={idx} value={year}>{year}</MenuItem>
            ))

          }
        </TextField>
        </div>

        <>
          <div className={styles.headerContent}>
            <div> Leave Credits</div>
            <IconButton
              name="addLeaveCredits"
              onClick={this.props.addLeaveCredit}>
              <AddCircleOutlineIcon fontSize="medium" />
            </IconButton>
          </div>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <b>Leave Type</b>
                </TableCell>
                <TableCell>
                  <b>No of Days</b>
                </TableCell>
                <TableCell/>
              </TableRow>
            </TableHead>
            <TableBody className={styles.tableBody} name="leaveCredits">
              {this.props.leaveCredits.map((val, idx) => (
                <>
                  <TableRow key={`leaveCredit-${idx}`}>
                    <TableCell style={{width:'50%'}}>
                      <Autocomplete
                        value={this.props.leaveCredits[idx].leave_type}
                        id="leave_type"
                        size="small"
                        fullWidth
                        className={styles.textFields}
                        name="leave_type"
                        options={this.props.leaveTypes}
                        getOptionLabel={(option) => option.name || ''}
                        getOptionSelected={(option, value) => option.name === value.name}
                        onChange={(event, value) => this.props.handleLeaveTypeAutocompleteChange(idx, value)}
                        renderInput={(params) => (
                          <TextField {...params} name="leave_type" className={styles.textFields} variant="outlined" label="Leave Type" required />
                        )}
                      />
                    </TableCell>
                    <TableCell style={{width:'50%'}}>
                      <TextField
                      type='number'
                        autoComplete="off"
                        className={styles.textFields}
                        variant="outlined"
                        size="small"
                        name="no_of_days"
                        fullWidth={true}
                        onChange={(event) => this.props.handleLeaveCredit(event, idx)}
                        value={this.props.leaveCredits[idx].no_of_days}

                      />
                    </TableCell>
                    <TableCell/>

                  </TableRow>
                  <TableRow
                    className={styles.actionButtonHoverable}
                    align="right">
                    <IconButton
                      color="secondary"
                      name={`deleteLeaveCredit-${idx}`}
                      onClick={() => this.props.removeLeaveCredit(idx)}>
                      <DeleteForeverIcon />
                    </IconButton>
                  </TableRow>
                </>
              ))}
            </TableBody>
          </Table>
        </>
        <div className={styles.actionButton}>
          <Button
            variant="outlined"
            color="primary"
            type="submit"
            onClick={this.props.handleSubmit}
          >
            Save
          </Button>
        </div>
      </div>
    )
  }
}
export default LeaveCreditsForm

LeaveCreditsForm.propTypes = {
  resources: PropTypes.object,
  handleAutoCompleteChange: PropTypes.func,
  handleChange: PropTypes.func,
  employees: PropTypes.array,
  employee: PropTypes.object,
  handleLeaveCredit: PropTypes.func,
  handleLeaveTypeAutocompleteChange: PropTypes.func,
  removeLeaveCredit: PropTypes.func,
  addLeaveCredit: PropTypes.func,
  leaveCredits: PropTypes.array,
  leaveTypes: PropTypes.array,
  year_of_effect: PropTypes.string,
  years: PropTypes.array,
  handleSubmit: PropTypes.func

}