import React from 'react'


import LinearProgress from '@material-ui/core/LinearProgress'
import styles from './ProgressBar.module.css'

import PropTypes from 'prop-types'

class ProgressBar extends React.Component {
  render () {
    return (
      <div className={styles.Bodylinear}>
        <LinearProgress  className={styles.colorLiner}/>
        <div className={styles.modelName}>
        {this.props.model?(this.props.model.toLowerCase()==="home"?(this.props.model):(this.props.model+'s')):(null)}
        </div>
      </div>
    )
  }
}

export default ProgressBar;

ProgressBar.propTypes = {
  model: PropTypes.string
}
