import React from 'react'
import styles from './Employees.module.css'

import PropTypes from 'prop-types'

import {
    TextField, InputAdornment, IconButton, Tooltip,
    Button, Grid, MenuItem
} from '@material-ui/core'
import NumberFormat from 'react-number-format'
import { Autocomplete } from '@material-ui/lab'
import RefreshIcon from '@material-ui/icons/Refresh';
class EmployeeForm extends React.Component {
    validate() {
        var disabled = false
        if (!this.props.item.id) {
            if (!this.props.employment_statuses_attributes.remarks) {
                disabled = true
            }
            if (!this.props.salaries_attributes.date_effective) {
                disabled = true
            }
        }
        return disabled
    }
    render() {
        return (
            <div>
                <form>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                autoComplete="off"
                                className={styles.textFields}
                                variant="outlined"
                                size="small"
                                name="reference_no"
                                fullWidth
                                label="Employee Number"
                                onChange={this.props.handleProfileChange}
                                value={this.props.profile_attributes.reference_no}
                                id="employee-reference_no"
                                InputLabelProps={{
                                    shrink: true
                                }}
                                required
                                InputProps={{
                                    endAdornment:
                                        <InputAdornment position="end" >{this.props.profile_attributes.reference_no ? <Tooltip title="Get Reference No"><IconButton id="refresh_ref_no" name="refresh_ref_no" onClick={this.props.getReferenceNo}><RefreshIcon /></IconButton></Tooltip> : null}</InputAdornment>,
                                }}
                            />
                            <p className={styles.error}>{this.props.error.reference_no ? 'Employee Number ' + this.props.error.reference_no : null}</p>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                autoComplete="off"
                                className={styles.textFields}
                                variant="outlined"
                                size="small"
                                name="email"
                                required
                                fullWidth
                                label="Email"
                                onChange={this.props.handleChange}
                                value={this.props.item.email}
                                id="employee-email"
                                InputLabelProps={{
                                    shrink: true
                                }}
                            />
                            <p className={styles.error}>{this.props.error.email ? 'Email ' + this.props.error.email : null}</p>

                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                autoComplete="off"
                                className={styles.textFields}
                                variant="outlined"
                                size="small"
                                name="username"
                                required
                                fullWidth
                                label="Username"
                                onChange={this.props.handleChange}
                                value={this.props.item.username}
                                id="employee-username"
                                InputLabelProps={{
                                    shrink: true
                                }}
                            />
                            <p className={styles.error}>{this.props.error.username ? 'Username ' + this.props.error.username : null}</p>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextField
                                autoComplete="off"
                                className={styles.textFields}
                                variant="outlined"
                                size="small"
                                name="last_name"
                                required
                                fullWidth
                                label="Last Name"
                                onChange={this.props.handleProfileChange}
                                value={this.props.profile_attributes.last_name}
                                id="employee-last_name"
                                InputLabelProps={{
                                    shrink: true
                                }}
                            />
                            <p className={styles.error}>{this.props.error.last_name ? (this.props.error.last_name ? 'Last name ' + this.props.error.last_name : null) : null}</p>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextField
                                autoComplete="off"
                                className={styles.textFields}
                                variant="outlined"
                                size="small"
                                name="first_name"
                                fullWidth
                                required
                                label="First Name"
                                onChange={this.props.handleProfileChange}
                                value={this.props.profile_attributes.first_name}
                                id="employee-first_name"
                                InputLabelProps={{
                                    shrink: true
                                }}
                            />
                            <p className={styles.error}>{this.props.error.first_name ? (this.props.error.first_name ? 'First name ' + this.props.error.first_name : null) : null}</p>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextField
                                autoComplete="off"
                                className={styles.textFields}
                                variant="outlined"
                                size="small"
                                name="middle_name"
                                fullWidth
                                required
                                label="Middle Name"
                                onChange={this.props.handleProfileChange}
                                value={this.props.profile_attributes.middle_name}
                                id="employee-middle_name"
                                InputLabelProps={{
                                    shrink: true
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextField
                                autoComplete="off"
                                className={styles.textFields}
                                variant="outlined"
                                size="small"
                                name="suffix"
                                fullWidth
                                label="Suffix"
                                onChange={this.props.handleProfileChange}
                                value={this.props.profile_attributes.suffix}
                                id="employee-suffix"
                                InputLabelProps={{
                                    shrink: true
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <TextField
                                autoComplete="off"
                                className={styles.textFields}
                                variant="outlined"
                                size="small"
                                name="address"
                                fullWidth
                                label="Address"
                                required
                                onChange={this.props.handleProfileChange}
                                value={this.props.profile_attributes.address}
                                id="employee-address"
                                InputLabelProps={{
                                    shrink: true
                                }}
                            />
                            <p className={styles.error}>{this.props.error.address ? (this.props.error.address ? 'Address ' + this.props.error.address : null) : null}</p>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <TextField
                                autoComplete="off"
                                className={styles.textFields}
                                variant="outlined"
                                size="small"
                                name="home_no"
                                fullWidth
                                label="Home Number"
                                onChange={this.props.handleProfileChange}
                                value={this.props.profile_attributes.home_no}
                                id="employee-home_no"
                                InputLabelProps={{
                                    shrink: true
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <TextField
                                autoComplete="off"
                                className={styles.textFields}
                                variant="outlined"
                                size="small"
                                name="gender"
                                fullWidth
                                select
                                label="Gender"
                                onChange={this.props.handleProfileChange}
                                value={this.props.profile_attributes.gender || ''}
                                id="employee-gender"
                            >
                                <MenuItem value="male">Male</MenuItem>
                                <MenuItem value="female">Female</MenuItem>
                            </TextField>
                        </Grid>

                        <Grid item xs={12} sm={3}>
                            <TextField
                                type="date"
                                autoComplete="off"
                                className={styles.textFields}
                                variant="outlined"
                                size="small"
                                name="birth_date"
                                required
                                fullWidth={true}
                                label="Birth Date"
                                onChange={this.props.handleProfileChange}
                                value={this.props.profile_attributes.birth_date}
                                id="employee-birth_date"
                                InputLabelProps={{
                                    shrink: true
                                }}
                            />
                            <p className={styles.error}>{this.props.error.birth_date ? (this.props.error.birth_date ? 'Birthdate ' + this.props.error.birth_date : null) : null}</p>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextField
                                autoComplete="off"
                                className={styles.textFields}
                                variant="outlined"
                                size="small"
                                name="civil_status"
                                fullWidth
                                select
                                label="Civil Status"
                                onChange={this.props.handleProfileChange}
                                value={this.props.profile_attributes.civil_status || ''}
                                id="employee-civil_status"
                            >
                                <MenuItem value="single">Single</MenuItem>
                                <MenuItem value="married">Married</MenuItem>
                                <MenuItem value="widowed">Widowed</MenuItem>
                                <MenuItem value="separated">Separated</MenuItem>
                                <MenuItem value="divorced">Divorced</MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextField
                                autoComplete="off"
                                className={styles.textFields}
                                variant="outlined"
                                size="small"
                                name="mobile_no"
                                fullWidth
                                label="Mobile Number"
                                onChange={this.props.handleProfileChange}
                                value={this.props.profile_attributes.mobile_no}
                                id="employee-mobile_no"
                                InputLabelProps={{
                                    shrink: true
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={3}>
                            <Autocomplete
                                value={this.props.branch}
                                id="branch"
                                name="branch"
                                fullWidth
                                required
                                className={styles.autoCompleteField}
                                size="small"
                                options={(this.props.branches).sort(function (a, b) {
                                    if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
                                    if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
                                    return 0;
                                })}
                                getOptionLabel={(option) => option.name || ''}
                                getOptionSelected={(option, value) => option.name === value.name}
                                onChange={this.props.handleAutoComplete}
                                renderInput={(params) => (
                                    <TextField {...params} name="branch" variant="outlined" label="Branch" />
                                )}
                            />
                        </Grid>

                        <Grid item xs={12} sm={3}>
                            <Autocomplete
                                value={this.props.department}
                                id="department"
                                name="department"
                                disabled={!this.props.departments.length > 0}
                                fullWidth
                                required={this.props.departments.length > 0}
                                className={styles.autoCompleteField}
                                size="small"
                                options={(this.props.departments).sort(function (a, b) {
                                    if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
                                    if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
                                    return 0;
                                })}
                                getOptionLabel={(option) => option.name || ''}
                                getOptionSelected={(option, value) => option.name === value.name}
                                onChange={this.props.handleAutoComplete}
                                renderInput={(params) => (
                                    <TextField {...params} name="department" variant="outlined" label="Department" />
                                )}
                            />
                        </Grid>

                        <Grid item xs={12} sm={3}>
                            <Autocomplete
                                value={this.props.division}
                                id="division"
                                name="division"
                                fullWidth
                                className={styles.autoCompleteField}
                                size="small"
                                options={(this.props.divisions).sort(function (a, b) {
                                    if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
                                    if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
                                    return 0;
                                })}
                                getOptionLabel={(option) => option.name || ''}
                                getOptionSelected={(option, value) => option.name === value.name}
                                onChange={this.props.handleAutoComplete}
                                renderInput={(params) => (
                                    <TextField {...params} name="division" variant="outlined" label="Division" />
                                )}
                            />
                        </Grid>

                        <Grid item xs={12} sm={3}>
                            <Autocomplete
                                value={this.props.position}
                                id="position"
                                required
                                name="position"
                                fullWidth
                                className={styles.autoCompleteField}
                                size="small"
                                options={(this.props.positions).sort(function (a, b) {
                                    if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
                                    if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
                                    return 0;
                                })}
                                getOptionLabel={(option) => option.name || ''}
                                getOptionSelected={(option, value) => option.name === value.name}
                                onChange={this.props.handleAutoComplete}
                                renderInput={(params) => (
                                    <TextField {...params} name="position" required variant="outlined" label="Position" />
                                )}
                            />
                            <p className={styles.error}>{this.props.error.position ? 'Position ' + this.props.error.position : null}</p>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextField
                                autoComplete="off"
                                className={styles.textFields}
                                variant="outlined"
                                size="small"
                                name="is_active"
                                fullWidth
                                required
                                select
                                label="Active"
                                onChange={this.props.handleProfileChange}
                                value={this.props.profile_attributes.is_active || ''}
                                id="employee-is_active"
                            >
                                <MenuItem value="true">Yes</MenuItem>
                                <MenuItem value="false">No</MenuItem>
                            </TextField>
                        </Grid>

                        <Grid items xs={12}>
                            <h3 className={styles.leftMargin}>Employee Details</h3>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                autoComplete="off"
                                className={styles.textFields}
                                variant="outlined"
                                size="small"
                                name="bank_account_no"
                                fullWidth
                                label="Bank Account Number"
                                onChange={this.props.handleProfileChange}
                                value={this.props.profile_attributes.bank_account_no}
                                id="employee-bank_account_no"
                                InputLabelProps={{
                                    shrink: true
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                autoComplete="off"
                                className={styles.textFields}
                                variant="outlined"
                                size="small"
                                name="tin_no"
                                fullWidth
                                label="TIN"
                                onChange={this.props.handleProfileChange}
                                value={this.props.profile_attributes.tin_no}
                                id="employee-tin_no"
                                InputLabelProps={{
                                    shrink: true
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                autoComplete="off"
                                className={styles.textFields}
                                variant="outlined"
                                size="small"
                                name="sss_no"
                                fullWidth
                                label="SSS No."
                                onChange={this.props.handleProfileChange}
                                value={this.props.profile_attributes.sss_no}
                                id="employee-sss_no"
                                InputLabelProps={{
                                    shrink: true
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextField
                                autoComplete="off"
                                className={styles.textFields}
                                variant="outlined"
                                size="small"
                                name="philhealth_no"
                                fullWidth
                                label="PhilHealth No."
                                onChange={this.props.handleProfileChange}
                                value={this.props.profile_attributes.philhealth_no}
                                id="employee-philhealth_no"
                                InputLabelProps={{
                                    shrink: true
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextField
                                autoComplete="off"
                                className={styles.textFields}
                                variant="outlined"
                                size="small"
                                name="pagibig_no"
                                fullWidth
                                label="Pag-ibig No."
                                onChange={this.props.handleProfileChange}
                                value={this.props.profile_attributes.pagibig_no}
                                id="employee-pagibig_no"
                                InputLabelProps={{
                                    shrink: true
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextField
                                autoComplete="off"
                                className={styles.textFields}
                                variant="outlined"
                                size="small"
                                name="office_no"
                                fullWidth
                                label="Office No."
                                onChange={this.props.handleProfileChange}
                                value={this.props.profile_attributes.office_no}
                                id="employee-office_no"
                                InputLabelProps={{
                                    shrink: true
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextField
                                autoComplete="off"
                                className={styles.textFields}
                                variant="outlined"
                                size="small"
                                name="is_tax_exempted"
                                fullWidth
                                select
                                label="Tax Exempted"
                                onChange={this.props.handleProfileChange}
                                value={this.props.profile_attributes.is_tax_exempted.toString() || ''}
                                id="employee-is_tax_exempted"
                                InputLabelProps={{
                                    shrink: true
                                }}
                            >
                                <MenuItem value=""></MenuItem>
                                <MenuItem value={true}>Yes</MenuItem>
                                <MenuItem value={false}>No</MenuItem>
                            </TextField>
                        </Grid>
                    </Grid>
                    {
                        this.props.item.id ? null : (
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={6}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} sm={12}>
                                            <h3 className={styles.leftMargin}>Employee Salary</h3>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                required
                                                aria-required
                                                type="date"
                                                autoComplete="off"
                                                // className={styles.leftTextField}
                                                variant="outlined"
                                                size="small"
                                                name="date_effective"
                                                fullWidth={true}
                                                label="Date Effective"
                                                onChange={this.props.handleSalaryChange}
                                                value={this.props.salaries_attributes.date_effective}
                                                id="employee-date_effective"
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                autoComplete="off"
                                                className={styles.leftText}
                                                variant="outlined"
                                                size="small"
                                                name="salary_type"
                                                fullWidth
                                                select
                                                label="Salary Type"
                                                required
                                                onChange={this.props.handleSalaryChange}
                                                value={this.props.salaries_attributes.salary_type}
                                                id="employee-salary_type"
                                            >
                                                {
                                                    this.props.salaryTypes.length > 0 ? (
                                                        this.props.salaryTypes.map((state, idx) => (
                                                            <MenuItem className={styles.capitalize} value={state} key={idx}>{state.replace(/_/g, ' ')}</MenuItem>
                                                        ))
                                                    ) : null
                                                }
                                            </TextField>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <NumberFormat
                                                customInput={TextField}
                                                autoComplete="off"
                                                variant="outlined"
                                                className={styles.amountBody}
                                                size="small"
                                                name="rate"
                                                fullWidth
                                                label="Rate"
                                                required
                                                thousandSeparator={true}
                                                onValueChange={this.props.handleRateChange}
                                                value={this.props.salaries_attributes.rate}
                                                decimalSeparator="."
                                                fixedDecimalScale={true}
                                                prefix={'₱'}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} >
                                            <TextField
                                                autoComplete="off"
                                                variant="outlined"
                                                size="small"
                                                name="remarks"
                                                fullWidth
                                                label="Remarks"
                                                onChange={this.props.handleSalaryChange}
                                                value={this.props.salaries_attributes.remarks}
                                                id="employee-salary_remarks"
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} sm={12}>
                                            <h3 className={styles.leftMargin}>Employment Status</h3>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                type="date"
                                                autoComplete="off"
                                                className={styles.leftTextField}
                                                variant="outlined"
                                                size="small"
                                                required
                                                name="date_effective"
                                                fullWidth={true}
                                                label="Date Effective"
                                                onChange={this.props.handleEmploymentChange}
                                                value={this.props.employment_statuses_attributes.date_effective}
                                                id="employment_statuses_attributes-date_effective"
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                            />

                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                autoComplete="off"
                                                className={styles.rightText}
                                                variant="outlined"
                                                size="small"
                                                name="kind"
                                                fullWidth
                                                select
                                                label="Kind"
                                                required
                                                onChange={this.props.handleEmploymentChange || ''}
                                                value={this.props.employment_statuses_attributes.kind}
                                                id="employee-kind"
                                            >
                                                {
                                                    this.props.kinds.length > 0 ? (
                                                        this.props.kinds.map((kind, idx) => (
                                                            <MenuItem className={styles.capitalize} value={kind} key={idx}>{kind}</MenuItem>
                                                        ))
                                                    ) : null
                                                }

                                            </TextField>
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                autoComplete="off"
                                                className={styles.leftText}
                                                variant="outlined"
                                                size="small"
                                                name="state"
                                                fullWidth
                                                select
                                                label="State"
                                                required
                                                onChange={this.props.handleEmploymentChange}
                                                value={this.props.employment_statuses_attributes.state || ''}
                                                id="employee-state"
                                            >
                                                {
                                                    this.props.states.length > 0 ? (
                                                        this.props.states.map((state, idx) => (
                                                            <MenuItem className={styles.capitalize} value={state} key={idx}>{state.replace(/_/g, ' ')}</MenuItem>
                                                        ))
                                                    ) : null
                                                }

                                            </TextField>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                autoComplete="off"
                                                className={styles.rightText}
                                                variant="outlined"
                                                size="small"
                                                name="remarks"
                                                required
                                                fullWidth
                                                label="Remarks"
                                                onChange={this.props.handleEmploymentChange}
                                                value={this.props.employment_statuses_attributes.remarks}
                                                id="employment_statuses_attributes-remarks"
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )
                    }
                </form>

                <div className={styles.actionButton}>
                    <Button variant="outlined" id="employee-submit" color="primary" onClick={() => this.props.submit()}
                        disabled={
                            this.validate()
                            || !this.props.profile_attributes.first_name
                            || !this.props.profile_attributes.last_name
                            || !this.props.profile_attributes.address
                            || !this.props.item.email
                            || !this.props.item.username

                        }
                    >Save</Button>
                </div>
            </div>
        )
    }
}
export default EmployeeForm

EmployeeForm.propTypes = {
    handleChange: PropTypes.func,
    item: PropTypes.object,
    submit: PropTypes.func,
    handleAutoComplete: PropTypes.func,
    branch: PropTypes.object,
    position: PropTypes.object,
    division: PropTypes.object,
    department: PropTypes.object,
    departments: PropTypes.array,
    divisions: PropTypes.array,
    positions: PropTypes.array,
    branches: PropTypes.array,
    employment_statuses_attributes: PropTypes.object,
    salaries_attributes: PropTypes.object,
    profile_attributes: PropTypes.object,
    handleProfileChange: PropTypes.func,
    handleSalaryChange: PropTypes.func,
    handleEmploymentChange: PropTypes.func,
    handleRateChange: PropTypes.func,
    kinds: PropTypes.array,
    states: PropTypes.array,
    error: PropTypes.object,
    getReferenceNo: PropTypes.func,
    salaryTypes: PropTypes.array,
}
