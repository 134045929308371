import React from 'react'
import PropTypes from 'prop-types'
import styles from './Announcements.module.css'
import moment from 'moment'

class Announcement extends React.Component {
    
    render() {
       const item = this.props.item
        return (
            <>
            <div dangerouslySetInnerHTML={{__html: item.content}}></div>
            <br/>
            <div className={styles.author}>
                {item.officer_name ? (item.officer_name) : null}{item.employee ? (item.employee.name) : null} -{moment(item.created_at).format('L')}
            </div>
            </>
        )
    }
}
export default Announcement
Announcement.propTypes = {
    item: PropTypes.object,
}
