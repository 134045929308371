import React from 'react'

import PropTypes from 'prop-types'

import {
    Button, TextField,Grid,Checkbox
} from '@material-ui/core'
import styles from "./Photos.module.css";
import VerticalAlignBottomIcon from '@material-ui/icons/VerticalAlignBottom';


class PhotoForm extends React.Component {
    render() {
        const { file } = this.props.photo;
        let filename = null;
        filename = file
            ? (<span><b>File Selected</b> - {file.name}</span>)
            : (<span>Drag your image here or click in this area.</span>);

        return (
            <div>
                <form>
                <div className={styles.valueDetails}>
                         <TextField
                            autoComplete="off"
                            variant="outlined"
                            className={styles.rank}
                            size="small"
                            name="rank"
                            fullWidth
                            required
                            label="Rank"
                            type="number"
                            onChange={this.props.onchange}
                            value={this.props.photo.rank}
                            InputLabelProps={{
                                shrink: true
                            }}
                        />

                        <div><b>Public</b><Checkbox checked={this.props.photo.publish} onChange={this.props.handleCheck}/> </div>
                        </div>
                    <div className={styles.formBody}>

                        <input
                            id="file"
                            type="file"
                            name="file"
                            className={styles.input}
                            onChange={(e) => this.props.onFileChange(e)}
                            accept="image/*"
                        />
                        <label
                            className={styles.filelabel}
                            htmlFor="file">
                            <VerticalAlignBottomIcon fontSize="large" />
                            <br />
                            {filename}

                        </label>
                        <Grid container spacing={1}>
                        <TextField
                            autoComplete="off"
                            variant="outlined"
                            className={styles.textFields}
                            size="small"
                            name="description"
                            fullWidth
                            label="Description"
                            onChange={this.props.onchange}
                            value={this.props.photo.description}
                            InputLabelProps={{
                                shrink: true
                            }}
                        />

                        </Grid>
                        <br />
                        <p className={styles.fileError}><b>{this.props.error}</b></p>
                    </div>
                </form>
                <div className={styles.actionAttactmentButton}>
                    <Button name="photo-submit" id="photo-submit"
                     variant="outlined" color="primary" onClick={() => this.props.submit()}>Save</Button>
                </div>
            </div>
        )
    }
}

export default PhotoForm

PhotoForm.propTypes = {
    onFileChange: PropTypes.func,
    photo: PropTypes.object,
    children: PropTypes.object,
    submit: PropTypes.func,
    error: PropTypes.func,
    handleFileInputChange: PropTypes.func,
    onchange: PropTypes.func,
    handleCheck: PropTypes.func
}
