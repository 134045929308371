// eslint-disable-next-line

import React from 'react'
import SimpleTable from '../../../shared/SimpleTable/SimpleTable'
import SimplePopUp from '../../../shared/SimplePopUp/SimplePopUp'
import SimpleDeletePopUp from '../../../shared/SimpleDeletePopUp/SimpleDeletePopUp'
import AccountBoxOutlinedIcon from '@material-ui/icons/AccountBoxOutlined';
import axios from 'axios'
import ProgressBar from "../../../shared/ProgressBar/ProgressBar";
import PropTypes from "prop-types";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import { Button, TextField } from "@material-ui/core";
import styles from "../PayrollList.module.css";
import { Redirect } from "react-router-dom";

class PayrollAdjustment extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      model: 'Payroll Adjustment',
      payrollAdjustments: [],
      payrollAccounts: [],
      updateAccount: {},
      openPopup: false,
      openAdjustment: false,
      openDeletePopup: false,
      payrollList: {},
      redirect: false,
      payrollId: '',
      title: '',
      load: false,
      tableHead: ['account_name', 'amount'],
      withShow: true,
      withEdit: true,
      maxWidth: 'sm',
      error_messages: '',
      notify: {},
      isOpen: false,
      message: '',
      type: '',
      withShowPopup: false,
      groupedAdjustments: [],
      dialogItems: [],
      payrollAdjustment: {},
      openAdjustmentDeletePopUp: false
    }

    this.handleAdjusment = this.handleAdjusment.bind(this)
    this.handleAutocomplete = this.handleAutocomplete.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.redirectTo = this.redirectTo.bind(this)
    this.handleShowPopUp = this.handleShowPopUp.bind(this)
    this.handleDeleteAdjustment = this.handleDeleteAdjustment.bind(this)
    this.handleOpenDeleteAdustmentPopup = this.handleOpenDeleteAdustmentPopup.bind(this)
    this.handleCloseDeletePopUp = this.handleCloseDeletePopUp.bind(this)
  }

  componentDidMount() {
    this.loadPayrollAdjustments('/v1/payrolls/' + this.props.match.params.id + '/payroll_adjustments')
    if (this.props.location.state) {
      if (this.props.location.state.event && this.props.location.state.event === 'show') {
        this.setState({ withShowPopup: true })
      }
    }
  }

  loadPayrollAdjustments(url) {
    axios({
      method: 'get',
      url: url,
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {
      var arr = resp.data
      console.log(arr)
      var result = [];
      let groupByArray = arr.reduce((r, a) => {
        r[a.account_id] = [...r[a.account_id] || [], a];
        return r;
      }, {});
      this.setState({groupedAdjustments: groupByArray})
      arr.forEach(function (a) {
        if (!this[a.account_name]) {
          this[a.account_name] = { account_name: a.account_name, amount: 0, account_id: a.account_id };
          result.push(this[a.account_name]);
        }
        this[a.account_name].amount += parseFloat(a.amount);
        this[a.account_name].account_id
      }, Object.create(null));
      this.setState({ payrollAdjustments: result.sort(function (a, b) {
        if (a.account_name.toLowerCase() < b.account_name.toLowerCase()) return -1;
        if (a.account_name.toLowerCase() > b.account_name.toLowerCase()) return 1;
        return 0;
      }) })
      this.setState({ load: true })
    }).catch(error => console.log(error.response))
  }

  handleAdjusment() {
    axios({
      method: 'get',
      url: '/v1/payroll_accounts',
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {
      this.setState({ payrollAccounts: resp.data })
    }).catch(error => console.log(error.response))
    this.setState({ openAdjustment: true })
  }

  handleAutocomplete(event, values) {
    if (values !== null) {
      this.setState({ updateAccount: values })
    }
  }
  handleClose() {
    this.setState({ openAdjustment: false, openPopup: false })
  }

  handleCloseDeletePopUp(){
    this.setState({ openAdjustmentDeletePopUp: false  })
  }
  redirectTo() {
    this.setState({ redirect: true })
  }

  handleShowPopUp(item){
    this.setState({ title: item.account_name})
    this.setState({ dialogItems: this.state.groupedAdjustments[item.account_id]})
    this.setState({ openPopup: true })
  }

  handleOpenDeleteAdustmentPopup(item){
    this.setState({ payrollAdjustment: item, openAdjustmentDeletePopUp: true })
  }

  handleDeleteAdjustment(){
    const item = this.state.payrollAdjustment
    axios({
      method: 'delete',
      url: '/v1/payroll_adjustments/' + item.id,
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(() => {
      this.setState({ openAdjustmentDeletePopUp: false, openPopup: false, openAdjustment: false,  })
      this.loadPayrollAdjustments('/v1/payrolls/' + this.props.match.params.id + '/payroll_adjustments')
    }).catch(error => console.log(error.response))
  }

  render() {
    if (this.state.redirect) {
      return (<Redirect to={{
        pathname: `/payrolls/${this.props.match.params.id}/payroll_adjustments/new?payroll_account=${this.state.updateAccount.id}`,
        state: {
          item: this.state.updateAccount,
        }
      }} />)
    }
    return (
      <>
        {
          this.state.load ? (
            <div>
              {
                this.state.withShowPopup ?
                  <SimpleTable
                    handleClick={this.handleAdjusment}
                    items={this.state.payrollAdjustments}
                    model={'Payroll_Adjustment'}
                    withAdd={false}
                    headers={this.state.tableHead}
                    withShowPopUp={this.state.withShowPopup}
                    withEdit={this.state.withEdit}
                    linkable={true}
                    icon={<AccountBoxOutlinedIcon fontSize="large" />}
                    backToIndex={this.props.match.params.id}
                    handleShowPopUp={this.handleShowPopUp}
                    backIndex={true}
                  />
              :
                  <SimpleTable
                    handleClick={this.handleAdjusment}
                    items={this.state.payrollAdjustments}
                    model={this.state.model}
                    onOpenDeletePopup={this.handleOpenDeletePopup}
                    headers={this.state.tableHead}
                    withShowPopUp={this.state.withShow}
                    handleShowPopUp={this.handleShowPopUp}
                    withEdit={this.state.withEdit}
                    linkable={true}
                    icon={<AccountBoxOutlinedIcon fontSize="large" />}
                    backToIndex={this.props.match.params.id}
                    backIndex={true}
                  />

              }
              <SimplePopUp
                openPopup={this.state.openAdjustment}
                title='Payroll Adjustments'
                handleClose={this.handleClose}
                maxWidth={this.state.maxWidth}
              >
                <Autocomplete
                  value={this.state.updateAccount}
                  id="account_id"
                  size="small"
                  fullWidth
                  name="account_id"
                  options={this.state.payrollAccounts.sort(function (a, b) {
                    if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
                    if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
                    return 0;
                  })}
                  getOptionLabel={(option) => option.name || ''}
                  getOptionSelected={(option, value) => option.name === value.name}
                  onChange={this.handleAutocomplete}
                  renderInput={(params) => (
                    <TextField {...params} name="account_id" variant="outlined" label="Account Name" required />
                  )}
                />

                <div className={styles.actionButton}>
                  <Button disabled={!this.state.updateAccount.id} variant="outlined" id="payrollAccount-submit" color="primary" onClick={this.redirectTo}>Add</Button>
                </div>
              </SimplePopUp>

              <SimplePopUp
                openPopup={this.state.openPopup}
                title={this.state.title}
                handleClose={this.handleClose}
                maxWidth={'sm'}
              >
                <SimpleTable
                  items={this.state.dialogItems}
                  model={'Payroll_Adjustment'}
                  headers={['employee.name_formal','department','amount']}
                  onOpenDeletePopup={this.handleOpenDeleteAdustmentPopup}
                  noActionHeader={true} />
              </SimplePopUp>

              <SimpleDeletePopUp
                openDeletePopup={this.state.openDeletePopup}
                item={this.state.payrollList}
                delete={this.handleDeleteItem}
                handleDeleteClose={this.handleClose}
                model={this.state.model}
              />
              <SimpleDeletePopUp
                openDeletePopup={this.state.openAdjustmentDeletePopUp}
                item={this.state.payrollAdjustment}
                delete={this.handleDeleteAdjustment}
                handleDeleteClose={this.handleCloseDeletePopUp}
                model={this.state.model}
              />

            </div>
          ) : (
            <ProgressBar model={this.state.model} />
          )}
      </>
    )
  }
}

export default PayrollAdjustment

PayrollAdjustment.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.object.isRequired
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired
    })
  }),
  item: PropTypes.object,
  state: PropTypes.object
}
