import React from 'react'
import styles from './Employees.module.css'

import PropTypes from 'prop-types'

import {
  TextField,
  Button,
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'

class ApprovingOfficerForm extends React.Component {
  render() {
    return (
      <div>
        <form>
          {
            this.props.employees.length > 0 ?
              <Autocomplete
                value={this.props.selectedEmployees}
                id="employees"
                multiple
                name="employees"
                fullWidth
                size="small"
                options={this.props.employees}
                getOptionLabel={(option) => option.name || ''}
                getOptionSelected={(option, value) => option.id === value.id}
                onChange={this.props.handleApprovingOfficers}
                renderInput={(params) => (
                  <TextField {...params} name="employees" variant="outlined" label="Employees" />
                )}
              />
              : null
          }
        </form>
        <div className={styles.actionButton}>
          <Button variant="outlined" id="ApprovingOfficer-submit" color="primary" onClick={() => this.props.submit()} >Save</Button>
        </div>
      </div>
    )
  }
}
export default ApprovingOfficerForm

ApprovingOfficerForm.propTypes = {
  item: PropTypes.object,
  submit: PropTypes.func,
  selectedEmployees: PropTypes.array,
  employees: PropTypes.array,
  handleApprovingOfficers: PropTypes.func
}
