import React from 'react'

import PropTypes from 'prop-types'

import styles from './SimplePopUp.module.css'

import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined'
import ReactToPrint from "react-to-print";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
  Tooltip
} from '@material-ui/core'

class SimplePopUp extends React.Component {
  render() {
    const printPdf = <ReactToPrint
      trigger={() => {
        return <Tooltip title="Print PDF" arrow ><IconButton size="small" className={styles.print}><CloudDownloadOutlinedIcon /></IconButton></Tooltip>
      }}
      onAfterPrint={this.props.print}
      onBeforeGetContent={this.props.print}
      content={() => this.componentRef}
    />
    return (
      <Dialog open={this.props.openPopup} fullWidth={true} maxWidth={this.props.maxWidth} aria-labelledby={this.props.title ? (`${this.props.title.toLowerCase().replace(/ /g, '_')}_dialog`) : ("dialog")}>
        <DialogTitle className={styles.header} >
          <div style={{ display: 'flex' }}>
            <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
              <b>{this.props.title ? (this.props.title.replace(/ /g, ' ')) : (null)}</b>
            </Typography>
            <div style={{ display: 'flex',alignItems: 'center' }}>
              { this.props.withPrint? printPdf: null}
              <IconButton
                className={styles.secondary}
                onClick={() => this.props.handleClose()}
                id={this.props.title ? (`${this.props.title.toLowerCase().replace(/ /g, '_')}_close`) : ("close")}
                name={this.props.title ? (`${this.props.title.toLowerCase().replace(/ /g, '_')}_close`) : ("close")}
              >
                x
              </IconButton>
            </div>
          </div>
        </DialogTitle>
        <DialogContent dividers ref={el => (this.componentRef = el)}>
          {this.props.children}
        </DialogContent>
      </Dialog>
    )
  }
}

export default SimplePopUp;

SimplePopUp.propTypes = {
  classes: PropTypes.object,
  openPopup: PropTypes.bool.isRequired,
  title: PropTypes.string,
  handleClose: PropTypes.func,
  onChange: PropTypes.func,
  item: PropTypes.object,
  submit: PropTypes.func,
  children: PropTypes.object,
  maxWidth: PropTypes.string,
  withPrint: PropTypes.bool,
  print: PropTypes.func
}
