// eslint-disable-next-line
import React from 'react'

import Cookies from 'js-cookie';

import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom'
import axios from 'axios'
import style from './App.module.css'
import CssBaseline from '@material-ui/core/CssBaseline'
import Grid from '@material-ui/core/Grid'
import LinearProgress from '@material-ui/core/LinearProgress'
import HomeIcon from '@material-ui/icons/Home'
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined'
import PermContactCalendarOutlinedIcon from '@material-ui/icons/PermContactCalendarOutlined';
import SupervisedUserCircleOutlinedIcon from '@material-ui/icons/SupervisedUserCircleOutlined';
import BorderColorOutlinedIcon from '@material-ui/icons/BorderColorOutlined';
import FeedbackOutlinedIcon from '@material-ui/icons/FeedbackOutlined';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import EventNoteOutlinedIcon from '@material-ui/icons/EventNoteOutlined';
import EventAvailableOutlinedIcon from '@material-ui/icons/EventAvailableOutlined';
import EventBusyOutlinedIcon from '@material-ui/icons/EventBusyOutlined';
import PhotoLibraryOutlinedIcon from '@material-ui/icons/PhotoLibraryOutlined';
import AutorenewOutlinedIcon from '@material-ui/icons/AutorenewOutlined';
import SupervisorAccountOutlinedIcon from '@material-ui/icons/SupervisorAccountOutlined';
import ListAltOutlinedIcon from '@material-ui/icons/ListAltOutlined';
import CreditCardOutlinedIcon from '@material-ui/icons/CreditCardOutlined';
import AccountBoxOutlinedIcon from '@material-ui/icons/AccountBoxOutlined';
import TableChartOutlinedIcon from '@material-ui/icons/TableChartOutlined';
import HomeWorkOutlinedIcon from '@material-ui/icons/HomeWorkOutlined';
import DnsOutlinedIcon from '@material-ui/icons/DnsOutlined';
import RecentActorsOutlinedIcon from '@material-ui/icons/RecentActorsOutlined';
import InsertInvitationIcon from '@material-ui/icons/InsertInvitation'
import PermMediaOutlinedIcon from '@material-ui/icons/PermMediaOutlined';
import SettingsInputCompositeOutlinedIcon from '@material-ui/icons/SettingsInputCompositeOutlined';
import AccountBalanceOutlinedIcon from '@material-ui/icons/AccountBalanceOutlined';
import AssignmentIcon from '@material-ui/icons/Assignment';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';

import Positions from "./pages/Positions/Positions";
import Departments from "./pages/Departments/Departments";
import Divisions from "./pages/Divisions/Divisions";
import PayrollAccounts from "./pages/PayrollAccounts/PayrollAccounts";
import SideNavMenu from "./shared/SideNav/SideNavMenu";
import Appbar from './shared/Appbar/Appbar'

import Memos from '../components/pages/Memos/Memos'
import Announcements from './pages/Announcements/Announcements'
import Recurrences from './pages/Recurrences/Recurrences'
import PhotoGalleries from './pages/PhotoGallery/PhotoGallery'
import Employees from './pages/Employees/Employees'
import EmployeeNewAndUpdate from './pages/Employees/EmployeeNewAndUpdate'
import LeaveApplications from './pages/LeaveManagement/LeaveApplication/LeaveApplications'
import LeaveRecords from "./pages/LeaveRecord/LeaveRecords";
import EmployeeShow from './pages/Employees/EmployeeShow'
import TaxTables from './pages/TaxTables/TaxTables'
import PayrollList from "./pages/PayrollList/PayrollList";
import PayrollListShow from './pages/PayrollList/PayrollListShow'
import PayrollAdjustmentShow from './pages/PayrollList/PayrollAdjustment/PayrollAdjustmentShow'
import PayrollAdjustment from './pages/PayrollList/PayrollAdjustment/PayrollAdjustment'
import LeaveTypes from "./pages/LeaveTypes/LeaveTypes";
import AccountLink from './shared/ModuleLinks/AccountLink'
import LoanReports from './pages/LoanReports/LoanReports'
import SignIn from './shared/Sessions/SignIn'
import Directories from './pages/Directories/Directories'
import Branches from "./pages/Branches/Branches";
import Permissions from "./pages/Permissions/Permissions"
import PermissionNewUpdate from "./pages/Permissions/PermissionNewUpdate"
import Banks from "./pages/Banks/Banks"

import { setupAxios } from '../helpers/axios';

import { removeAllCookies } from '../helpers/cookies';

import LeaveCredits from './pages/LeaveCredits/LeaveCredits';
import Home from './pages/Home/Home'
import SimplePopUp from './shared/SimplePopUp/SimplePopUp';
import ChangePasswordForm from './shared/Appbar/ChangePasswordForm';
import Attendances from './pages/Attendances/Attendances';
import ForgotPassword from "../components/shared/Sessions/ForgotPassword";
import UnlockInstruction from './shared/Sessions/UnlockInstruction';
import ResetPassword from './shared/Sessions/ResetPassword';
import Accounts from './pages/Accounts/Accounts'
import SettingsSystemDaydreamOutlinedIcon from '@material-ui/icons/SettingsSystemDaydreamOutlined';
import SubjectIcon from '@material-ui/icons/Subject';
import Annualization from './pages/Annualization/Annualization';
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import GeneralLedgers from './pages/Report/GeneralLedgers';

class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      promises: [],
      load: false,
      open: true,
      linkOpen: false,
      token: '',
      CurrentUser: '',
      currentUser: {},
      employee: {},
      signIn: false,
      apps: [],
      anchorEl: null,
      openChangePassword: false,
      singleMenu: [
        { label: 'Home', icon: <HomeIcon />, link: '/' },
        { label: 'Directory', icon: <PermMediaOutlinedIcon />, link: '/directory' },
        { label: 'Report', icon: <AssessmentOutlinedIcon />, link: '/report' },
      ],
      employeeMenu: [
        { label: 'Leave Applications', icon: <InsertInvitationIcon />, link: '/leave_applications' },
        { label: 'Attendance', icon: <EventAvailableIcon />, link: '/attendances' }
      ],
      subMenus: [
        {
          parentLabel: 'Human Resources', parentIcon: <SupervisedUserCircleOutlinedIcon />,
          subLabel: [
            { label: 'Employee Profiles', childIcon: <PermContactCalendarOutlinedIcon />, link: '/employees' },
            { label: 'Incident Reports', childIcon: <BorderColorOutlinedIcon />, link: '/incident_reports' },
            { label: 'Announcement', childIcon: <FeedbackOutlinedIcon />, link: '/announcements' },
            {
              childParentLabel: 'Leave Management', parentIcon: <EventNoteOutlinedIcon />, class: "childParentLabel",
              childLabel: [
                { label: 'Leave Records', childIcon: <ExitToAppOutlinedIcon />, link: '/leave_records' },
                { label: 'Manage Leave Type', childIcon: <EventAvailableOutlinedIcon />, link: '/manage_leave_types' },
                { label: 'Manage Leave Credit', childIcon: <EventBusyOutlinedIcon />, link: '/manage_leave_credits' },
              ]
            },
            { label: 'Photo Gallery', childIcon: <PhotoLibraryOutlinedIcon />, link: '/photo_galleries' },
            { label: 'Loans and Benefits', childIcon: <AutorenewOutlinedIcon />, link: '/loan_and_benefits' },

          ],
        },

        {
          parentLabel: 'Payroll', parentIcon: <SupervisorAccountOutlinedIcon />,
          subLabel: [
            { label: 'Payroll List', childIcon: <ListAltOutlinedIcon />, link: '/payrolls' },
            { label: 'Loan Reports', childIcon: <CreditCardOutlinedIcon />, link: '/loan_reports' },
            { label: `Annualization`, childIcon: <SubjectIcon />, link: '/annualization' },
          ]
        },
        {
          parentLabel: 'Payroll Settings', parentIcon: <SettingsOutlinedIcon />,
          subLabel: [
            { label: 'Attendance', childIcon: <EventAvailableIcon />, link: '/attendances' },
            { label: 'Account Setup', childIcon: <SettingsSystemDaydreamOutlinedIcon />, link: '/accounts' },
            { label: 'Payroll Account', childIcon: <AccountBoxOutlinedIcon />, link: '/payroll_accounts' },
            { label: 'Tax Table', childIcon: <TableChartOutlinedIcon />, link: '/tax_tables' },
            { label: 'Department', childIcon: <HomeWorkOutlinedIcon />, link: '/departments' },
            { label: 'Division', childIcon: <DnsOutlinedIcon />, link: '/divisions' },
            { label: 'Position', childIcon: <RecentActorsOutlinedIcon />, link: '/positions' },
            { label: 'Branches', childIcon: <SettingsInputCompositeOutlinedIcon />, link: '/branches' },
            { label: 'Banks', childIcon: <AccountBalanceOutlinedIcon />, link: '/banks' },
            { label: 'Permissions', childIcon: <AssignmentIcon />, link: '/permissions' },
          ]
        }
      ],

    }
    this.toggleDrawer = this.toggleDrawer.bind(this)
    this.toggleLink = this.toggleLink.bind(this)
    this.logOut = this.logOut.bind(this)
    this.setToken = this.setToken.bind(this)
    this.setLoad = this.setLoad.bind(this)
    this.loadAttribute = this.loadAttribute.bind(this)
    this.handleRefreshStorage = this.handleRefreshStorage.bind(this)
    this.toggleDropdown = this.toggleDropdown.bind(this)
    this.handleChangePassword = this.handleChangePassword.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleClosePopup = this.handleClosePopup.bind(this)
  }

  componentDidMount() {
    var token = Cookies.get('token')

    if (token) {
      localStorage.setItem('api_key', token)
    } else {
      this.logOut()
    }

    if (localStorage.getItem("api_key") !== null) {
      this.loadAttribute()
      this.setState({ load: true })
    } else {
      this.setState({ load: false })
      this.setState({ signIn: true })
    }
  }

  clearSession() {
    // Clear local storage
    localStorage.removeItem('api_key')
    localStorage.removeItem('username')
    localStorage.removeItem('password')
    localStorage.removeItem('cashvoucher_prefix')
    localStorage.removeItem('checkvoucher_prefix')
    localStorage.removeItem('user_apps')
    localStorage.removeItem('employee')
    localStorage.removeItem('employee_resources')
    localStorage.removeItem('approvingOfficers')
    localStorage.removeItem('payroll_accounts')
    localStorage.removeItem('branches')
    localStorage.clear()
    removeAllCookies()
  }

  logOut() {
    this.setState({ load: false })
    this.clearSession()
  }

  setToken(token) {
    this.setState({ token: token })
  }

  setLoad(load) {
    this.setState({ load: load })
  }

  toggleDrawer() {
    this.setState({ open: !this.state.open })
    localStorage.setItem('openSideNav', this.state.open)
  }

  toggleLink() {
    this.setState({ linkOpen: !this.state.linkOpen })
  }

  loadApps() {
    axios({
      method: 'get',
      url: '/v1/apps',
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {
      if (resp.data.length > 0) {
        var apps = []
        axios({
          method: 'get',
          url: '/v1/user',
          headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
        }).then(res => {

          if (res.data.app_ids.length > 0) {
            apps = resp.data.filter(({ id }) => res.data.app_ids.includes(id))
            this.setState({ apps: apps })
            localStorage.setItem('user_apps', JSON.stringify(apps))
          }
        })
      }
    })
  }

  loadEmployee() {
    axios({
      method: 'get',
      url: '/v1/employees/profiles',
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {
      this.setState({ employee: resp.data })
      localStorage.setItem('employee', JSON.stringify(resp.data))
    })
  }

  loadAttribute() {
    let username = Cookies.get("current_user") ? JSON.parse(Cookies.get("current_user")).username : localStorage.getItem('username')
    setupAxios(username)
    if (Cookies.get('current_user')) {
      if (localStorage.getItem('employee')) {
        if (JSON.parse(localStorage.getItem('employee')).employee_id === JSON.parse(Cookies.get('current_user')).id) {
          this.setState({ employee: JSON.parse(localStorage.getItem('employee')) })
        } else {
          if (JSON.parse(Cookies.get('current_user')).is_employee) { this.loadEmployee() }
        }
      } else {
        if (JSON.parse(Cookies.get('current_user')).is_employee) { this.loadEmployee() }
      }
      this.setState({ currentUser: JSON.parse(Cookies.get('current_user')) })
      if (JSON.parse(Cookies.get('current_user')).is_employee) {
        localStorage.removeItem('user_apps')
      } else {
        if (localStorage.getItem('user_apps')) {
          this.setState({ apps: JSON.parse(localStorage.getItem('user_apps')) })
        } else {
          this.loadApps()
        }
      }
    }
  }

  async handleRefreshStorage() {
    const adminRoutes = ['divisions', 'branches', 'positions', 'departments', 'payroll_accounts']
    const employeeRoutes = ['leave_applications', 'leave_credits', 'approving_officers', 'leave_applications/received']
    var responses = []
    if (this.state.currentUser.is_employee) {
      for (const route of employeeRoutes) {
        const result = await axios.get(`/v1/employees/${route}`, { headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') } })
        responses.push(result);
      }
      responses.forEach((response, index) => {
        var url = response.config.url ? response.config.url.split('/')[3] : ''
        switch (url) {
          case 'leave_applications':
            if (response.config.url.split('/')[4] === 'received') {
              localStorage.setItem('received_leave_applications', JSON.stringify(response.data))
            } else {
              localStorage.setItem('employee_leave_applications', JSON.stringify(response.data))
            }
            break;
          case 'leave_credits':
            localStorage.setItem('employee_leave_credits', JSON.stringify(response.data))
            break;
          case 'approving_officers':
            localStorage.setItem('employee_officers', JSON.stringify(response.data))
            break
          default:
            break;
        }

        if (index === responses.length - 1) {
          location.reload()
        }
      })
    } else {

      for (const element of adminRoutes) {
        const result = await axios.get(`/v1/${element}`, { headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') } })
        responses.push(result);
      }
      responses.forEach((response, index) => {
        var storageName = response.config.url ? response.config.url.split('/')[2] : ''
        localStorage.setItem(storageName, JSON.stringify(response.data))
        if (index === responses.length - 1) {
          location.reload()
        }
      })
    }

  }

  toggleDropdown(event) {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleClose() {
    this.setState({ anchorEl: null })
  }

  handleChangePassword() {
    this.setState({ anchorEl: null })
    this.setState({ openChangePassword: true })
  }

  handleClosePopup() {
    this.setState({ openChangePassword: false })
  }


  render() {
    if(!Cookies.get('token')) {
      if  (location.pathname === '/login' || location.pathname === '/') {
        return <SignIn setToken={this.setToken} setLoad={this.setLoad} loadAttribute={this.loadAttribute} />
      }else if (location.pathname === '/forgot_password') {
        return <ForgotPassword />
      }
      else if (location.pathname === '/unlock_account') {
        return <UnlockInstruction />
      }
      else if (location.pathname === '/reset_password') {
        return <ResetPassword />
      }
      else{
        return location.href='/'
      }
    }
    const employeeRoutes = [
      { route: '/directory', component: Directories },
      { route: '/leave_applications', component: LeaveApplications },
      { route: '/attendances', component: Attendances },
      { route: '/report', component: GeneralLedgers },
    ]

    const adminRoutes = [
      { route: '/attendances', component: Attendances },
      { route: '/employees', component: Employees },
      { route: '/directory', component: Directories },
      { route: '/report', component: GeneralLedgers },
      { route: '/employee/new', component: EmployeeNewAndUpdate },
      { route: '/employees/:id/edit', component: EmployeeNewAndUpdate },
      { route: '/employees/:id', component: EmployeeShow },
      { route: '/photo_galleries', component: PhotoGalleries },
      { route: '/divisions', component: Divisions },
      { route: '/branches', component: Branches },
      { route: '/banks', component: Banks },
      { route: '/positions', component: Positions },
      { route: '/departments', component: Departments },
      { route: '/payroll_accounts', component: PayrollAccounts },
      { route: '/accounts', component: Accounts },
      { route: '/tax_tables', component: TaxTables },
      { route: '/loan_and_benefits', component: Recurrences },
      { route: '/leave_records', component: LeaveRecords },
      { route: '/manage_leave_credits', component: LeaveCredits },
      { route: '/manage_leave_types', component: LeaveTypes },
      { route: '/payrolls', component: PayrollList },
      { route: '/payrolls/:id/payroll_details', component: PayrollListShow },
      { route: '/payrolls/:id/payroll_adjustments/new', component: PayrollAdjustment },
      { route: '/payrolls/:id/payroll_adjustments/new?payroll_account=:uid', component: PayrollAdjustmentShow },
      { route: '/payrolls/:id/payroll_adjustments/edit/:accId', component: PayrollAdjustmentShow },
      { route: '/loan_reports', component: LoanReports },
      { route: '/annualization', component: Annualization },
      { route: '/permissions', component: Permissions },
      { route: '/permissions/:permission_id', component: PermissionNewUpdate },
    ]

    return (
      <>
        {
          this.state.load ? (
            <Router>
              <div className={style.root}>
                <SimplePopUp
                  openPopup={this.state.openChangePassword}
                  title="Change Password"
                  handleClose={this.handleClosePopup}
                  maxWidth={this.state.maxWidth}
                >
                  <ChangePasswordForm />
                </SimplePopUp>
                <CssBaseline />
                <Appbar
                  handleChangePassword={this.handleChangePassword}
                  handleClose={this.handleClose}
                  toggleDropdown={this.toggleDropdown}
                  anchorEl={this.state.anchorEl}
                  toggleDrawer={this.toggleDrawer}
                  open={this.state.open}
                  currentUser={this.state.currentUser}
                  logOut={this.logOut}
                  handleRefreshStorage={this.handleRefreshStorage}
                  title={<React.Fragment>
                    <div className={style.linkList}>
                      <div>
                        {process.env.REACT_APP_TITLE}
                      </div>
                      {
                        this.state.currentUser.is_employee ? null :
                          <AccountLink
                            toggleLink={this.toggleLink}
                            linkOpen={this.state.linkOpen}
                            apps={this.state.apps}
                          />
                      }
                    </div>
                  </React.Fragment>
                  }
                />
                <SideNavMenu
                  withSubMenus={this.state.subMenus}
                  menus={this.state.singleMenu}
                  openDrawer={this.state.open}
                  toggleDrawer={this.toggleDrawer}
                  currentUser={this.state.currentUser}
                  employeeMenu={this.state.employeeMenu}
                />

                <div className={this.state.open ? style.openBody : style.closeBody}>
                  <Grid container style={{ flexWrap: 'inherit' }}>
                    <Grid item xs={12}>
                      <main className={style.content}>
                        <Switch>
                          <Route exact path="/">
                            {
                              this.state.employee.id && this.state.currentUser.is_employee ?
                                <Home employeeId={this.state.employee.id} /> : null
                            }
                          </Route>

                          {
                            this.state.currentUser.is_employee ?
                              employeeRoutes.map((route, index) => (
                                <Route key={index} exact path={route.route} component={route.component} />
                              )) : (
                                <>
                                  {adminRoutes.map((route, index) => (
                                    <Route key={index} exact path={route.route} component={route.component} />
                                  ))}
                                  <Route exact path="/incident_reports">
                                    <Memos currentUser={this.state.employee} />
                                  </Route>
                                  <Route exact path="/announcements">
                                    <Announcements currentUser={this.state.employee} />
                                  </Route>
                                </>

                              )


                          }
                          <Route path="*">
                            Route Not Found
                          </Route>
                        </Switch>
                      </main>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </Router>
          ) : (<LinearProgress className={style.appLoading} />)
        }
      </>
    )
  }
}

export default App
