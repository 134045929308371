// eslint-disable-next-line
import React from 'react'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import DeleteIcon from '@material-ui/icons/Delete'
import IconButton from '@material-ui/core/IconButton'
import { Tooltip } from '@material-ui/core'
import styles from "./Announcements.module.css";
import moment from "moment";


import EditIcon from "@material-ui/icons/Edit";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import VisibilityIcon from '@material-ui/icons/Visibility'
import { Pagination } from '@material-ui/lab'

class AnnouncementTable extends React.Component {
    handleChildClick(e) {
        e.stopPropagation();
    }
    render() {
        return (
            <div className="ParentBody">
                <div className={styles.tableTitle}>
                    <div className={styles.tileBody}>
                        {this.props.icon}
                        <Typography variant="h4" className={styles.title}>
                            {this.props.model ? (this.props.model + 's') : null}
                        </Typography>
                    </div>
                    <div className={styles.rightAction}>
                        {
                            this.props.withPagination ? (
                                this.props.totalPages !== 1 ? (
                                    <Pagination
                                        className={styles.paginationWidth}
                                        size="small"
                                        count={this.props.totalPages}
                                        page={this.props.currentPage}
                                        color="primary"
                                        onChange={this.props.handlePageChange}
                                        showFirstButton
                                        showLastButton
                                    />
                                ) : null
                            ) : null
                        }
                        {
                            this.props.withOutActions ? null :
                                <IconButton color="inherit"
                                    id={`${this.props.model.toLowerCase().replace(/ /g, '_')}_add`}
                                    name={`${this.props.model.toLowerCase().replace(/ /g, '_')}_add`}
                                    onClick={() => this.props.handleClick(this.props.initialItem, true, this.props.model)}>
                                    <AddCircleOutlineIcon fontSize="large" />
                                </IconButton>
                        }
                    </div>
                </div>
                <div className={styles.nameHeader}>
                    <div>Title</div>
                    <div ></div>
                </div>
                <div>
                    {this.props.items.length > 0 ? (
                        this.props.items.sort(
                            (a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
                        ).reverse().map((item, index) => (
                            <div key={index} className={styles.parent}>
                                <div className={styles.tile}>
                                    {
                                        item.employee_names !== undefined ? (
                                            <div>
                                                {
                                                    item.employee_names.map((name, idx) => (
                                                        idx > 0 ? (
                                                            ' - ' + name
                                                        ) : name
                                                    ))
                                                }
                                            </div>
                                        ) : null
                                    }
                                    <b>{item.title}</b>
                                    <div className={styles.subs}>
                                        {item.officer_name ? (item.officer_name) : null}{item.employee ? (item.employee.name) : null} -{moment(item.created_at).format('L')}
                                    </div>
                                </div>
                                {
                                    this.props.withOutActions ? null : (
                                        <div className={styles.buttonoverlapmulti}>
                                            <Tooltip title="Show" arrow>
                                                <IconButton
                                                    size="small"
                                                    color="primary"
                                                    onClick={() => this.props.handleShowPopup(item)}>
                                                    <VisibilityIcon color="primary" fontSize="small" />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Edit" arrow>
                                            <IconButton
                                                size="small"
                                                color="primary"
                                                onClick={() => this.props.handleClick(item, false, this.props.model)}
                                            >
                                                <EditIcon />
                                            </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Delete" arrow>
                                            <IconButton
                                                size="small"
                                                color="secondary"
                                                onClick={() => this.props.onOpenDeletePopup(item, this.props.model)}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                            </Tooltip>
                                        </div>
                                    )
                                }
                            </div>
                        ))) : (null)}
                </div>
            </div >
        )
    }
}

export default AnnouncementTable

AnnouncementTable.propTypes = {
    icon: PropTypes.object,
    items: PropTypes.array,
    model: PropTypes.string,
    initialItem: PropTypes.object,
    handleClick: PropTypes.func,
    onOpenDeletePopup: PropTypes.func,
    rowClick: PropTypes.func,
    handlePageChange: PropTypes.func,
    withPagination: PropTypes.bool,
    totalPages: PropTypes.number,
    currentPage: PropTypes.number,
    withOutActions: PropTypes.bool,
    handleShowPopup: PropTypes.func
}
