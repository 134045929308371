// eslint-disable-next-line


import React from 'react'

import SimpleTable from '../../shared/SimpleTable/SimpleTable'
import SimplePopUp from '../../shared/SimplePopUp/SimplePopUp'
import SimpleDeletePopUp from '../../shared/SimpleDeletePopUp/SimpleDeletePopUp'
import PayrollAccountsForm from './PayrollAccountsForm'
import AccountBoxOutlinedIcon from '@material-ui/icons/AccountBoxOutlined';
import axios from 'axios'
import AlertMessage from "../../shared/Notify/AlertMessage";
import ProgressBar from "../../shared/ProgressBar/ProgressBar";

class PayrollAccounts extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      model: 'Payroll Account',
      payrollAccounts: [],
      accounts: [],
      registryTypes: [],
      updateAccount: {},
      openPopup: false,
      openDeletePopup: false,
      payrollAccount: {},
      initialPayrollAccounts: {
        id: '',
        account_id: '',
        name: '',
        registry_type: '',
        deduction: false,
        taxable: false,
        rate: 0
      },
      title: '',
      load: false,
      tableHead: ['account.name', 'registry_type', 'Name', 'deduction', 'taxable', 'rate'],
      withShow: false,
      withEdit: true,
      maxWidth: 'sm',
      error_messages: '',
      notify: {},
      isOpen: false,
      message: '',
      type: ''
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleDeleteItem = this.handleDeleteItem.bind(this)
    this.handleCreateorUpdateItem = this.handleCreateorUpdateItem.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleOpenDeletePopup = this.handleOpenDeletePopup.bind(this)
    this.handleDeleteClose = this.handleDeleteClose.bind(this)
    this.handleCloseAlert = this.handleCloseAlert.bind(this)
    this.handleSwitch = this.handleSwitch.bind(this)
    this.handleAutocomplete = this.handleAutocomplete.bind(this)
  }

  componentDidMount() {
    this.loadpayrollAccounts('/v1/payroll_accounts')
  }

  loadpayrollAccounts(url) {
    axios({
      method: 'get',
      url: url,
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then(resp => {
      this.setState({payrollAccounts: resp.data})
      axios({
        method: 'get',
        url: '/v1/accounts',
        headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
      }).then(resp => {
        this.setState({accounts: resp.data})
        axios({
          method: 'get',
          url: '/v1/payroll_accounts/registry_types',
          headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
        }).then(resp => {
          this.setState({registryTypes: resp.data})
          this.setState({load: true})
        }).catch(error => console.log(error.response))
      }).catch(error => console.log(error.response))
    }).catch(error => console.log(error.response))
  }

  handleSubmit() {
    const item = this.state.payrollAccount
    var method = ''
    var url = ''
    if (item.id === '') {
      method = 'post'
      url = '/v1/payroll_accounts/'
    } else {
      method = 'put'
      url = '/v1/payroll_accounts/' + item.id
    }
    axios({
      method: method,
      url: url,
      data: (item),
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then(resp => {
      
      const newData = resp.data
      this.setState({
        payrollAccounts: this.state.payrollAccounts.filter(payrollAccount => payrollAccount.id !== item.id)
      })
      localStorage.setItem('payroll_accounts', JSON.stringify([...this.state.payrollAccounts, newData]))
      this.setState({payrollAccounts: [...this.state.payrollAccounts, newData]})
      this.setState({openPopup: false})
      this.setState({
        isOpen: true,
        message: 'Submitted Successfully',
        type: 'success'
      })
    }).catch(error => {
      if (error.response.status === 422) {
        this.setState({error_messages: 'Name ' + error.response.data.errors.name[0]})
      } else {
        this.setState({
          isOpen: true,
          message: error.response.status.toString() + " Unexpected Error Problem Occurred",
          type: 'error'
        })
        this.setState({openPopup: false})
      }
    })
  }

  handleDeleteItem() {
    const DeleteItemId = this.state.payrollAccount.id
    axios({
      method: 'delete',
      url: '/v1/payroll_accounts/' + DeleteItemId,
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then(() => {
      this.setState({
        payrollAccounts: this.state.payrollAccounts.filter(item => item.id !== DeleteItemId)
      })
      this.setState({openDeletePopup: false})
      this.setState({
        isOpen: true,
        message: 'Deleted Successfully',
        type: 'error'
      })
    }).catch(error => console.log(error.response))
  }

  handleClose() {
    this.setState({openPopup: false})
    this.setState({openDeletePopup: false})
    this.setState({error_messages: ''})
  }

  handleCreateorUpdateItem(item, isAdd, model) {
    var textTitle = ''
    if (isAdd) {
      textTitle = 'Create ' + model
    } else {
      textTitle = 'Edit ' + model
    }
    this.setState({payrollAccount: item, updateAccount: item.account, openPopup: true, title: textTitle })
  }

  handleInputChange(e) {
    this.setState({
      payrollAccount: {
        ...this.state.payrollAccount,
        [e.target.name]: e.target.value
      }
    })
  }

  handleOpenDeletePopup(item, model) {
    this.setState({model: model})
    this.setState({payrollAccount: item})
    this.setState({openDeletePopup: true})
  }

  handleDeleteClose() {
    this.setState({openDeletePopup: false})
  }

  handleCloseAlert(event, reason) {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({isOpen: false})
  }

  handleSwitch(event) {
    const value = event.target.checked
    this.setState({
      payrollAccount: {
        ...this.state.payrollAccount,
        [event.target.name]: value
      }
    })
  }

  handleAutocomplete(event, values) {
    if (values !== null) {
      this.setState({updateAccount: values})

      this.setState({
        payrollAccount: {
          ...this.state.payrollAccount,
          account_id: values.id
        }
      })
    }
  }

  render() {
    return (
      <>
        {
          this.state.load ? (
            <div>
              <SimpleTable
                handleClick={this.handleCreateorUpdateItem}
                initialItem={this.state.initialPayrollAccounts}
                items={this.state.payrollAccounts}
                model={this.state.model}
                onOpenDeletePopup={this.handleOpenDeletePopup}
                headers={this.state.tableHead}
                withShow={this.state.withShow}
                withEdit={this.state.withEdit}
                icon={<AccountBoxOutlinedIcon fontSize="large"/>}
              />

              <SimplePopUp
                openPopup={this.state.openPopup}
                title={this.state.title}
                handleClose={this.handleClose}
                maxWidth={this.state.maxWidth}
              >
                <PayrollAccountsForm
                  error={this.state.error_messages}
                  item={this.state.payrollAccount}
                  accounts={this.state.accounts}
                  updateAccount={this.state.updateAccount}
                  registryTypes={this.state.registryTypes}
                  handleSwitch={this.handleSwitch}
                  handleAutocomplete={this.handleAutocomplete}
                  submit={this.handleSubmit}
                  onchange={this.handleInputChange}/>
              </SimplePopUp>

              <SimpleDeletePopUp
                openDeletePopup={this.state.openDeletePopup}
                item={this.state.payrollAccount}
                delete={this.handleDeleteItem}
                handleDeleteClose={this.handleClose}
                model={this.state.model}
              />
              <AlertMessage
                notify={this.state.notify}
                handleCloseAlert={this.handleCloseAlert}
                isOpen={this.state.isOpen}
                type={this.state.type}
                message={this.state.message}
              />
            </div>
          ) : (
            <ProgressBar model={this.state.model}/>
          )}
      </>
    )
  }
}

export default PayrollAccounts
