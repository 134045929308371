
import axios from "axios";
import React from 'react'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import { Grid, Avatar, IconButton } from '@material-ui/core'
import styles from './Home.module.css'
import Cookies from 'js-cookie';
import moment from 'moment'
import SimpleTable from '../../shared/SimpleTable/SimpleTable'
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AlertMessage from '../../shared/Notify/AlertMessage'
import SimplePopUp from "../../shared/SimplePopUp/SimplePopUp";
import PayrollEmployeeInfo from "../PayrollList/PayrollEmployeeInfo";
function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  }
}

class Home extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      model: 'Payee',
      employee: {},
      approvingOfficers: [],
      attachments: [],
      payrollDetails: [],
      isCaretOpen: false,
      load: false,
      value: 0,
      payrollAdjustment: [],
      openPayrollDetails: false,
      payrollEmployee: {}
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleCloseAlert = this.handleCloseAlert.bind(this)
    this.handelCaret = this.handelCaret.bind(this)
    this.handleShowPopUpPayroll = this.handleShowPopUpPayroll.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.printEmployeePayroll = this.printEmployeePayroll.bind(this)
  }

  componentDidMount() {
    const currentUser = JSON.parse(Cookies.get("current_user"))
    if (!currentUser) return null
    
    if (currentUser.is_tenant && currentUser.is_employee)
      this.loadEmployee('/v1/employees/' + currentUser.employee_id)
    else if (currentUser.is_employee)
      this.loadEmployee('/v1/employees/' + currentUser.id)
  }


  addResourceToLocalStorage(resource, data) {
    var employeeResources = localStorage.getItem('employee_resources') ? JSON.parse(localStorage.getItem('employee_resources')) : {}
    employeeResources[resource] = data
    localStorage.setItem('employee_resources', JSON.stringify(employeeResources))
  }

  async loadEmployee(url) {
    await axios({
      method: 'get',
      url: url,
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {
      this.setState({ employee: resp.data, load: true })
    }).catch(error => {
      this.setState({ isOpen: true, message: error.response.status.toString() + " Unexpected Error Problem Occurred", type: 'error' })
    })

    await axios({
      method: 'get',
      url: '/v1/employees/approving_officers',
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {
      this.setState({ approvingOfficers: resp.data })
      localStorage.setItem('employee_officers', JSON.stringify(resp.data))
    }).catch(error => {
      this.setState({ isOpen: true, message: error.response.status.toString() + " Unexpected Error Problem Occurred", type: 'error' })
    })
  }

  loadAttachments() {
    axios({
      method: 'get',
      url: '/v1/employees/attachments',
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {
      this.setState({ attachments: resp.data })
      if (this.props.employeeId) {
        this.addResourceToLocalStorage('attachments', resp.data)
      }
    }).catch(error => {
      this.setState({ isOpen: true, message: error.response.status.toString() + " Unexpected Error Problem Occurred", type: 'error' })
    })
  }

  loadPayslips(url) {
    axios({
      method: 'get',
      url: url + '/payroll_details',
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {
      this.setState({ payrollDetails: resp.data })
      if (this.props.employeeId) {
        this.addResourceToLocalStorage('payrollDetails', resp.data)
      }
    }).catch(error => {
      this.setState({ isOpen: true, message: error.response.status.toString() + " Unexpected Error Problem Occurred", type: 'error' })
    })
  }


  handleChange(event, newValue) {
    if (newValue === 1) {
      this.loadAttachments()
    } else if (newValue === 2) {
      const currentUser = JSON.parse(Cookies.get("current_user"))
      if (!currentUser) return null
      const url = `/v1/employees/${currentUser.is_tenant && currentUser.is_employee ? currentUser.employee_id : currentUser.id}`

      this.loadPayslips(url)
    }
    this.setState({ value: newValue })
  }

  handelCaret() {
    this.setState({ isCaretOpen: !this.state.isCaretOpen })
  }
  handleCloseAlert(event, reason) {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ isOpen: false })
  }

  handleShowPopUpPayroll(item) {

    this.setState({ payrollEmployee: item })
    axios({
      method: 'get',
      url: '/v1/payroll_details/' + item.id + '/payroll_adjustments/',
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {
      this.setState({ payrollAdjustment: resp.data })
      this.setState({ openPayrollDetails: true })
    }).catch(error => {
      if (error.response.status === 422) {
        this.setState({ error_messages: 'Name ' + error.response.data.errors.name[0] })
      } else {
        this.setState({
          isOpen: true,
          message: error.response.status.toString() + " Unexpected Error Problem Occurred",
          type: 'error'
        })
        this.setState({ openPayrollDetails: false })
      }
    })
  }

  printEmployeePayroll() {
    return new Promise((resolve) => {
      this.setState({ printEmployeePayroll: !this.state.printEmployeePayroll}, () => resolve());
    });
  }

  handleClose() {
    this.setState({ openPayrollDetails: false })
  }

  render() {
    const profile = this.state.employee.profile_attributes
    const employmentStatus = this.state.employee !== undefined ? this.state.employee.employment_status : {}
    const emp = this.state.employee
    return (
      <>
        {this.state.load ?
          <div>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <div className={styles.headers}>
                  <div><b> Employee No </b></div>
                  <div>:&emsp; {profile.reference_no}</div>
                </div>
                <div className={styles.headers}>
                  <div><b> Employee Name </b></div>
                  <div>:&emsp; {profile.name}</div>
                </div>
              </Grid>
              <Grid item xs={4} />
              <Grid item xs={2}>
                <Avatar
                  className={styles.avatar}
                  variant="square"
                  src={this.state.employee.image ? this.state.employee.image.default : ''}
                  alt=""
                />
              </Grid>
            </Grid>
            <Tabs
              value={this.state.value}
              onChange={this.handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab label="Profile" {...a11yProps(0)} />
              <Tab label="201 Files" {...a11yProps(1)} />
              <Tab label="Payslips" {...a11yProps(2)} />
            </Tabs>

            <TabPanel className={styles.tabBodyEmployee} value={this.state.value} index={0}>
              <>
                <div className={styles.showTitleHeader}>
                  <div className={styles.detailList}>
                    <h3 style={{ margin: '0' }}><b> Personal Information </b></h3>
                    <IconButton onClick={this.handelCaret}>
                      {this.state.isCaretOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </IconButton>
                  </div>

                </div>
                <hr />
                {this.state.isCaretOpen ?
                  <div className={styles.detailsBody}>
                    <div className={styles.leftShowDetails}>
                      <div className={styles.detailList}>
                        <div><b> Date of birth </b></div>
                        <div>:&emsp; {moment(profile.birth_date).format('L')}</div>
                      </div>
                      <div className={styles.detailList}>
                        <div><b> Gender </b></div>
                        <div>:&emsp; {profile.gender}</div>
                      </div>
                      <div className={styles.detailList}>
                        <div><b> Email </b></div>
                        <div>:&emsp; {this.state.employee.email}</div>
                      </div>
                      <div className={styles.detailList}>
                        <div><b> Civil Status </b></div>
                        <div>:&emsp; {profile.civil_status}</div>
                      </div>

                      <div className={styles.detailList}>
                        <div><b> Address </b></div>
                        <div>:&emsp;{profile.home_no ? profile.home_no : ''} {profile.address}</div>
                      </div>
                    </div>
                    <div className={styles.rightShowDetails}>
                      <div className={styles.detailList}>
                        <div><b> SSS </b></div>
                        <div>:&emsp; {profile.sss_no}</div>
                      </div>
                      <div className={styles.detailList}>
                        <div><b> PAGIBIG </b></div>
                        <div>:&emsp; {profile.pagibig_no}</div>
                      </div>
                      <div className={styles.detailList}>
                        <div><b> TIN </b></div>
                        <div>:&emsp; {profile.tin_no}</div>
                      </div>
                      <div className={styles.detailList}>
                        <div><b> Philhealth </b></div>
                        <div>:&emsp; {profile.philhealth_no}</div>
                      </div>
                      <div className={styles.detailList}>
                        <div><b> Contact No. (Mobile) </b></div>
                        <div>:&emsp; {profile.mobile_no}</div>
                      </div>
                      <div className={styles.detailList}>
                        <div><b> Contact No. (Home) </b></div>
                        <div>:&emsp; {profile.home_no}</div>
                      </div>
                    </div>
                  </div>
                  : "Click toggle to view details"}
                <div className={styles.showTitleHeader}>
                  <div className={styles.detailList}>
                    <h3><b> Employment Details </b></h3>
                  </div>
                  <div>
                  </div>
                </div>
                <hr />
                {this.state.isCaretOpen ?
                  <div className={styles.detailsBody}>
                    <div className={styles.leftShowDetails}>
                      <div className={styles.detailList}>
                        <div><b> Bank Account No. </b></div>
                        <div>:&emsp; {profile.bank_account_no}</div>
                      </div>
                      <div className={styles.detailList}>
                        <div><b> Current Salary </b></div>
                        <div>:&emsp; {this.state.employee.salary ? parseFloat(this.state.employee.salary.rate).toLocaleString(navigator.language, { minimumFractionDigits: 2 }) : '0.0'}</div>
                      </div>
                      <div className={styles.detailList}>
                        <div><b> Date Hired </b></div>
                        <div>:&emsp; {moment(this.state.employee.date_hired).format('L')}</div>
                      </div>
                      <div className={styles.detailList}>
                        <div><b> Employment Status </b></div>
                        <div>:&emsp; {employmentStatus ? employmentStatus.kind : null}</div>
                      </div>

                    </div>
                    <div className={styles.rightShowDetails}>
                      <div className={styles.detailList}>
                        <div><b> Branch </b></div>
                        <div>:&emsp; {emp.branch ? (emp.branch.name) : ("")}</div>
                      </div>
                      <div className={styles.detailList}>
                        <div><b> Department </b></div>
                        <div>:&emsp; {emp.department ? (emp.department.name) : ("")}</div>
                      </div>
                      <div className={styles.detailList}>
                        <div><b> Division </b></div>
                        <div>:&emsp; {emp.division ? (emp.division.name) : ("")}</div>
                      </div>
                      <div className={styles.detailList}>
                        <div><b> Position </b></div>
                        <div>:&emsp; {emp.position ? (emp.position.name) : ("")}</div>
                      </div>
                      <div className={styles.detailList}>
                        <div><b> Tax Exempted </b></div>
                        <div>:&emsp; {profile.is_tax_exempted ? ("Yes") : ("No")}</div>
                      </div>
                    </div>
                  </div>
                  : "Click toggle to view details"}
                <SimpleTable
                  items={this.state.approvingOfficers}
                  model="Approving Officer"
                  headers={['officer.name']}
                  handleClick={this.handleCreateApprovingOfficer}
                  noActionHeader={true}
                />
              </>
            </TabPanel>
            <TabPanel className={styles.tabBody} value={this.state.value} index={1}>
              <SimpleTable
                items={this.state.attachments}
                model="Salary History"
                headers={['file_name', 'description']}
                handleClick={this.handleCreateAttachment}
                onOpenDeletePopup={this.handleOpenDeleteAttachment}
                noActionHeader={true}
              />
            </TabPanel>
            <TabPanel className={styles.tabBody} value={this.state.value} index={2}>
              <SimpleTable
                items={this.state.payrollDetails.sort((a, b) => { return new Date(b.payroll.date_to) - new Date(a.payroll.date_to) })}
                model="Employee Payroll"
                noActionHeader={true}
                headers={['payroll_period', 'payroll_income', 'gross_income', 'payroll_deductions', 'net_pay']}
                nodelete={true}
                withShowPopUp={true}
                handleShowPopUp={this.handleShowPopUpPayroll}
              />
            </TabPanel>
            <SimplePopUp
              openPopup={this.state.openPayrollDetails}
              title={this.state.employee.name_formal + ' / ' + this.state.employee.reference_no}
              handleClose={this.handleClose}
              maxWidth={this.state.maxWidth}
              withPrint={true}
              print={this.printEmployeePayroll}
            >
              <PayrollEmployeeInfo
                payrollList={this.state.payrollEmployee.payroll}
                item={this.state.payrollEmployee}
                payrollAdjustment={this.state.payrollAdjustment}
                printEmployeePayroll={this.state.printEmployeePayroll}
              />
            </SimplePopUp>
          </div>
          : null}
        <AlertMessage
          notify={this.state.notify}
          handleCloseAlert={this.handleCloseAlert}
          isOpen={this.state.isOpen}
          type={this.state.type}
          message={this.state.message}
        />
      </>
    )
  }
}

export default Home

Home.propTypes = {
  employeeId: PropTypes.number,
}
