import React from 'react'
import styles from './ChangePassword.module.css'

import PropTypes from 'prop-types'

import axios from 'axios'
import {
  Button,Grid,FormControl,InputAdornment,InputLabel,IconButton,OutlinedInput
} from '@material-ui/core'

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Cookies from 'js-cookie';

class ChangePasswordForm extends React.Component {

  constructor (props) {
    super(props)
    this.state = {
      model: 'Expense Type',
      changePassword: {old_password: '', new_password: '',new_password_confirmation: '' },
      showNewConfirmPassword: false,
      showOldPassword: false,
      showNewPassword: false,
      error: false,

    }
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleClickShowConfirmPassword = this.handleClickShowConfirmPassword.bind(this)
    this.handleMouseDownPassword = this.handleMouseDownPassword.bind(this)
    this.handleClickShowOldPassword = this.handleClickShowOldPassword.bind(this)
    this.handleClickShowNewPassword = this.handleClickShowNewPassword.bind(this)
    this.submit = this.submit.bind(this)

  }

  componentDidMount () {
  }

  handleInputChange (e) {
    this.setState({ changePassword: { ...this.state.changePassword,  [e.target.name]: e.target.value } })
  }

  handleClickShowConfirmPassword () {
    this.setState({ showNewConfirmPassword: !this.state.showNewConfirmPassword })
  }

  handleClickShowOldPassword () {
    this.setState({ showOldPassword: !this.state.showOldPassword })
  }

  handleClickShowNewPassword () {
    this.setState({ showNewPassword: !this.state.showNewPassword })
  }

  handleMouseDownPassword (event) {
    event.preventDefault();
  }

  validateForm() {
    return (
      this.state.changePassword.old_password.length > 0 &&
      this.state.changePassword.new_password.length > 0 &&
      this.state.changePassword.new_password === this.state.changePassword.new_password_confirmation
    );
  }

  fieldsError() {
    if(this.state.changePassword.new_password !== '' && this.state.changePassword.new_password_confirmation !== '') {
      return (
        this.state.changePassword.new_password === this.state.changePassword.new_password_confirmation
      )
    }else {
      return true
    }

  }

  submit() {
    const item = this.state.changePassword
    axios({
      method: 'put',
      url: '/v1/users/' + JSON.parse(Cookies.get("current_user")).id,
      data: (item),
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(() => {
      location.reload()
    })
  }


  render () {
    return (
      <div>
        <form>
           <Grid container spacing={1}>
            <Grid item xs={12}>
            <FormControl variant="outlined" className={styles.textField} size="small" fullWidth>
              <InputLabel htmlFor="outlined-adornment-password">Old Password</InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                label="Old Password"
                type={this.state.showOldPassword ? 'text' : 'password'}
                onChange={this.handleInputChange}
                value={this.state.changePassword.old_password}
                name="old_password"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={this.handleClickShowOldPassword}
                      onMouseDown={this.handleMouseDownPassword}
                      edge="end"
                    >
                      {this.state.showOldPassword  ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                labelWidth={70}
              />
            </FormControl>

            </Grid>
            <Grid item xs={12}>
              <FormControl variant="outlined" className={styles.textField} size="small" fullWidth>
                <InputLabel htmlFor="outlined-adornment-password">New Password</InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  label="New Password"
                  type={this.state.showNewPassword ? 'text' : 'password'}
                  value={this.state.changePassword.new_password}
                  name="new_password"
                  onChange={this.handleInputChange}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={this.handleClickShowNewPassword}
                        onMouseDown={this.handleMouseDownPassword}
                        edge="end"
                      >
                        {this.state.showNewPassword  ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                  labelWidth={70}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>

            <FormControl variant="outlined" className={styles.textField} size="small" fullWidth>
              <InputLabel htmlFor="outlined-adornment-confirm-password">Confirm Password</InputLabel>
              <OutlinedInput
                id="outlined-adornment-confirm-password"
                label="Confirm Password"
                type={this.state.showNewConfirmPassword ? 'text' : 'password'}
                value={this.state.changePassword.new_password_confirmation}
                name="new_password_confirmation"
                onChange={this.handleInputChange}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={this.handleClickShowConfirmPassword}
                      onMouseDown={this.handleMouseDownPassword}
                      edge="end"
                    >
                      {this.state.showNewConfirmPassword  ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                labelWidth={70}
              />
            </FormControl>
            <div className={styles.errorMessage}>{!this.fieldsError()? "Passwords do not match": null}</div>

            </Grid>
          </Grid>
        </form>
        <div className={styles.actionButton}>
          <Button variant="outlined" id="expenseType-submit" color="primary"
          disabled={!this.validateForm()}
          onClick={() => this.submit()}
          >Save</Button>
        </div>
      </div>
    )
  }
}
export default ChangePasswordForm

ChangePasswordForm.propTypes = {
  onchange: PropTypes.func,
  item: PropTypes.object,
  submit: PropTypes.func,
  error: PropTypes.object
}
