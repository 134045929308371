import React, { Component } from "react";
import style from "./Annualization.module.css";
import { Box, Button, ButtonGroup, Paper, Typography } from "@material-ui/core";
import NativeSelect from "@material-ui/core/NativeSelect";
import NoteIcon from "@material-ui/icons/Note";
import AnnualizationTable from "./AnnualizationTable";
import axios from "axios";
import * as XLSX from "xlsx";
import moment from "moment";
import ProgressBar from "../../shared/ProgressBar/ProgressBar";

class Annualization extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSummary: false,
      isFetchingData: true,
      annualPayrolls: [],
      rawAnnualPayroll: [],
      fetchError: false,
      errorMessage: "",
      selectedYear: 2022,
    };
    this.loadAnnualPayrolls = this.loadAnnualPayrolls.bind(this);
    this.handleSelectYearChange = this.handleSelectYearChange.bind(this);
    this.exportToExcel = this.exportToExcel.bind(this);
    this.toggleSummary = this.toggleSummary.bind(this);
    this.numberFormatter = this.numberFormatter.bind(this);
    this.formatDataToPrint = this.formatDataToPrint.bind(this);
  }

  componentDidMount() {
    this.loadAnnualPayrolls();
  }

  loadAnnualPayrolls() {
    axios({
      method: "get",
      url: "/v1/annual_payrolls",
      headers: { "X-API-ACCESS-TOKEN": localStorage.getItem("api_key") },
    })
      .then((resp) => {
        this.setState({
          isFetchingData: false,
          rawAnnualPayroll: resp.data.annual_payrolls,
          annualPayrolls: resp.data.annual_payrolls
            .sort(
              (a, b) =>
                a.employee.name_formal.charAt(0) >
                b.employee.name_formal.charAt(0)
            )
            .reduce((accumulator, currentVal) => {
              if (accumulator.length === 0) {
                return accumulator.concat([
                  [
                    {
                      id: currentVal.employee.id,
                      name: currentVal.employee.name_formal,
                      referenceId: currentVal.employee.reference_no,
                    },
                    currentVal,
                  ],
                ]);
              } else {
                const valExistInAccumulator = accumulator.findIndex(
                  (accumulatorVal) =>
                    accumulatorVal[0].id === currentVal.employee.id
                );
                if (valExistInAccumulator !== -1) {
                  return [
                    ...accumulator.filter(
                      (accumulatorVal) =>
                        accumulatorVal[0].id !== currentVal.employee.id
                    ),
                    accumulator[valExistInAccumulator].concat([currentVal]),
                  ];
                }
              }
              return accumulator.concat([
                [
                  {
                    id: currentVal.employee.id,
                    name: currentVal.employee.name_formal,
                    referenceId: currentVal.employee.reference_no,
                  },
                  currentVal,
                ],
              ]);
            }, []),
        });
      })
      .catch((error) => {
        this.setState({
          isFetchingData: false,
          fetchError: true,
          errorMessage: error.message,
        });
      });
  }

  toggleSummary() {
    this.setState((state) => ({
      isSummary: !state.isSummary,
    }));
  }

  numberFormatter(value) {
    return value
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  handleSelectYearChange(event) {
    this.setState({
      selectedYear: event.target.value,
    });
  }

  formatDataToPrint() {
    const { annualPayrolls } = this.state;

    const filteredPayrolls = [];

    annualPayrolls.forEach((payroll) => {
      const individualPayroll = payroll
        .slice(1)
        .sort((a, b) => a.payroll.date_from > b.payroll.date_from)
        .map((payrollRecord) => {
          const {
            id,
            other_deductions,
            other_income,
            employee_sss,
            employee_pagibig,
            employee_philhealth,
            employee_bir,
            taxable_income,
            total_deductions,
            payroll: employeePayroll,
          } = payrollRecord;

          return {
            id,
            inclusiveDates: `${moment(employeePayroll.date_from).format(
              "MMM DD"
            )} - ${moment(employeePayroll.date_to).format("DD, YYYY")}`,
            basic: this.numberFormatter(Number(taxable_income)),
            otherTaxable: this.numberFormatter(Number(other_deductions)),
            otherNonTaxable: this.numberFormatter(Number(other_income)),
            grossIncome: this.numberFormatter(
              Number(taxable_income) +
                Number(other_income) -
                Number(other_deductions)
            ),
            taxableIncome: this.numberFormatter(
              Number(taxable_income) - Number(other_deductions)
            ),
            sss: this.numberFormatter(Number(employee_sss)),
            pagIbig: this.numberFormatter(Number(employee_pagibig)),
            philHealth: this.numberFormatter(Number(employee_philhealth)),
            totalTaxable: this.numberFormatter(
              Number(taxable_income) - Number(total_deductions),
              2
            ),
            taxWithheld: this.numberFormatter(Number(employee_bir)),
            action: "",
          };
        });
      individualPayroll.unshift(payroll[0]);
      filteredPayrolls.push(individualPayroll);
    });

    const dataToPrint = filteredPayrolls.map((payroll) => {
      const result = payroll.slice(1).reduce(
        (prevVal, currentVal) => {
          return {
            totalBasic:
              prevVal.totalBasic + Number(currentVal.basic.replace(",", "")),
            totalOtherTaxable:
              prevVal.totalOtherTaxable +
              Number(currentVal.otherTaxable.replace(",", "")),
            totalOtherNonTaxable:
              prevVal.totalOtherNonTaxable +
              Number(currentVal.otherNonTaxable.replace(",", "")),
            totalGrossIncome:
              prevVal.totalGrossIncome +
              Number(currentVal.grossIncome.replace(",", "")),
            totalTaxableIncome:
              prevVal.totalTaxableIncome +
              Number(currentVal.taxableIncome.replace(",", "")),
            totalSss:
              prevVal.totalSss + Number(currentVal.sss.replace(",", "")),
            totalPagibig:
              prevVal.totalPagibig +
              Number(currentVal.pagIbig.replace(",", "")),
            totalPhilhealth:
              prevVal.totalPhilhealth +
              Number(currentVal.philHealth.replace(",", "")),
            totalTaxable:
              prevVal.totalTaxable +
              Number(currentVal.totalTaxable.replace(",", "")),
            totalTaxWithheld:
              prevVal.totalTaxWithheld +
              Number(currentVal.taxWithheld.replace(",", "")),
          };
        },
        {
          totalBasic: 0,
          totalOtherTaxable: 0,
          totalOtherNonTaxable: 0,
          totalGrossIncome: 0,
          totalTaxableIncome: 0,
          totalSss: 0,
          totalPagibig: 0,
          totalPhilhealth: 0,
          totalTaxable: 0,
          totalTaxWithheld: 0,
        }
      );

      return {
        Name: payroll[0].name,
        Basic: this.numberFormatter(result.totalBasic),
        "Other Taxable": this.numberFormatter(result.totalOtherTaxable),
        "Other Non Taxable": this.numberFormatter(result.totalOtherNonTaxable),
        "Gross Income": this.numberFormatter(result.totalGrossIncome),
        "Taxable Income": this.numberFormatter(result.totalTaxableIncome),
        SSS: this.numberFormatter(result.totalSss),
        "PAG IBIG": this.numberFormatter(result.totalPagibig),
        "PHIL HEALTH": this.numberFormatter(result.totalPhilhealth),
        "Total Taxable": this.numberFormatter(result.totalTaxable),
        "Tax Withheld": this.numberFormatter(result.totalTaxWithheld),
      };
    });

    return dataToPrint;
  }

  exportToExcel() {
    const dataToPrint = this.formatDataToPrint();

    const ws = XLSX.utils.json_to_sheet(dataToPrint);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Data");
    XLSX.writeFileXLSX(wb, "Annualization.xlsx");
  }

  render() {
    const {
      isFetchingData,
      annualPayrolls,
      rawAnnualPayroll,
      selectedYear,
      errorMessage,
      isSummary,
    } = this.state;

    let selectYearOptions = [];
    for (
      let yearStart = 2021;
      yearStart <= new Date().getFullYear();
      yearStart++
    ) {
      selectYearOptions.push(yearStart);
    }

    return isFetchingData ? (
      <ProgressBar model="Annualization" />
    ) : (
      <Paper style={{ flexGrow: 1, padding: "10px" }}>
        <Box className={style.boxHeader}>
          <Typography
            variant="h1"
            style={{ fontSize: "16px", fontWeight: 800 }}
          >
            Payroll Annualization 2022
          </Typography>
          <ButtonGroup
            variant="contained"
            color="primary"
            aria-label="contained primary button group"
            size="small"
          >
            <Button disabled style={{ fontSize: "10px", color: "#000" }}>
              Annual Payroll Report
            </Button>
            <NativeSelect
              value={selectedYear}
              onChange={this.handleSelectYearChange}
              name="year"
              inputProps={{ "aria-label": "year" }}
              className={style.selectStyles}
            >
              {selectYearOptions.map((year, idx) => (
                <option value={year} key={idx}>
                  {year}
                </option>
              ))}
            </NativeSelect>
            <Button color="primary" style={{ fontSize: "10px" }}>
              Get
            </Button>
            <Button color="secondary" style={{ fontSize: "10px" }}>
              Generate
            </Button>
          </ButtonGroup>
        </Box>
        <Box className={style.boxSubHeader}>
          <Button
            size="small"
            color="primary"
            variant="contained"
            startIcon={<NoteIcon />}
            style={{ fontSize: "10px" }}
            onClick={() => this.exportToExcel()}
          >
            Export to Excel
          </Button>
          <Button
            size="small"
            color={isSummary ? "secondary" : "primary"}
            variant="contained"
            style={{ fontSize: "10px" }}
            onClick={this.toggleSummary}
          >
            Summary
          </Button>
        </Box>
        {annualPayrolls.length > 0 ? (
          annualPayrolls.map((payroll, idx) => {
            return (
              <AnnualizationTable
                key={idx}
                payroll={payroll}
                rawAnnualPayroll={rawAnnualPayroll}
                isSummary={isSummary}
              />
            );
          })
        ) : (
          <Box className={style.errorOverlay}>
            <kbd>{errorMessage}</kbd>
          </Box>
        )}
      </Paper>
    );
  }
}

export default Annualization;
