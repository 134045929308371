// eslint-disable-next-line
import React from 'react'

import PropTypes from 'prop-types'

import SimpleTable from '../../shared/SimpleTable/SimpleTable'
import SimplePopUp from '../../shared/SimplePopUp/SimplePopUp'
import SimpleDeletePopUp from '../../shared/SimpleDeletePopUp/SimpleDeletePopUp'
import EmployeeForm from './EmployeeForm.js'
import EmployeesSearch from './EmployeesSearch'
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline'

import axios from 'axios'
import { withRouter, Redirect } from 'react-router-dom'

import AlertMessage from "../../shared/Notify/AlertMessage";
import ProgressBar from "../../shared/ProgressBar/ProgressBar";

export const Component = withRouter(() => {

})

class Employees extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            model: 'Employee',
            employees: [],
            openPopup: false,
            openDeletePopup: false,
            employee: {},
            initialEmployee: { id: '', name: '', address: '', classification: 'vatable', tin: '', business_style: '' },
            title: '',
            load: false,
            tableHead: ['reference_no', 'name_formal', 'branch.name', 'department.name', 'position.name', "is_active"],
            withShow: false,
            withEdit: true,
            maxWidth: 'sm',
            error_messages: '',
            notify: {},
            isOpen: false,
            message: '',
            type: '',
            searchEmployee: { is_active: true },
            draftEmployees: [],
            totalPages: 1,
            currentPage: 1,
            withPagination: true,
            totalResults: 0,
            withResultsLabel: false,
            redirect: false,
            departments: [],
            positions: [],
            searchUrl: '/v1/employees/search'

        }
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleDeleteItem = this.handleDeleteItem.bind(this)
        this.handleCreateorUpdateItem = this.handleCreateorUpdateItem.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this)
        this.handleOpenDeletePopup = this.handleOpenDeletePopup.bind(this)
        this.handleDeleteClose = this.handleDeleteClose.bind(this)
        this.handleCloseAlert = this.handleCloseAlert.bind(this)
        this.handleSearch = this.handleSearch.bind(this)
        this.handleSearchInputChange = this.handleSearchInputChange.bind(this)
        this.clearSearch = this.clearSearch.bind(this)
        this.handlePageChange = this.handlePageChange.bind(this)
        this.handleKeyPress = this.handleKeyPress.bind(this)
        this.handlePositions = this.handlePositions.bind(this)
        this.handleAutocomplete = this.handleAutocomplete.bind(this)
    }

    componentDidMount() {
        var searchUrl = this.state.searchUrl + this.props.location.search + (this.props.location.search ? '&is_active=true' : '?is_active=true')
        var searchParams = {}
        const params = new URLSearchParams(this.props.location.search)
        // This is to load all params based on URLSearchParams
        params.forEach(function (value, key) {
            if (key !== 'page') {
                if (key === 'name' || key === 'content' || key === 'title') {
                    searchParams[key] = value.replace(/\*/g, '')
                } else if (key === 'department_name') {
                    axios({
                        method: 'get',
                        url: '/v1/departments',
                        headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
                    }).then(resp => {
                        searchParams["department"] = resp.data.filter(department => department.name === value)[0]
                    })
                } else if (key === 'position_name') {
                    axios({
                        method: 'get',
                        url: '/v1/positions',
                        headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
                    }).then(resp => {
                        searchParams["position"] = resp.data.filter(position => position.name === value)[0]
                    })
                }
                else {
                    searchParams[key] = value
                }
            }
        })
        if (!searchParams['is_active']) {
            searchParams['is_active'] = true
        }

        this.setState({ searchEmployee: searchParams })
        this.loadEmployees(searchUrl)
    }

    loadEmployees(url) {
        axios({
            method: 'get',
            url: url,
            headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
        }).then(resp => {
            this.setState({ employees: resp.data.employees, totalPages: resp.data.meta.total_pages, totalResults: resp.data.meta.total_count, load: true })
        }).catch(error => console.log(error.response))
        axios({
            method: 'get',
            url: '/v1/departments',
            headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
        }).then(resp => {
            this.setState({ departments: resp.data })
        }).catch(error => console.log(error.response))
        axios({
            method: 'get',
            url: '/v1/positions',
            headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
        }).then(resp => {
            this.setState({ positions: resp.data })
        }).catch(error => console.log(error.response))

    }

    handleSubmit() {
        const item = this.state.employee
        var method = ''
        var url = ''
        if (item.id === '') {
            method = 'post'
            url = '/v1/employees/'
        } else {
            method = 'put'
            url = '/v1/employees/' + item.id
        }
        axios({
            method: method,
            url: url,
            data: (item),
            headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
        }).then(resp => {

            const newData = resp.data
            this.setState({
                employees: this.state.employees.filter(employee => employee.id !== item.id)
            })
            this.setState({ employees: [...this.state.employees, newData] })
            this.setState({ openPopup: false })
            this.setState({
                isOpen: true,
                message: 'Submitted Successfully',
                type: 'success'
            })
        }).catch(error => {
            if (error.response.status === 422) {
                this.setState({ error_messages: 'Name ' + error.response.data.errors.name[0] })
            } else {
                this.setState({
                    isOpen: true,
                    message: error.response.status.toString() + " Unexpected Error Problem Occurred",
                    type: 'error'
                })
                this.setState({ openPopup: false })
            }
        })
    }


    getSearchParams() {
        var searchParams = []
        // This is to load all params based on searchParams state.
        Object.entries(this.state.searchEmployee).map(([key, value]) => {
            if (value != '' &&value != 'All' && value != undefined && value != null) {
                if (key === "department" && value.id !== undefined && value.id !== null && value.name !== null && value.name !== undefined) {
                    searchParams.push(['department_name', value.name].join("="))
                } else if (key === "position" && value.id !== undefined && value.id !== null && value.name !== null && value.name !== undefined) {
                    searchParams.push(['position_name', value.name].join("="))
                }
                else {
                    searchParams.push([key, value].join("="))
                }
            }
        })
        if (!searchParams['is_active']) {
            searchParams['is_active'] = true
        }

        return searchParams
    }

    handleSearch() {
        this.setState({ load: false })
        const { history } = this.props
        var searchParams = this.getSearchParams().join("&")
        const params = new URLSearchParams()
        params.append("/search", searchParams)
        history.push({ search: searchParams })
        this.loadEmployees([this.state.searchUrl, searchParams].join("?"))
    }

    handleSearchInputChange(e) {
        this.setState({
            searchEmployee: {
                ...this.state.searchEmployee,
                [e.target.name]: e.target.value
            }
        })
    }

    handleDeleteItem() {
        const DeleteItemId = this.state.employee.id
        axios({
            method: 'delete',
            url: '/v1/employees/' + DeleteItemId,
            headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
        }).then(() => {
            this.setState({
                employees: this.state.employees.filter(item => item.id !== DeleteItemId)
            })
            this.setState({ openDeletePopup: false })
            this.setState({
                isOpen: true,
                message: 'Deleted Successfully',
                type: 'error'
            })
        }).catch(error => console.log(error.response))
    }

    handleClose() {
        this.setState({ openPopup: false })
        this.setState({ openDeletePopup: false })
        this.setState({ error_messages: '' })
    }

    handleCreateorUpdateItem(item) {
        this.setState({ employee: item })
        this.setState({ redirect: true })
    }

    handleInputChange(e) {
        this.setState({
            employee: {
                ...this.state.employee,
                [e.target.name]: e.target.value
            }
        })
    }

    handleOpenDeletePopup(item, model) {
        this.setState({ model: model })
        this.setState({ employee: item })
        this.setState({ openDeletePopup: true })
    }

    handleDeleteClose() {
        this.setState({ openDeletePopup: false })
    }

    handleCloseAlert(event, reason) {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ isOpen: false })
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.handleSearch()
        }
    }

    clearSearch() {
        const { history } = this.props
        history.push({ search: '' })
        this.setState({ withResultsLabel: false })
        this.loadEmployees('/v1/employees/search?&is_active=true')
        this.setState({ searchEmployee: { name: '', department: null, position: null, employment_status: '', is_active: '' } })

    }

    handlePageChange(event, value) {
        var searchParams = this.getSearchParams()
        searchParams.push(["page", value].join("="))
        searchParams = searchParams.join("&")

        const { history } = this.props
        const params = new URLSearchParams()

        params.append("/search", searchParams)
        history.push({ search: searchParams })
        this.setState({ currentPage: value })
        this.loadEmployees([this.state.searchUrl, searchParams].join("?"))
    }

    handleAutocomplete(event, values) {
        this.setState({
            searchEmployee: {
                ...this.state.searchEmployee,
                department: values
            }
        })
    }
    handlePositions(event, values) {
        this.setState({
            searchEmployee: {
                ...this.state.searchEmployee,
                position: values
            }
        })
    }
    render() {
        const { redirect } = this.state
        var pathName = ''


        if (this.state.employee.id === "") {
            pathName = "/employee/new"
        } else {

            pathName = "/employees/" + this.state.employee.id + "/edit"
        }
        if (redirect)
            return (<Redirect to={{
                pathname: pathName
            }} />)
        return (
            <>
                {
                    this.state.load ? (
                        <div>
                            <EmployeesSearch
                                clearSearch={this.clearSearch}
                                item={this.state.searchEmployee}
                                search={this.handleSearch}
                                handleChange={this.handleSearchInputChange}
                                handleKeyPress={this.handleKeyPress}
                                departments={this.state.departments}
                                positions={this.state.positions}
                                handleAutocomplete={this.handleAutocomplete}
                                handlePositions={this.handlePositions}
                            />
                            <SimpleTable
                                handleClick={this.handleCreateorUpdateItem}
                                initialItem={this.state.initialEmployee}
                                items={this.state.employees}
                                model={this.state.model}
                                onOpenDeletePopup={this.handleOpenDeletePopup}
                                headers={this.state.tableHead}
                                withShow={this.state.withShow}
                                withEdit={this.state.withEdit}
                                icon={<PeopleOutlineIcon fontSize="large" />}
                                currentPage={this.state.currentPage}
                                totalPages={this.state.totalPages}
                                handlePageChange={this.handlePageChange}
                                withPagination={this.state.withPagination}
                                totalResults={this.state.totalResults}
                                withResultsLabel={this.state.withResultsLabel}
                            />
                            <SimplePopUp
                                openPopup={this.state.openPopup}
                                title={this.state.title}
                                handleClose={this.handleClose}
                                maxWidth={this.state.maxWidth}
                            >
                                <EmployeeForm error={this.state.error_messages} item={this.state.employee} submit={this.handleSubmit} onchange={this.handleInputChange} />
                            </SimplePopUp>

                            <SimpleDeletePopUp
                                openDeletePopup={this.state.openDeletePopup}
                                item={this.state.employee}
                                delete={this.handleDeleteItem}
                                handleDeleteClose={this.handleClose}
                                model={this.state.model}
                            />
                            <AlertMessage
                                notify={this.state.notify}
                                handleCloseAlert={this.handleCloseAlert}
                                isOpen={this.state.isOpen}
                                type={this.state.type}
                                message={this.state.message}
                            />
                        </div>
                    ) : (
                        <ProgressBar model={this.state.model} />
                    )}
            </>
        )
    }
}

export default withRouter(Employees)

Employees.propTypes = {
    location: PropTypes.object,
    history: PropTypes.object
}
