import React from 'react'
import styles from './Announcements.module.css'

import PropTypes from 'prop-types'

import {
	TextField,
	Button,
} from '@material-ui/core'
// eslint-disable-next-line 
import Trix from "trix";
import { ReactTrixRTEInput, ReactTrixRTEToolbar } from "react-trix-rte";
import { Autocomplete } from '@material-ui/lab';
class AnnouncementForm extends React.Component {
	render() {
		return (
			<div>
				<form>
					<div>
						<TextField
							autoComplete="off"
							className={styles.textFields}
							variant="outlined"
							size="small"
							name="title"
							fullWidth
							label="Title"
							onChange={this.props.handleChange}
							value={this.props.item.title}
							id="announcement-title"
						/>
					</div><br />
					{
						!this.props.currentUser.is_employee?
						<div className={styles.autoCompleteField}>
						
						<Autocomplete
							value={this.props.selectedEmployee}
							id="employees"
							name="employees"
							fullWidth
							size="small"
							options={(this.props.employees).sort(function (a, b) {
								if (a.name_formal.toLowerCase() < b.name_formal.toLowerCase()) return -1;
								if (a.name_formal.toLowerCase() > b.name_formal.toLowerCase()) return 1;
								return 0;
							})}
							getOptionLabel={(option) => option.name_formal || ''}
							getOptionSelected={(option, value) => option.name_formal === value.name_formal}
							onChange={this.props.handleAutocomplete}
							renderInput={(params) => (
								<TextField {...params} name="employees" variant="outlined" label="Employee" />
							)}
						/>
						<br />
					</div>:null
					}

					<ReactTrixRTEToolbar toolbarId="react-trix-rte-editor" />
					<ReactTrixRTEInput
						className={styles.editor}
						toolbarId="react-trix-rte-editor"
						value={this.props.item.content}
						defaultValue={this.props.item.content}
						name="content"
						id="announcement-content"
						onChange={this.props.handleEditor}
					/>
					<br />
				</form>

				<div className={styles.actionButton}>
					<Button variant="outlined" disabled={!this.props.item.title} id="announcement-submit" color="primary" onClick={() => this.props.submit()} >Save</Button>
				</div>
			</div>
		)
	}
}
export default AnnouncementForm

AnnouncementForm.propTypes = {
	handleChange: PropTypes.func,
	item: PropTypes.object,
	children: PropTypes.object,
	submit: PropTypes.func,
	handleEditor: PropTypes.func,
	handleAutocomplete: PropTypes.func,
	employees: PropTypes.array,
	selectedEmployee: PropTypes.object,
	currentUser: PropTypes.object,
}
