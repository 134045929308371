// eslint-disable-next-line

import React from 'react'
import SimpleTable from '../../shared/SimpleTable/SimpleTable'
import { Link } from 'react-router-dom'
import { Redirect } from 'react-router-dom'
import IconButton from '@material-ui/core/IconButton'
import axios from 'axios'
import ProgressBar from "../../shared/ProgressBar/ProgressBar"
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import styles from './Employees.module.css'
import Typography from '@material-ui/core/Typography'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Box from '@material-ui/core/Box'
import moment from 'moment'
import { Autocomplete } from '@material-ui/lab'
import { Grid, Avatar, Button, TextField } from '@material-ui/core'

import PropTypes from 'prop-types'
import ApprovingOfficerForm from './ApprovingOfficerForm'
import SimplePopUp from '../../shared/SimplePopUp/SimplePopUp'
import AttachmentForm from './AttachmentForm'
import SimpleDeletePopUp from '../../shared/SimpleDeletePopUp/SimpleDeletePopUp'
import SalaryHistoryForm from './SalaryHistoryForm'
import EmploymentStatusForm from './EmploymentStatusForm'
import RecurrenceForm from '../Recurrences/RecurrenceForm'
import AnnouncementTable from '../Announcements/AnnouncementTable'
import PayrollEmployeeInfo from '../PayrollList/PayrollEmployeeInfo'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import AlertMessage from '../../shared/Notify/AlertMessage'
import AttendanceForm from '../Attendances/AttendanceForm'
function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  }
}

class EmployeeShow extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      model: 'Employee',
      value: 0,
      employees: [],
      selectedEmployees: [],
      employmentStatuses: [],
      attachments: [],
      approvingOfficers: [],
      memos: [],
      recurrences: [],
      salaries: [],
      payrollDetails: [],
      employee: {},
      attachment: {},
      openAttachmentForm: false,
      approvingOfficer: {},
      openApprovingOfficerForm: false,
      deleteAttachmentPopup: false,
      salaryHistory: {},
      openSalaryHistoryForm: false,
      openSalaryHistoryDelete: false,
      salaryFormTitle: '',
      kinds: [],
      states: [],
      salaryTypes: [],
      openEmploymentForm: false,
      openDeleteEmployment: false,
      employmentStatus: {},
      employmentTitle: '',
      accounts: [],
      openRecurrencePopUp: false,
      recurrence: {},
      openRecurrenceDeletePopup: false,
      selectedRecurrenceEmployee: {},
      selectedRecurrenceEmployees: [],
      selectedAccount: {},
      employeePermissions: [],
      permissions: [],
      openPermissionForm: false,
      selectedEmployeePermissions: [],
      redirectToMemoShow: false,
      memo: {},
      openPayrollDetails: false,
      payrollList: {},
      payrollAdjustment: [],
      payrollEmployee: {},
      employeeId: '',
      attendances: [],
      attendance: {},
      openAttendancePopup: false,
      openAttendanceDeletePopup: false,
      attendanceTitle: '',
      initialAttendance: { date_worked: moment().format('yyyy-MM-DD'), time_in: moment().format('HH:mm'), time_out: moment().add(8,'hours').format('HH:mm') },
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleCreateAttachment = this.handleCreateAttachment.bind(this)
    this.handleCreateApprovingOfficer = this.handleCreateApprovingOfficer.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleSubmitApprovingOfficers = this.handleSubmitApprovingOfficers.bind(this)
    this.handleApprovingOfficers = this.handleApprovingOfficers.bind(this)
    this.handleDeleteAttachment = this.handleDeleteAttachment.bind(this)
    this.handleOpenDeleteAttachment = this.handleOpenDeleteAttachment.bind(this)
    this.onFileChange = this.onFileChange.bind(this)
    this.handleAttachmentSubmit = this.handleAttachmentSubmit.bind(this)
    this.handleAttachmentInputChange = this.handleAttachmentInputChange.bind(this)
    this.handleCreateorUpdateSalaryHistory = this.handleCreateorUpdateSalaryHistory.bind(this)
    this.handleOpenDeleteSalaryHistory = this.handleOpenDeleteSalaryHistory.bind(this)
    this.handleSalaryRateChange = this.handleSalaryRateChange.bind(this)
    this.handleSalaryChange = this.handleSalaryChange.bind(this)
    this.handleSubmitSalary = this.handleSubmitSalary.bind(this)
    this.handleDeleteSalaryHistory = this.handleDeleteSalaryHistory.bind(this)
    this.handleCreateOrUpdateEmploymentStatus = this.handleCreateOrUpdateEmploymentStatus.bind(this)
    this.handleOpenDeleteEmploymentStatus = this.handleOpenDeleteEmploymentStatus.bind(this)
    this.handleSubmitEmployment = this.handleSubmitEmployment.bind(this)
    this.handleEmploymentChange = this.handleEmploymentChange.bind(this)
    this.handleDeleteEmployment = this.handleDeleteEmployment.bind(this)
    this.handleSubmitRecurrence = this.handleSubmitRecurrence.bind(this)
    this.handleDeleteRecurrence = this.handleDeleteRecurrence.bind(this)
    this.handleCreateorUpdateRecurrence = this.handleCreateorUpdateRecurrence.bind(this)
    this.handleRecurrenceChange = this.handleRecurrenceChange.bind(this)
    this.handleOpenDeleteRecurrence = this.handleOpenDeleteRecurrence.bind(this)
    this.handleRecurrenceAutoComplete = this.handleRecurrenceAutoComplete.bind(this)
    this.handleCheckRecurrence = this.handleCheckRecurrence.bind(this)
    this.handleAmountRecurrenceChange = this.handleAmountRecurrenceChange.bind(this)
    this.handleNumberRecurrenceChange = this.handleNumberRecurrenceChange.bind(this)
    this.handleRecurrenceAccount = this.handleRecurrenceAccount.bind(this)
    this.handleClickPermission = this.handleClickPermission.bind(this)
    this.handleSubmitPermission = this.handleSubmitPermission.bind(this)
    this.handlePermissionAutoComplete = this.handlePermissionAutoComplete.bind(this)
    this.rowClick = this.rowClick.bind(this)
    this.handleShowPopUpPayroll = this.handleShowPopUpPayroll.bind(this)
    this.handleCloseAlert = this.handleCloseAlert.bind(this)
    this.handleCreateOrUpdateAttendance = this.handleCreateOrUpdateAttendance.bind(this)
    this.handleOpenDeleteAttendance = this.handleOpenDeleteAttendance.bind(this)
    this.handleSubmitAttendance = this.handleSubmitAttendance.bind(this)
    this.handleAttendanceChange = this.handleAttendanceChange.bind(this)
    this.handleDeleteAttendance = this.handleDeleteAttendance.bind(this)

  }

  componentDidMount() {
    if (this.props.employeeId !== undefined) {
      this.setState({ employeeId: this.props.employeeId.toString() })
      this.loadEmployee('/v1/employees/' + this.props.employeeId.toString())
    } else {
      if (this.props.match) {
        if (this.props.match.params) {
          if (this.props.match.params.id) {
            this.setState({ employeeId: this.props.match.params.id.toString() })
            this.loadEmployee('/v1/employees/' + this.props.match.params.id.toString())
          }
        }

      }
    }
  }

  addResourceToLocalStorage(resource, data) {
    var employeeResources = localStorage.getItem('employee_resources') ? JSON.parse(localStorage.getItem('employee_resources')) : {}
    employeeResources[resource] = data
    localStorage.setItem('employee_resources', JSON.stringify(employeeResources))
  }

  loadAttachments(url) {
    axios({
      method: 'get',
      url: url + '/attachments',
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {
      this.setState({ attachments: resp.data })
      if (this.props.employeeId) {
        this.addResourceToLocalStorage('attachments', resp.data)
      }
    }).catch(error => {
      if (error.response.status === 422) {
        this.setState({ error_messages: 'Name ' + error.response.data.errors.name[0] })
      } else {
        this.setState({
          isOpen: true, message: error.response.status.toString() + " Unexpected Error Problem Occurred", type: 'error'
        })
      }
    })
  }
  loadPayslips(url) {
    axios({
      method: 'get',
      url: url + '/payroll_details',
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {
      this.setState({ payrollDetails: resp.data })
      if (this.props.employeeId) {
        this.addResourceToLocalStorage('payrollDetails', resp.data)
      }
    }).catch(error => {
      if (error.response.status === 422) {
        this.setState({ error_messages: 'Name ' + error.response.data.errors.name[0] })
      } else {
        this.setState({ isOpen: true, message: error.response.status.toString() + " Unexpected Error Problem Occurred", type: 'error' })
      }
    })
  }

  loadSalaryHistory(url) {
    axios({
      method: 'get',
      url: url + '/salaries',
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {
      this.setState({ salaries: resp.data })
      if (this.props.employeeId) {
        this.addResourceToLocalStorage('salaries', resp.data)
      }
    }).catch(error => {
      if (error.response.status === 422) {
        this.setState({ error_messages: 'Name ' + error.response.data.errors.name[0] })
      } else {
        this.setState({ isOpen: true, message: error.response.status.toString() + " Unexpected Error Problem Occurred", type: 'error' })
      }
    })
  }

  loadEmploymentHistory(url) {
    axios({
      method: 'get',
      url: url + '/employment_statuses',
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {
      this.setState({ employmentStatuses: resp.data })
      if (this.props.employeeId) {
        this.addResourceToLocalStorage('employmentStatuses', resp.data)
      }
    }).catch(error => {
      if (error.response.status === 422) {
        this.setState({ error_messages: 'Name ' + error.response.data.errors.name[0] })
      } else {
        this.setState({ isOpen: true, message: error.response.status.toString() + " Unexpected Error Problem Occurred", type: 'error' })
      }
    })
  }

  loadRecurrences(url) {
    axios({
      method: 'get',
      url: url + '/recurrences',
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {
      this.setState({ recurrences: resp.data })
      if (this.props.employeeId) {
        this.addResourceToLocalStorage('recurrences', resp.data)
      }
    }).catch(error => {
      if (error.response.status === 422) {
        this.setState({ error_messages: 'Name ' + error.response.data.errors.name[0] })
      } else {
        this.setState({
          isOpen: true,
          message: error.response.status.toString() + " Unexpected Error Problem Occurred",
          type: 'error'
        })
        this.setState({ openPopup: false })
      }
    })
    if (!this.props.employeeId) {
      axios({
        method: 'get',
        url: '/v1/payroll_accounts',
        headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
      }).then(resp => {
        this.setState({ accounts: resp.data })

      }).catch(error => {
        if (error.response.status === 422) {
          this.setState({ error_messages: 'Name ' + error.response.data.errors.name[0] })
        } else {
          this.setState({
            isOpen: true,
            message: error.response.status.toString() + " Unexpected Error Problem Occurred",
            type: 'error'
          })
          this.setState({ openPopup: false })
        }
      })
    }

  }

  loadMemos(url) {
    axios({
      method: 'get',
      url: url + '/memos',
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {
      this.setState({ memos: resp.data })
      if (this.props.employeeId) {
        this.addResourceToLocalStorage('memos', resp.data)
      }
    }).catch(error => {
      if (error.response.status === 422) {
        this.setState({ error_messages: 'Name ' + error.response.data.errors.name[0] })
      } else {
        this.setState({
          isOpen: true,
          message: error.response.status.toString() + " Unexpected Error Problem Occurred",
          type: 'error'
        })
        this.setState({ openPopup: false })
      }
    })
  }

  loadPermissions(url) {
    axios({
      method: 'get',
      url: url + '/permissions',
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {
      this.setState({ employeePermissions: resp.data })
      if (this.props.employeeId) {
        this.addResourceToLocalStorage('employeePermissions', resp.data)
      }
    }).catch(error => {
      if (error.response.status === 422) {
        this.setState({ error_messages: 'Name ' + error.response.data.errors.name[0] })
      } else {
        this.setState({
          isOpen: true,
          message: error.response.status.toString() + " Unexpected Error Problem Occurred",
          type: 'error'
        })
        this.setState({ openPopup: false })
      }
    })
    if (!this.props.employeeId) {
      axios({
        method: 'get',
        url: '/v1/permissions',
        headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
      }).then(resp => {
        this.setState({ permissions: resp.data })

      }).catch(error => {
        if (error.response.status === 422) {
          this.setState({ error_messages: 'Name ' + error.response.data.errors.name[0] })
        } else {
          this.setState({
            isOpen: true,
            message: error.response.status.toString() + " Unexpected Error Problem Occurred",
            type: 'error'
          })
          this.setState({ openPopup: false })
        }
      })
    }

  }



  handleChange(event, newValue) {
    const url = '/v1/employees/' + this.state.employeeId
    if (newValue === 1) {
      if (this.props.employeeId && localStorage.getItem('employee_resources')) {
        if (JSON.parse(localStorage.getItem('employee_resources')).attachments) {
          this.setState({ attachments: JSON.parse(localStorage.getItem('employee_resources')).attachments })
        } else {
          this.loadAttachments(url)
        }
      } else {
        this.loadAttachments(url)
      }
    } else if (newValue === 2) {
      if (this.props.employeeId && localStorage.getItem('employee_resources')) {
        if (JSON.parse(localStorage.getItem('employee_resources')).payrollDetails) {
          this.setState({ payrollDetails: JSON.parse(localStorage.getItem('employee_resources')).payrollDetails })
        } else {
          this.loadPayslips(url)
        }
      } else {
        this.loadPayslips(url)
      }

    } else if (newValue === 3) {
      if (this.props.employeeId && localStorage.getItem('employee_resources')) {
        if (JSON.parse(localStorage.getItem('employee_resources')).salaries) {
          this.setState({ salaries: JSON.parse(localStorage.getItem('employee_resources')).salaries })
        } else {
          this.loadSalaryHistory(url)
        }
      } else {
        this.loadSalaryHistory(url)
      }
    } else if (newValue === 4) {
      if (this.props.employeeId && localStorage.getItem('employee_resources')) {
        if (JSON.parse(localStorage.getItem('employee_resources')).employmentStatuses) {
          this.setState({ employmentStatuses: JSON.parse(localStorage.getItem('employee_resources')).employmentStatuses })
        } else {
          this.loadEmploymentHistory(url)
        }
      } else {
        this.loadEmploymentHistory(url)
      }
    } else if (newValue === 5) {
      this.loadAttendances(url)
    }
    else if (newValue === 6) {
      if (this.props.employeeId && localStorage.getItem('employee_resources')) {
        if (JSON.parse(localStorage.getItem('employee_resources')).recurrences) {
          this.setState({ recurrences: JSON.parse(localStorage.getItem('employee_resources')).recurrences })
        } else {
          this.loadRecurrences(url)
        }
      } else {
        this.loadRecurrences(url)
      }
    } else if (newValue === 7) {
      if (this.props.employeeId && localStorage.getItem('employee_resources')) {
        if (JSON.parse(localStorage.getItem('employee_resources')).memos) {
          this.setState({ memos: JSON.parse(localStorage.getItem('employee_resources')).memos })
        } else {
          this.loadMemos(url)
        }
      } else {
        this.loadMemos(url)
      }
    }
    else if (newValue === 8) {
      if (this.props.employeeId && localStorage.getItem('employee_resources')) {
        if (JSON.parse(localStorage.getItem('employee_resources')).employeePermissions) {
          this.setState({ employeePermissions: JSON.parse(localStorage.getItem('employee_resources')).employeePermissions })
        } else {
          this.loadPermissions(url)
        }
      } else {
        this.loadPermissions(url)
      }
    }
    this.setState({ value: newValue })
  }

  loadAttendances(url) {
    axios({
      method: 'get',
      url: url + '/attendances',
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {
      this.setState({ attendances: resp.data })
      if(resp.data.length>0){
        this.setState({ initialAttendance: {...this.state.initialAttendance,
           date_worked: moment(resp.data.sort((a,b) => new Date(b.date_worked) - new Date(a.date_worked) )[0].date_worked).add(1,'days').format('yyyy-MM-DD')
          }
          })
      }
    }).catch(error => {
      if (error.response.status === 422) {
        this.setState({ error_messages: 'Name ' + error.response.data.errors.name[0] })
      } else {
        this.setState({
          isOpen: true,
          message: error.response.status.toString() + " Unexpected Error Problem Occurred",
          type: 'error'
        })
      }
    })
  }
  handleCreateApprovingOfficer() {
    var officers = []
    if (this.state.approvingOfficers !== null) {
      if (this.state.approvingOfficers.length > 0) {
        this.state.approvingOfficers.map((officer) => {
          officers.push(officer)
          this.setState({ selectedEmployees: officers })
        })
      }
    }else{
      this.setState({ selectedEmployees: officers })
    }

    this.setState({ openApprovingOfficerForm: true })
    this.setState({ approvingOfficer: {} })
  }

  handleApprovingOfficers(event, values) {
    this.setState({
      selectedEmployees: values
    })
  }

  loadApprovingOfficers() {

    axios({
      method: 'get',
      url: '/v1/employees/approving_officers',
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {
      this.setState({ approvingOfficers: resp.data })
    }).catch(error => {
      if (error.response.status === 422) {
        this.setState({ error_messages: 'Name ' + error.response.data.errors.name[0] })
      } else {
        this.setState({
          isOpen: true,
          message: error.response.status.toString() + " Unexpected Error Problem Occurred",
          type: 'error'
        })
        this.setState({ openPopup: false })
      }
    })
  }



  loadEmployee(url) {
    var employee = {}
    if (this.props.employeeId !== null && localStorage.getItem('employee') !== null) {
      if (JSON.parse(localStorage.getItem('employee')).is_employee) {
        this.setState({ employee: JSON.parse(localStorage.getItem('employee')) })
        this.setState({ load: true })
      } else {
        axios({
          method: 'get',
          url: url,
          headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
        }).then(resp => {
          this.setState({ employee: resp.data })

          this.setState({ load: true })
        }).catch(() => {
          this.setState({
            isOpen: true,
            message: " Unexpected Error Problem Occurred",
            type: 'error'
          })
        })
      }
    } else {
      console.log('s')
      axios({
        method: 'get',
        url: url,
        headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
      }).then(resp => {
        this.setState({ employee: resp.data })
        employee = resp.data

        axios({
          method: 'get',
          url: '/v1/employees/search?is_active=true&per_page=50000',
          headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
        }).then(resp => {
          this.setState({ employees: resp.data.employees })
          if (employee.approving_officer_ids.length > 0) {
            var officers = []
            employee.approving_officer_ids.map((officer) => {
              resp.data.employees.map((employee) => {
                if (employee.id === officer) {
                  officers.push(employee)
                }
              })
            })
            this.setState({ approvingOfficers: officers })

          }
          this.setState({ load: true })
        }).catch(error => {

          if (error.response) {
            if (error.response.status === 422) {
              this.setState({ error_messages: 'Name ' + error.response.data.errors.name[0] })
            } else {
              this.setState({
                isOpen: true,
                message: error.response.status.toString() + " Unexpected Error Problem Occurred",
                type: 'error'
              })
              this.setState({ openPopup: false })
            }
          }

        })
      }).catch(() => {
        this.setState({
          isOpen: true,
          message: " Unexpected Error Problem Occurred",
          type: 'error'
        })
      })
    }
    if (this.props.employeeId && localStorage.getItem('approvingOfficers')) {
      this.setState({ approvingOfficers: JSON.parse(localStorage.getItem('approvingOfficers')) })
    } else {
      if (this.props.employeeId) {
        this.loadApprovingOfficers()
      }

    }



    if (!this.props.employeeId) {
      axios({
        method: 'get',
        url: '/v1/employment_statuses/kinds',
        headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
      }).then(resp => {
        this.setState({ kinds: resp.data })

      }).catch(error => {
        if (error.response.status === 422) {
          this.setState({ error_messages: 'Name ' + error.response.data.errors.name[0] })
        } else {
          this.setState({
            isOpen: true,
            message: error.response.status.toString() + " Unexpected Error Problem Occurred",
            type: 'error'
          })
          this.setState({ openPopup: false })
        }
      })
      axios({
        method: 'get',
        url: '/v1/employment_statuses/states',
        headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
      }).then(resp => {
        this.setState({ states: resp.data })

      }).catch(error => {
        if (error.response.status === 422) {
          this.setState({ error_messages: 'Name ' + error.response.data.errors.name[0] })
        } else {
          this.setState({
            isOpen: true,
            message: error.response.status.toString() + " Unexpected Error Problem Occurred",
            type: 'error'
          })
          this.setState({ openPopup: false })
        }
      })

      axios({
        method: 'get',
        url: '/v1/salaries/types',
        headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
      }).then(resp => {
        this.setState({ salaryTypes: resp.data })
      }).catch(error => {
        if (error.response.status === 422) {
          this.setState({ error_messages: 'Name ' + error.response.data.errors.name[0] })
        } else {
          this.setState({
            isOpen: true,
            message: error.response.status.toString() + " Unexpected Error Problem Occurred",
            type: 'error'
          })
          this.setState({ openPopup: false })
        }
      })
    }
  }



  handleCreateAttachment() {
    this.setState({ openAttachmentForm: true })

  }

  handleClose() {
    this.setState({ openApprovingOfficerForm: false, openAttendanceDeletePopup: false, openAttendancePopup: false })
    this.setState({ openAttachmentForm: false })
    this.setState({ openSalaryHistoryDelete: false })
    this.setState({ deleteAttachmentPopup: false })
    this.setState({ openSalaryHistoryForm: false })
    this.setState({ openEmploymentForm: false })
    this.setState({ openDeleteEmployment: false })
    this.setState({ openRecurrencePopUp: false })
    this.setState({ openRecurrenceDeletePopup: false })
    this.setState({ openPermissionForm: false })
    this.setState({ openPayrollDetails: false })
  }

  handleAttachmentInputChange(e) {
    this.setState({
      attachment: {
        ...this.state.attachment,
        [e.target.name]: e.target.value
      }
    })
  }

  handleEmploymentChange(e) {
    this.setState({
      employmentStatus: {
        ...this.state.employmentStatus,
        [e.target.name]: e.target.value
      }
    })
  }

  onFileChange(e) {
    e.stopPropagation()
    e.preventDefault()
    this.setState({
      attachment: {
        ...this.state.attachment,
        file: e.target.files[0],
        title: e.target.files[0].name
      }
    })
  }

  handleAttachmentSubmit() {
    const id = this.state.employeeId
    const item = this.state.attachment
    const file = item.file
    const description = item.description
    const formdata = new FormData()
    formdata.append('file', file)
    formdata.append('description', description)
    axios({
      method: 'post',
      url: '/v1/employees/' + id + '/attachments',
      data: (formdata),
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {
      const newData = resp.data
      this.setState({
        attachments: this.state.attachments.filter(attach => attach.id !== item.id)
      })
      this.setState({ attachments: [...this.state.attachments, newData] })

      this.setState({
        isOpen: true,
        message: 'Submitted Successfully',
        type: 'success'
      })
      this.setState({ openAttachmentForm: false })
    }).catch(error => {
      if (error.response.status === 422) {
        this.setState({ error_messages: 'Name ' + error.response.data.errors.name[0] })
      } else {
        this.setState({
          isOpen: true,
          message: error.response.status.toString() + " Unexpected Error Problem Occurred",
          type: 'error'
        })
        this.setState({ openPopup: false })
      }
    })
  }

  handleOpenDeleteAttachment(item) {
    this.setState({ deleteAttachmentPopup: true })
    this.setState({ attachment: item })
  }

  handleDeleteAttachment() {
    const DeleteItemId = this.state.attachment.id
    axios({
      method: 'delete',
      url: '/v1/employees/' + this.state.employee.id + '/attachments/' + DeleteItemId,
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(() => {
      this.setState({
        attachments: this.state.attachments.filter(item => item.id !== DeleteItemId)
      })
      this.setState({ deleteAttachmentPopup: false })
      this.setState({
        isOpen: true,
        message: 'Deleted Successfully',
        type: 'error'
      })
    }).catch(error => {
      if (error.response.status === 422) {
        this.setState({ error_messages: 'Name ' + error.response.data.errors.name[0] })
      } else {
        this.setState({
          isOpen: true,
          message: error.response.status.toString() + " Unexpected Error Problem Occurred",
          type: 'error'
        })
        this.setState({ openPopup: false })
      }
    })
  }

  handleDeleteSalaryHistory() {
    const DeleteItemId = this.state.salaryHistory.id
    axios({
      method: 'delete',
      url: '/v1/employees/' + this.state.employee.id + '/salaries/' + DeleteItemId,
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(() => {
      this.setState({
        salaries: this.state.salaries.filter(item => item.id !== DeleteItemId)
      })
      this.setState({ openSalaryHistoryDelete: false })
      this.setState({
        isOpen: true,
        message: 'Deleted Successfully',
        type: 'error'
      })
    }).catch(error => {
      if (error.response.status === 422) {
        this.setState({ error_messages: 'Name ' + error.response.data.errors.name[0] })
      } else {
        this.setState({
          isOpen: true,
          message: error.response.status.toString() + " Unexpected Error Problem Occurred",
          type: 'error'
        })
        this.setState({ openPopup: false })
      }
    })
  }

  handleSubmitApprovingOfficers() {
    var employee = this.state.employee
    var appOfficers = []
    if (this.state.selectedEmployees.length > 0) {
      this.state.selectedEmployees.map((emp) => { appOfficers.push(emp.id) })
    }
    employee["approving_officer_ids"] = appOfficers
    axios({
      method: 'put',
      url: '/v1/employees/' + this.state.employee.id,
      data: (employee),
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {
      this.setState({ employee: resp.data })

      axios({
        method: 'get',
        url: '/v1/employees/' + this.state.employee.id + '/approving_officers',
        headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
      }).then(resp => {
        this.setState({ approvingOfficers: resp.data })
        this.setState({ openApprovingOfficerForm: false })
      })


      this.setState({
        isOpen: true,
        message: 'Submitted Successfully',
        type: 'success'
      })
    }).catch(error => {
      if (error.response.status === 422) {
        this.setState({ error_messages: 'Name ' + error.response.data.errors.name[0] })
      } else {
        this.setState({
          isOpen: true,
          message: error.response.status.toString() + " Unexpected Error Problem Occurred",
          type: 'error'
        })
        this.setState({ openPopup: false })
      }
    })
  }
  handleCreateorUpdateSalaryHistory(item, isAdd) {
    var textTitle = ''
    if (isAdd) {
      textTitle = 'Create Salary History'
    } else {
      textTitle = 'Edit Salary History'
    }
    this.setState({ openSalaryHistoryForm: true })
    this.setState({ salaryHistory: item })
    this.setState({ salaryFormTitle: textTitle })
  }

  handleSalaryChange(e) {
    this.setState({
      salaryHistory: {
        ...this.state.salaryHistory,
        [e.target.name]: e.target.value
      }
    })
  }

  handleSalaryRateChange(e) {
    this.setState({
      salaryHistory: {
        ...this.state.salaryHistory,
        rate: e.floatValue
      }
    })
  }

  handleSubmitSalary() {
    var item = this.state.salaryHistory
    item["employee_id"] = this.state.employee.id
    var method = ''
    var url = ''
    if (item.id === '') {
      method = 'post'
      url = '/v1/employees/' + this.state.employee.id + '/salaries'
    } else {
      method = 'put'
      url = '/v1/employees/' + this.state.employee.id + '/salaries/' + item.id
    }
    axios({
      method: method,
      url: url,
      data: (item),
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {
      const newData = resp.data
      this.setState({
        salaries: this.state.salaries.filter(salary => salary.id !== item.id)
      })
      this.setState({ salaries: [...this.state.salaries, newData] })
      this.setState({ openSalaryHistoryForm: false })

      this.setState({
        isOpen: true,
        message: 'Submitted Successfully',
        type: 'success'
      })
    }).catch(error => {
      if (error.response.status === 422) {
        this.setState({ error_messages: 'Name ' + error.response.data.errors.name[0] })
      } else {
        this.setState({
          isOpen: true,
          message: error.response.status.toString() + " Unexpected Error Problem Occurred",
          type: 'error'
        })
        this.setState({ openPopup: false })
      }
    })
  }

  handleOpenDeleteSalaryHistory(item) {
    this.setState({ openSalaryHistoryDelete: true })
    this.setState({ salaryHistory: item })
  }

  handleCreateOrUpdateEmploymentStatus(item, isAdd) {
    var textTitle = ''

    if (isAdd) {
      textTitle = 'Create Salary History'
    } else {
      textTitle = 'Edit Salary History'

    }
    this.setState({ openEmploymentForm: true })
    this.setState({ employmentStatus: item })
    this.setState({ employmentTitle: textTitle })
  }
  handleOpenDeleteEmploymentStatus(item) {

    this.setState({ employmentStatus: item })
    this.setState({ openDeleteEmployment: true })
  }

  handleSubmitEmployment() {
    var item = this.state.employmentStatus
    item["employee_id"] = this.state.employee.id
    var method = ''
    var url = ''
    if (item.id === '') {
      method = 'post'
      url = '/v1/employees/' + this.state.employee.id + '/employment_statuses'
    } else {
      method = 'put'
      url = '/v1/employees/' + this.state.employee.id + '/employment_statuses/' + item.id
    }
    axios({
      method: method,
      url: url,
      data: (item),
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {
      const newData = resp.data
      this.setState({
        employmentStatuses: this.state.employmentStatuses.filter(salary => salary.id !== item.id)
      })
      this.setState({ employmentStatuses: [...this.state.employmentStatuses, newData] })
      this.setState({ openEmploymentForm: false })

      this.setState({
        isOpen: true,
        message: 'Submitted Successfully',
        type: 'success'
      })
    }).catch(error => {
      if (error.response.status === 422) {
        this.setState({ error_messages: 'Name ' + error.response.data.errors.name[0] })
      } else {
        this.setState({
          isOpen: true,
          message: error.response.status.toString() + " Unexpected Error Problem Occurred",
          type: 'error'
        })
        this.setState({ openPopup: false })
      }
    })
  }

  handleDeleteEmployment() {
    const DeleteItemId = this.state.employmentStatus.id
    axios({
      method: 'delete',
      url: '/v1/employees/' + this.state.employee.id + '/employment_statuses/' + DeleteItemId,
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(() => {
      this.setState({
        employmentStatuses: this.state.employmentStatuses.filter(item => item.id !== DeleteItemId)
      })
      this.setState({ openDeleteEmployment: false })
      this.setState({
        isOpen: true,
        message: 'Deleted Successfully',
        type: 'error'
      })
    }).catch(error => {
      if (error.response.status === 422) {
        this.setState({ error_messages: 'Name ' + error.response.data.errors.name[0] })
      } else {
        this.setState({
          isOpen: true,
          message: error.response.status.toString() + " Unexpected Error Problem Occurred",
          type: 'error'
        })
        this.setState({ openPopup: false })
      }
    })
  }

  handleSubmitRecurrence() {
    const item = this.state.recurrence
    var method = ''
    var url = '/v1/employees/'
    var empId = ''
    item["account_id"] = this.state.selectedAccount.id
    if (item.id === '' || item.id === undefined) {
      method = 'post'
    } else {
      method = 'put'
    }
    item["employee_ids"] = [this.state.employee.id]
    axios({
      method: method,
      url: url + this.state.employee.id + '/recurrences/' + item.id,
      data: (item),
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {
      const newData = resp.data
      axios({
        method: 'get',
        url: '/v1/employees/' + empId,
        headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
      }).then(resp => {
        newData["employee"] = resp.data
        newData["account"] = this.state.selectedAccount
        this.setState({
          recurrences: this.state.recurrences.filter(recurrence => recurrence.id !== item.id)
        })
        this.setState({ recurrences: [...this.state.recurrences, newData] })
      })
      this.setState({ openRecurrencePopUp: false })
      this.setState({
        isOpen: true,
        message: 'Submitted Successfully',
        type: 'success'
      })
    }).catch(error => {
      if (error.response.status === 422) {
        this.setState({ error_messages: 'Name ' + error.response.data.errors.name[0] })
      } else {
        this.setState({
          isOpen: true,
          message: error.response.status.toString() + " Unexpected Error Problem Occurred",
          type: 'error'
        })
        this.setState({ openPopup: false })
      }
    })
    this.setState({ openPopup: false })
  }

  handleDeleteRecurrence() {
    const DeleteItemId = this.state.recurrence.id
    axios({
      method: 'delete',
      url: '/v1/employees/' + this.state.recurrence.employee.id + '/recurrences/' + DeleteItemId,
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(() => {
      this.setState({
        recurrences: this.state.recurrences.filter(item => item.id !== DeleteItemId)
      })
      this.setState({ openRecurrenceDeletePopup: false })
      this.setState({
        isOpen: true,
        message: 'Deleted Successfully',
        type: 'error'
      })
    }).catch(error => {
      if (error.response.status === 422) {
        this.setState({ error_messages: 'Name ' + error.response.data.errors.name[0] })
      } else {
        this.setState({
          isOpen: true,
          message: error.response.status.toString() + " Unexpected Error Problem Occurred",
          type: 'error'
        })
        this.setState({ openPopup: false })
      }
    })
  }

  handleCreateorUpdateRecurrence(item, isAdd, model) {
    var textTitle = ''
    var selectedEmployees = []
    var account = {}
    var employee = {}
    if (isAdd) {
      textTitle = 'Create ' + model
    } else {
      textTitle = 'Edit ' + model
      account = item.account
      employee = item.employee
      this.setState({ selectedAccount: account })
    }
    this.setState({ selectedRecurrenceEmployee: employee })
    this.setState({ selectedRecurrenceEmployees: selectedEmployees })
    this.setState({ openRecurrencePopUp: true })
    this.setState({ recurrence: item })
    this.setState({ recurrenceTitle: textTitle })
  }

  handleRecurrenceChange(e) {
    if (e.target.name === "loan_date") {
      this.setState({
        recurrence: {
          ...this.state.recurrence,
          [e.target.name]: e.target.value,
          date_of_effect: e.target.value
        }
      })
    } else {
      this.setState({
        recurrence: {
          ...this.state.recurrence,
          [e.target.name]: e.target.value
        }
      })
    }
  }

  handleOpenDeleteRecurrence(item) {
    this.setState({ model: 'Recurrence' })
    this.setState({ recurrence: item })
    this.setState({ openRecurrenceDeletePopup: true })
  }

  handleRecurrenceAutoComplete(event, values) {
    if (this.state.recurrence.id !== undefined && this.state.recurrence.id !== "") {
      this.setState({ selectedRecurrenceEmployee: values })
    } else {
      this.setState({ selectedRecurrenceEmployees: values })
    }
  }

  handleRecurrenceAccount(event, values) {
    this.setState({ selectedAccount: values })
  }

  handleNumberRecurrenceChange(e) {
    this.setState({
      recurrence: {
        ...this.state.recurrence,
        principal_amount: e.value
      }
    })
  }

  handleAmountRecurrenceChange(e) {
    this.setState({
      recurrence: {
        ...this.state.recurrence,
        payable_amount: e.value
      }
    })
  }

  handleCheckRecurrence(e) {
    this.setState({
      recurrence: {
        ...this.state.recurrence,
        active: e.target.checked
      }
    })
  }

  handleClickPermission() {
    this.setState({ selectedEmployeePermissions: this.state.employeePermissions })
    this.setState({ openPermissionForm: true })
  }

  handleSubmitPermission() {
    var employee = this.state.employee
    var permissions = []
    if (this.state.selectedEmployeePermissions.length > 0) {
      this.state.selectedEmployeePermissions.map((permission) => { permissions.push(permission.id) })
    }
    employee["permission_ids"] = permissions
    axios({
      method: 'put',
      url: '/v1/employees/' + this.state.employee.id,
      data: (employee),
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {
      this.setState({ employee: resp.data })
      axios({
        method: 'get',
        url: '/v1/employees/' + this.state.employee.id + '/permissions',
        headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
      }).then(resp => {
        this.setState({ employeePermissions: resp.data })
      })
      this.setState({ openPermissionForm: false })

      this.setState({
        isOpen: true,
        message: 'Submitted Successfully',
        type: 'success'
      })
    }).catch(error => {
      if (error.response.status === 422) {
        this.setState({ error_messages: 'Name ' + error.response.data.errors.name[0] })
      } else {
        this.setState({
          isOpen: true,
          message: error.response.status.toString() + " Unexpected Error Problem Occurred",
          type: 'error'
        })
        this.setState({ openPopup: false })
      }
    })
  }

  handlePermissionAutoComplete(event, values) {
    this.setState({ selectedEmployeePermissions: values })
  }

  rowClick(item) {
    this.setState({ memo: item })
    this.setState({ redirectToMemoShow: true })
  }

  handleShowPopUpPayroll(item) {

    this.setState({ payrollEmployee: item })
    axios({
      method: 'get',
      url: '/v1/payroll_details/' + item.id + '/payroll_adjustments/',
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {
      this.setState({ payrollAdjustment: resp.data })
      this.setState({ openPayrollDetails: true })
    }).catch(error => {
      if (error.response.status === 422) {
        this.setState({ error_messages: 'Name ' + error.response.data.errors.name[0] })
      } else {
        this.setState({
          isOpen: true,
          message: error.response.status.toString() + " Unexpected Error Problem Occurred",
          type: 'error'
        })
        this.setState({ openPopup: false })
      }
    })


  }

  handleCloseAlert(event, reason) {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ isOpen: false })
  }

  handleCreateOrUpdateAttendance(item, isAdd) {
    var title = ""
    if (isAdd) {
      title = "Create Attendance"
      this.setState({ openAttendancePopup: true, attendance: item, attendanceTitle: title })
    } else {
      title = "Update Attendance"
      item['time_in'] = moment(item['time_in']).format('HH:mm')
      item['time_out'] = moment(item['time_out']).format('HH:mm')
      this.setState({ employee: this.state.employee })
      this.setState({ openAttendancePopup: true, attendance: item, attendanceTitle: title })
    }
  }

  handleAttendanceChange(e) {
    this.setState({ attendance: { ...this.state.attendance, [e.target.name]: e.target.value } })
  }

  handleSubmitAttendance() {
    var item = this.state.attendance
    var method = ""
    var url = ""

    if (!item.id) {
      method = "post",
        url = "/v1/employees/" + this.state.employee.id + "/attendances"
    } else {
      method = "put",
        url = "/v1/employees/" + this.state.employee.id + "/attendances/" + item.id
    }
    axios({
      method: method,
      url: url,
      data: item,
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then((resp) => {
      var newData = resp.data
      this.setState({ attendances: this.state.attendances.filter(attendance => attendance.id !== newData.id) })
      this.setState({ attendances: [...this.state.attendances, newData].sort((a,b)=> new Date(b.date_worked) - new Date(a.date_worked)) })
      this.setState({
        isOpen: true,
        message: 'Submitted Successfully',
        type: 'success'
      })
      this.setState({ openAttendancePopup: false })
    })
      .catch(error => { console.log(error) })
  }

  handleOpenDeleteAttendance(item) {
    this.setState({ openDeletePopup: true, attendance: item })
  }

  handleDeleteAttendance() {
    var item = this.state.attendance
    axios({
      method: 'delete',
      url: '/v1/employees/' + this.state.employee.id + '/attendances/' + item.id,
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(() => {
      this.setState({ attendances: this.state.attendances.filter(attendance => attendance.id !== item.id) })
      this.setState({ isOpen: true, message: 'Deleted Successfully', type: 'success' })
      this.setState({ openAttendanceDeletePopup: false })
    }).catch(error => { this.setState({ isOpen: true, message: error.response.status.toString() + " Unexpected Error Problem Occurred", type: 'error' }) })

  }

  render() {
    const profile = this.props.employeeId ? this.state.employee : this.state.employee.profile_attributes
    const employmentStatus = this.state.employee.employment_status
    const emp = this.state.employee

    if (this.state.redirectToMemoShow) {
      return (<Redirect to={{
        pathname: '/memos/' + this.state.memo.id
      }} />)
    }

    return (
      <>
        {
          this.state.load ? (
            <div>
              <Grid container spacing={1}>
                <Grid item xs={8}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={3} className={styles.headerText}><b> Employee No </b></Grid>
                    <Grid item xs={12} sm={9} className={styles.headerText}>: {profile.reference_no}</Grid>
                    <Grid item xs={12} sm={3} className={styles.headerText}><b> Employee Name </b></Grid>
                    <Grid item xs={12} sm={9} className={styles.headerText}>: {profile.name}</Grid>
                  </Grid>
                </Grid>
                <Grid item xs={0} sm={2} />
                <Grid item xs={4} sm={2}>
                  <Avatar
                    className={styles.avatar}
                    variant="square"
                    src={this.state.employee.image.default}
                    alt=""
                  />
                </Grid>
              </Grid>
              <Tabs
                value={this.state.value}
                onChange={this.handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                className={localStorage.getItem('openSideNav') === 'false' ? styles.openSidenav : styles.tabHead}
              >
                <Tab label="Profile" {...a11yProps(0)} />
                <Tab label="201 Files" {...a11yProps(1)} />
                <Tab label="Payslips" {...a11yProps(2)} />
                <Tab label="Salary History" {...a11yProps(3)} />
                <Tab label="Employee Status" {...a11yProps(4)} />
                <Tab label="Attendances" {...a11yProps(5)} />
                <Tab label="Recurrences" {...a11yProps(6)} />
                <Tab label="Memos" {...a11yProps(7)} />
                {this.props.employeeId ?
                  null
                  : <Tab label="Permissions" {...a11yProps(8)} />}
              </Tabs>
              <TabPanel className={localStorage.getItem('openSideNav') === 'false' ? styles.openSidenavTabBody : styles.tabBodyEmployee} value={this.state.value} index={0}>
                <>
                  <Grid container spacing={1} alignItems="center" style={{ borderBottom: '1px solid' }}>
                    <Grid item xs={6} sm={6} style={{ fontSize: '18px' }}><b> Personal Information </b></Grid>
                    <Grid item xs={6} sm={6} className={styles.buttonGroup}>
                      <IconButton color="primary" size="small">
                        <Link to={{
                          pathname: `/employees`,
                        }}>
                          <ArrowBackIosIcon fontSize="small" />
                        </Link>
                      </IconButton>
                      <IconButton color="primary" size="small">
                        <Link to={{
                          pathname: `/employees/${this.state.employee.id}/edit`,
                        }}>
                          <EditIcon fontSize="small" />
                        </Link>
                      </IconButton>
                      <IconButton
                        color="secondary"
                        onClick={this.handleOpenDeletePopup}
                      >
                        {
                          this.state.employee.can_delete ? (
                            <DeleteIcon />
                          ) : null
                        }
                      </IconButton>

                    </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item xs={6} sm={3} className={styles.labelText}><b> Date of birth </b></Grid>
                    <Grid item xs={6} sm={3} className={styles.labelText}>:&emsp; {profile.birth_date ? moment(profile.birth_date).format('L') : ''}</Grid>
                    <Grid item xs={6} sm={3} className={styles.labelText}><b> SSS No. </b></Grid>
                    <Grid item xs={6} sm={3} className={styles.labelText}>:&emsp; {profile.sss_no ? profile.sss_no : ''}</Grid>

                    <Grid item xs={6} sm={3} className={styles.labelText}><b> Gender </b></Grid>
                    <Grid item xs={6} sm={3} className={styles.labelText}>:&emsp; {profile.gender}</Grid>
                    <Grid item xs={6} sm={3} className={styles.labelText}><b> Pag-IBIG No. </b></Grid>
                    <Grid item xs={6} sm={3} className={styles.labelText}>:&emsp; {profile.pagibig_no ? profile.pagibig_no : ''}</Grid>

                    <Grid item xs={6} sm={3} className={styles.labelText}><b> Email </b></Grid>
                    <Grid item xs={6} sm={3} className={styles.emailText}>:&emsp; {this.state.employee.email ? this.state.employee.email : ''}</Grid>
                    <Grid item xs={6} sm={3} className={styles.labelText}><b> TIN No. </b></Grid>
                    <Grid item xs={6} sm={3} className={styles.labelText}>:&emsp; {profile.tin_no ? profile.tin_no : ''}</Grid>

                    <Grid item xs={6} sm={3} className={styles.labelText}><b> Civil Status </b></Grid>
                    <Grid item xs={6} sm={3} className={styles.labelText}>:&emsp; {profile.civil_status ? profile.civil_status : ''}</Grid>
                    <Grid item xs={6} sm={3} className={styles.labelText}><b> PhilHealth No. </b></Grid>
                    <Grid item xs={6} sm={3} className={styles.labelText}>:&emsp; {profile.philhealth_no ? profile.philhealth_no : ''}</Grid>

                    <Grid item xs={6} sm={3} className={styles.labelText}><b> Contact No. </b></Grid>
                    <Grid item xs={6} sm={9} className={styles.labelText}>:&emsp; {profile.mobile_no ? profile.mobile_no : ''}</Grid>
                    <Grid item xs={6} sm={3} className={styles.labelText}><b> Address </b></Grid>
                    <Grid item xs={6} sm={9} className={styles.labelText}>:&emsp; {profile.address ? profile.address : ''}</Grid>

                    <Grid item xs={12} style={{ fontSize: '18px', borderBottom: '1px solid' }}><b> Employment Details </b></Grid>
                    <Grid item xs={6} sm={3} className={styles.labelText}><b> Bank Account Number </b></Grid>
                    <Grid item xs={6} sm={3} className={styles.labelText}>:&emsp; {profile.bank_account_no ? profile.bank_account_no : ''}</Grid>
                    <Grid item xs={6} sm={3} className={styles.labelText}><b> Branch </b></Grid>
                    <Grid item xs={6} sm={3} className={styles.labelText}>:&emsp; {emp.branch ? (emp.branch.name) : ("")}</Grid>
                    <Grid item xs={6} sm={3} className={styles.labelText}><b> Current Salary </b></Grid>
                    <Grid item xs={6} sm={3} className={styles.labelText}>:&emsp; {this.state.employee.salary ? parseFloat(this.state.employee.salary.rate).toLocaleString(navigator.language, { minimumFractionDigits: 2 }) : '0.0'}</Grid>
                    <Grid item xs={6} sm={3} className={styles.labelText}><b> Department </b></Grid>
                    <Grid item xs={6} sm={3} className={styles.labelText}>:&emsp; {emp.department ? (emp.department.name) : ("")}</Grid>
                    <Grid item xs={6} sm={3} className={styles.labelText}><b> Date Hired </b></Grid>
                    <Grid item xs={6} sm={3} className={styles.labelText}>:&emsp; {moment(this.state.employee.date_hired).format('L')}</Grid>
                    <Grid item xs={6} sm={3} className={styles.labelText}><b> Division </b></Grid>
                    <Grid item xs={6} sm={3} className={styles.labelText}>:&emsp; {emp.division ? (emp.division.name) : ("")}</Grid>
                    <Grid item xs={6} sm={3} className={styles.labelText}><b> Employment Status </b></Grid>
                    <Grid item xs={6} sm={3} className={styles.labelText}>:&emsp; {employmentStatus ? employmentStatus.kind : null}</Grid>
                    <Grid item xs={6} sm={3} className={styles.labelText}><b> Position </b></Grid>
                    <Grid item xs={6} sm={3} className={styles.labelText}>:&emsp; {emp.position ? (emp.position.name) : ("")}</Grid>
                    <Grid item xs={6} sm={3} />
                    <Grid item xs={6} sm={3} />
                    <Grid item xs={6} sm={3} className={styles.labelText}><b> Tax Exempted </b></Grid>
                    <Grid item xs={6} sm={3} className={styles.labelText}>:&emsp; {profile.is_tax_exempted ? ("Yes") : ("No")}</Grid>
                  </Grid>
                  <SimpleTable
                    items={this.state.approvingOfficers}
                    model="Approving Officer"
                    headers={['officer.name']}
                    handleClick={this.handleCreateApprovingOfficer}
                    withAdd={this.props.employeeId ? false : true}
                    noActionHeader={true}
                  />
                </>
              </TabPanel>
              <TabPanel className={localStorage.getItem('openSideNav') === 'false' ? styles.openSidenavTabBody : styles.tabBody} value={this.state.value} index={1}>
                <SimpleTable
                  items={this.state.attachments}
                  model="Salary History"
                  noActionHeader={true}
                  headers={['file_name', 'description']}
                  withAdd={!this.props.employeeId}
                  handleClick={this.handleCreateAttachment}
                  onOpenDeletePopup={this.handleOpenDeleteAttachment}

                />
              </TabPanel>
              <TabPanel className={localStorage.getItem('openSideNav') === 'false' ? styles.openSidenavTabBody : styles.tabBody} value={this.state.value} index={2}>
                <SimpleTable
                  items={this.state.payrollDetails.sort((a, b) => { return new Date(b.payroll.date_to) - new Date(a.payroll.date_to) })}
                  model="Employee Payroll"
                  noActionHeader={true}
                  headers={['payroll_period', 'payroll_income', 'gross_income', 'payroll_deductions', 'net_pay']}
                  nodelete={true}
                  withShowPopUp={true}
                  handleShowPopUp={this.handleShowPopUpPayroll}
                  withAdd={!this.props.employeeId}
                />
              </TabPanel>
              <TabPanel className={localStorage.getItem('openSideNav') === 'false' ? styles.openSidenavTabBody : styles.tabBody} value={this.state.value} index={3}>
                <SimpleTable
                  items={this.state.salaries.sort(
                    (a, b) => new Date(a.date_effective).getTime() - new Date(b.date_effective).getTime()
                  ).reverse()}
                  model="Salary History"
                  noActionHeader={true}
                  withEdit={true}
                  headers={['date_effective', 'remarks', 'salary_type', 'rate']}
                  initialItem={{ id: '', date_effective: '', rate: '', remarks: '', salary_type: '' }}
                  handleClick={this.handleCreateorUpdateSalaryHistory}
                  onOpenDeletePopup={this.handleOpenDeleteSalaryHistory}
                  withAdd={!this.props.employeeId}
                />
              </TabPanel>
              <TabPanel className={localStorage.getItem('openSideNav') === 'false' ? styles.openSidenavTabBody : styles.tabBody} value={this.state.value} index={4}>

                <SimpleTable
                  items={this.state.employmentStatuses.sort(
                    (a, b) => new Date(a.date_effective).getTime() - new Date(b.date_effective).getTime()
                  ).reverse()}
                  model="Employment Status"
                  noActionHeader={true}
                  withEdit={true}

                  initialItem={{ id: '', date_effective: '', rate: '', remarks: '', kind: '' }}
                  headers={['date_effective', 'kind', 'remarks', 'state']}
                  handleClick={this.handleCreateOrUpdateEmploymentStatus}
                  onOpenDeletePopup={this.handleOpenDeleteEmploymentStatus}
                  withAdd={!this.props.employeeId}
                />
              </TabPanel>
              <TabPanel className={localStorage.getItem('openSideNav') === 'false' ? styles.openSidenavTabBody : styles.tabBody} value={this.state.value} index={5}>
                <SimpleTable
                  items={this.state.attendances}
                  model="Attendance"
                  noActionHeader={true}

                  withEdit={true}
                  headers={['date_worked', 'time_in', 'time_out']}
                  withAdd={true}
                  handleClick={this.handleCreateOrUpdateAttendance}
                  initialItem={this.state.initialAttendance}
                  onOpenDeletePopup={this.handleOpenDeleteAttendance}

                />
              </TabPanel>
              <TabPanel className={localStorage.getItem('openSideNav') === 'false' ? styles.openSidenavTabBody : styles.tabBody} value={this.state.value} index={6}>
                <SimpleTable
                  items={this.state.recurrences}
                  model="Recurrence"
                  noActionHeader={true}
                  withEdit={true}

                  headers={['account.name', "period", "payable_amount", "principal_amount", "loan_date", "maturity_date", "date_of_effect", "active"]}
                  initialItem={{ id: '', active: true, period: '' }}
                  handleClick={this.handleCreateorUpdateRecurrence}
                  onOpenDeletePopup={this.handleOpenDeleteRecurrence}
                  withAdd={!this.props.employeeId}
                />
              </TabPanel>
              <TabPanel className={localStorage.getItem('openSideNav') === 'false' ? styles.openSidenavTabBody : styles.tabBodyMemo} value={this.state.value} index={7}>
                <AnnouncementTable
                  items={this.state.memos}
                  withOutActions={true}
                  rowClick={this.rowClick}
                  withAdd={!this.props.employeeId}
                />
              </TabPanel>

              {this.props.employeeId ? null :
                <TabPanel className={localStorage.getItem('openSideNav') === 'false' ? styles.openSidenavTabBody : styles.tabBody} value={this.state.value} index={8}>
                  <SimpleTable
                    items={this.state.employeePermissions}
                    model="Permissions"
                    noActionHeader={true}
                    headers={['name']}
                    withEdit={false}
                    handleClick={this.handleClickPermission}
                    withAdd={!this.props.employeeId}
                  />
                </TabPanel>
              }
              <SimplePopUp
                openPopup={this.state.openApprovingOfficerForm}
                title="Update Approving Officers"
                handleClose={this.handleClose}
                maxWidth={this.state.sm}
              >
                <ApprovingOfficerForm
                  handleApprovingOfficers={this.handleApprovingOfficers}
                  submit={this.handleSubmitApprovingOfficers}
                  employees={this.state.employees}
                  selectedEmployees={this.state.selectedEmployees}
                  handleAutocomplete={this.handleAutocomplete}

                />
              </SimplePopUp>

              <SimplePopUp
                openPopup={this.state.openAttachmentForm}
                title="Add 201 File"
                handleClose={this.handleClose}
                maxWidth={this.state.sm}
              >
                <AttachmentForm
                  attachment={this.state.attachment}
                  item={this.state.attachment}
                  onFileChange={this.onFileChange}
                  submit={this.handleAttachmentSubmit} onchange={this.handleAttachmentInputChange} />
              </SimplePopUp>
              <SimpleDeletePopUp
                openDeletePopup={this.state.deleteAttachmentPopup}
                item={this.state.attachment}
                delete={this.handleDeleteAttachment}
                handleDeleteClose={this.handleClose}
                model="Attachment"
              />
              <SimplePopUp
                openPopup={this.state.openSalaryHistoryForm}
                title={this.state.salaryFormTitle}
                handleClose={this.handleClose}
                maxWidth="sm"
              >
                <SalaryHistoryForm
                  salaries_attributes={this.state.salaryHistory}
                  submit={this.handleSubmitSalary}
                  handleSalaryChange={this.handleSalaryChange}
                  handleRateChange={this.handleSalaryRateChange}
                  salaryTypes={this.state.salaryTypes}
                />
              </SimplePopUp>
              <SimpleDeletePopUp
                openDeletePopup={this.state.openSalaryHistoryDelete}
                item={this.state.salaryHistory}
                delete={this.handleDeleteSalaryHistory}
                handleDeleteClose={this.handleClose}
                model="Salary History"
              />
              <SimplePopUp
                openPopup={this.state.openEmploymentForm}
                title={this.state.employmentTitle}
                handleClose={this.handleClose}
                maxWidth="sm"
              >
                <EmploymentStatusForm
                  handleEmploymentChange={this.handleEmploymentChange}
                  submit={this.handleSubmitEmployment}
                  employment_statuses_attributes={this.state.employmentStatus}
                  kinds={this.state.kinds}
                  states={this.state.states}

                />
              </SimplePopUp>
              <SimpleDeletePopUp
                openDeletePopup={this.state.openDeleteEmployment}
                item={this.state.employmentStatus}
                delete={this.handleDeleteEmployment}
                handleDeleteClose={this.handleClose}
                model="Employment Status"
              />
              <SimplePopUp
                openPopup={this.state.openRecurrencePopUp}
                title={this.state.recurrenceTitle}
                items={this.state.recurrences}
                handleClose={this.handleClose}
                maxWidth="md"
              >
                <RecurrenceForm
                  error={this.state.error_messages}
                  item={this.state.recurrence}
                  submit={this.handleSubmitRecurrence}
                  handleChange={this.handleRecurrenceChange}
                  employees={this.state.employees}
                  selectedEmployees={this.state.selectedRecurrenceEmployees}
                  selectedEmployee={this.state.selectedRecurrenceEmployee}
                  handleAutocomplete={this.handleRecurrenceAutoComplete}
                  handleCheck={this.handleCheckRecurrence}
                  handleAmountChange={this.handleAmountRecurrenceChange}
                  handleNumberChange={this.handleNumberRecurrenceChange}
                  accounts={this.state.accounts}
                  selectedAccount={this.state.selectedAccount}
                  handleAccount={this.handleRecurrenceAccount}
                  withoutEmployeeField={true}
                />
              </SimplePopUp>

              <SimpleDeletePopUp
                openDeletePopup={this.state.openRecurrenceDeletePopup}
                item={this.state.recurrence}
                delete={this.handleDeleteRecurrence}
                handleDeleteClose={this.handleClose}
                model="Recurrence"
              />
              <SimplePopUp
                openPopup={this.state.openPermissionForm}
                title="Update Permissions"
                items={this.state.recurrences}
                handleClose={this.handleClose}
                maxWidth="sm"
              >
                <div>
                  <form>
                    <Autocomplete
                      value={this.state.selectedEmployeePermissions}
                      id="permissions"
                      multiple
                      name="permissions"
                      fullWidth
                      size="small"
                      options={(this.state.permissions).sort(function (a, b) {
                        if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
                        if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
                        return 0;
                      })}
                      getOptionLabel={(option) => option.name || ''}
                      getOptionSelected={(option, value) => option.name === value.name}
                      onChange={this.handlePermissionAutoComplete}
                      renderInput={(params) => (
                        <TextField {...params} name="permissions" variant="outlined" label="Permissions" />
                      )}
                    />
                  </form>
                  <br />
                  <div className={styles.actionButton}>
                    <Button variant="outlined" id="ApprovingOfficer-submit" color="primary" onClick={() => this.handleSubmitPermission()} >Save</Button>
                  </div>
                </div>
              </SimplePopUp>
              <SimplePopUp
                openPopup={this.state.openPayrollDetails}
                title={this.state.employee.name_formal + ' / ' + this.state.employee.reference_no}
                handleClose={this.handleClose}
                maxWidth={this.state.maxWidth}
              >
                <PayrollEmployeeInfo
                  payrollList={this.state.payrollEmployee.payroll}
                  item={this.state.payrollEmployee}
                  payrollAdjustment={this.state.payrollAdjustment}
                />
              </SimplePopUp>
              <SimplePopUp
                openPopup={this.state.openAttendancePopup}
                title={this.state.attendanceTitle}
                items={this.state.attendances}
                handleClose={this.handleClose}
                maxWidth="sm"
              >
                <AttendanceForm
                  item={this.state.attendance}
                  handleChange={this.handleAttendanceChange}
                  employees={this.state.employees}
                  submit={this.handleSubmitAttendance}
                  employee={this.state.employee}
                  employeeView={true}

                />
              </SimplePopUp>
              <SimpleDeletePopUp
                openDeletePopup={this.state.openAttendanceDeletePopup}
                item={this.state.attendance}
                delete={this.handleDeleteAttendance}
                handleDeleteClose={this.handleClose}
                model={this.state.model}
              />
              <AlertMessage
                notify={this.state.notify}
                handleCloseAlert={this.handleCloseAlert}
                isOpen={this.state.isOpen}
                type={this.state.type}
                message={this.state.message}
              />
            </div>
          ) : (
            <ProgressBar model={this.state.model} />
          )}
      </>
    )
  }
}

export default EmployeeShow
EmployeeShow.propTypes = {
  employeeId: PropTypes.number,
  location: PropTypes.object,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired
    })
  })
}
