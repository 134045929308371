// eslint-disable-next-line
import React from 'react'
import axios from 'axios'
import PropTypes from 'prop-types'
import ProgressBar from "../../shared/ProgressBar/ProgressBar";
import LoanReportSearchForm from './LoanReportSearchForm'
import SimpleTableGroupBy from '../../shared/SimpleTable/SimpleTableGroupBy'
import { withRouter } from 'react-router-dom'
import moment from 'moment'
import PaymentOutlinedIcon from '@material-ui/icons/PaymentOutlined';
export const Component = withRouter(() => {

})
class LoanReports extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      model: 'Loan Report',
      loanReports: [],
      loanReport: {},
      employees: [],
      selectedEmployees: [],
      title: '',
      load: false,
      tableHead: ['account_name', 'loan_date', 'maturity_date', 'principal_amount', 'loan_payment', 'balance'],
      withShow: false,
      withEdit: true,
      groupLabel: 'Employee Name',
      mdWidth: 'md',
      error_messages: '',
      notify: {},
      isOpen: false,
      message: '',
      type: '',
      redirectToShow: false,
      searchLoanReport: { employees: [] },
      totalPages: 1,
      currentPage: 1,
      withPagination: true,
      totalResults: 0,
      withResultsLabel: false,
      payrollAccounts: [],
      urlPrint: {},
      searchUrl: '/v1/payroll_adjustments/search',

    }
    this.handleCloseAlert = this.handleCloseAlert.bind(this)
    this.handleAutocomplete = this.handleAutocomplete.bind(this)
    this.handleSearchInput = this.handleSearchInput.bind(this)
    this.search = this.search.bind(this)
    this.handleKeyPress = this.handleKeyPress.bind(this)
    this.handlePageChange = this.handlePageChange.bind(this)
    this.clearSearch = this.clearSearch.bind(this)
    this.handleAccounts = this.handleAccounts.bind(this)
  }

  componentDidMount() {
    var searchUrl = this.state.searchUrl + this.props.location.search
    var searchParams = {}
    const params = new URLSearchParams(this.props.location.search)
    // This is to load all params based on URLSearchParams
    params.forEach(function (value, key) {
      if (key !== 'page') {
        if (key === 'name' || key === 'content' || key === 'title') {
          searchParams[key] = value.replace(/\*/g, '')
        } else if (key === 'account_id') {
          axios({
            method: 'get',
            url: '/v1/payroll_accounts/' + value,
            headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
          }).then(resp => {
            searchParams["payrollAccount"] = resp.data
          })
        }
        else if (key === 'employee_ids') {
          var employee_ids = value.split(',')
          var employees = []
          axios({
            method: 'get',
            url: '/v1/employees/search?is_active=true&per_page=50000',
            headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
          }).then(resp => {
            employee_ids.forEach(employee_id => {
              employees.push(resp.data.employees.filter(emp => emp.id.toString() === employee_id)[0])
            })
            searchParams["employees"] = employees
          })
        }
        else {
          searchParams[key] = value
        }
      }
    })
    this.setState({ searchLoanReport: searchParams })
    this.loadLoanReports(searchUrl, this.props.location.search)
  }

  loadLoanReports(url, search) {
    if (search) {
      axios({
        method: 'get',
        url: url,
        headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
      }).then(resp => {
        const newItems = []
        resp.data.payroll_adjustments.map((payroll) => {
          var item = {}
          item = payroll
          if (payroll.recurrence_id !== null) {
            axios({
              method: 'get',
              url: '/v1/recurrences/' + payroll.recurrence_id,
              headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
            }).then(resp => {
              item["recurrence"] = resp.data
            }).catch(error => console.log(error.response))
          }
          newItems.push(item)
        })
        this.setState({ loanReports: newItems })
        this.setState({ urlPrint: resp.data.meta })
        this.setState({ currentPage: resp.data.meta.current_page })
        this.setState({ totalResults: resp.data.meta.total_count })
        this.setState({ totalPages: resp.data.meta.total_pages })

      }).catch(error => console.log(error.response))
    }
    axios({
      method: 'get',
      url: '/v1/employees/search?is_active=true&per_page=50000',
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {
      this.setState({ employees: resp.data.employees })
    }).catch(error => console.log(error.response))
    if (localStorage.getItem('payroll_accounts')) {
      this.setState({ payrollAccounts: JSON.parse(localStorage.getItem('payroll_accounts')) })
    } else {
      axios({
        method: 'get',
        url: '/v1/payroll_accounts',
        headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
      }).then(resp => {
        this.setState({ payrollAccounts: resp.data })
        localStorage.setItem('payroll_accounts', JSON.stringify(resp.data))
      }).catch(error => console.log(error.response))
    }
    this.setState({ load: true })
  }


  handleCloseAlert(event, reason) {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ isOpen: false })
  }
  handleAutocomplete(event, values) {
    this.setState({ selectedEmployees: values })
  }


  handleSearchInput(e) {
    if (e.target.name === "start_date") {
      var dateStart = moment(e.target.value).format('YYYY-MM-DD')
      var lastDay = moment(dateStart).endOf('month').format('YYYY-MM-DD')
      this.setState({
        searchLoanReport: {
          ...this.state.searchLoanReport,
          [e.target.name]: e.target.value,
          end_date: lastDay
        }
      })
    }
    else {
      this.setState({
        searchLoanReport: {
          ...this.state.searchLoanReport,
          [e.target.name]: e.target.value
        }
      })
    }
  }

  search() {
    this.setState({ load: false })
    const { history } = this.props
    var searchParams = this.getSearchParams().join("&")
    const params = new URLSearchParams()
    params.append("/search", searchParams)
    history.push({ search: searchParams })
    this.loadLoanReports([this.state.searchUrl, searchParams].join("?"), searchParams ? true : false)
  }

  getSearchParams() {
    var searchParams = []
    // This is to load all params based on searchParams state.
    Object.entries(this.state.searchLoanReport).map(([key, value]) => {
      if (value != '' && value != undefined && value != null) {
        if (key === "employees" && value.length !== null && value.length > 0) {
          var employee_ids = []
          value.map(employee => {
            employee_ids.push(employee.id)
          })
          searchParams.push('employee_ids=' + employee_ids.join(","))
        }
        else if (key === "payrollAccount" && value.id !== null && value.id !== undefined) {
          searchParams.push('account_id' + "=" + value.id)

        } else {
          searchParams.push([key, value].join("="))
        }
      }
    })

    return searchParams
  }

 
  handlePageChange(event, value) {
    var searchParams = this.getSearchParams()
    searchParams.push(["page", value].join("="))
    searchParams = searchParams.join("&")

    const { history } = this.props
    const params = new URLSearchParams()

    params.append("/search", searchParams)
    history.push({ search: searchParams })
    this.setState({ currentPage: value })
    this.loadMemos([this.state.searchUrl, searchParams].join("?"))
  }


  clearSearch() {
    this.setState({ searchLoanReport: { start_date: '', end_date: '', payrollAccount: null, employees: [] } })
    const { history } = this.props
    history.push({ search: '' })
    this.setState({ withResultsLabel: false })
    this.setState({ loanReports: [], urlPrint: {}, currentPage: 1, totalPages: 1, totalResults: 0 })
  }
  handleKeyPress(e) {
    if (e.key === 'Enter') {
      this.search()
    }
  }

  handleAccounts(event, values) {
    if (event.target.id.includes("payrollAccounts")) {
      this.setState({
        searchLoanReport: {
          ...this.state.searchLoanReport,
          payrollAccount: values
        }
      })
    } else {
      this.setState({
        searchLoanReport: {
          ...this.state.searchLoanReport,
          employees: values
        }
      })
    }

  }

  render() {
    function groupArrayOfObjects(list, key) {
      return list.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    }
    var dataArray = this.state.loanReports.filter(item => item.payroll_account.is_deduction === true)
    var clientsGroupBy = groupArrayOfObjects(dataArray, "name");
    return (
      <>
        {
          this.state.load ? (
            <div>
              <LoanReportSearchForm
                employees={this.state.employees}
                item={this.state.searchLoanReport}
                handleChange={this.handleSearchInput}
                search={this.search}
                clearSearch={this.clearSearch}
                handleKeyPress={this.handleKeyPress}
                handleAutocomplete={this.handleAccounts}
                payrollAccounts={this.state.payrollAccounts}

              />

              <SimpleTableGroupBy
                initialItem={this.state.initialProduct}
                items={clientsGroupBy}
                model={this.state.model}
                onOpenDeletePopup={this.handleOpenDeletePopup}
                headers={this.state.tableHead}
                withShowPopUp={this.state.withShowPopUp}
                handleShowPopUp={this.handleShowPopUp}
                withEdit={this.state.withEdit}
                withPagination={true}
                currentPage={this.state.currentPage}
                totalPages={this.state.totalPages}
                handlePageChange={this.handlePageChange}
                groupLabel={this.state.groupLabel}
                loopedHeaders={true}
                urlPrint={this.state.urlPrint}
                withPrint={true}
                icon={<PaymentOutlinedIcon />}

              />
            </div>
          ) : (
            <ProgressBar model={this.state.model} />
          )}
      </>
    )
  }
}

export default withRouter(LoanReports)
LoanReports.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object
}
