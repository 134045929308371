import React from 'react'
import styles from './Branches.module.css'

import PropTypes from 'prop-types'

import {
  TextField,
  Button, Grid,IconButton,Tooltip
} from '@material-ui/core'
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";

import AddBoxIcon from '@material-ui/icons/AddBox'
class BranchDepartmentForm extends React.Component {
  render() {
    console.log(this.props.selectedDepartment)
    return (
      <div>
        <form>
          <Grid container spacing={1}>
            <Grid item xs={11}>
              <Autocomplete
              value={this.props.selectedDepartment}
              className={styles.textFields}
              id="account_id"
              size="small"
              fullWidth
              name="account_id"
              options={this.props.departments.sort(function (a, b) {
                if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
                if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
                return 0;
              })}

              getOptionLabel={(option) => option.name || ''}
              getOptionSelected={(option, value) => option.name === value.name}
              onChange={this.props.handleAutocomplete}
              renderInput={(params) => (
                <TextField {...params} name="deparment" variant="outlined" label="Select Department"/>
              )}
            />  
            </Grid>
            <Grid item xs={1}> 
            <div className={styles.emailBody}>
              <Tooltip title="Add new department"arrow>
              <IconButton
                color="primary"
                variant="outlined"
                name="add_client_email_address"
                onClick={this.props.addDepartment}
                >
                <AddBoxIcon/>
              </IconButton>
              </Tooltip>
            </div>


            </Grid>
          </Grid>
        </form>

        <div className={styles.actionButton}>
          <Button variant="outlined"  id="expenseType-submit" color="primary"
                  onClick={() => this.props.submit()}>Save</Button>
        </div>
      </div>
    )
  }
}

export default BranchDepartmentForm

BranchDepartmentForm.propTypes = {
  onchange: PropTypes.func,
  handleSwitch: PropTypes.func,
  handleAutocomplete: PropTypes.func,
  item: PropTypes.object,
  selectedDepartment: PropTypes.object,
  selectedDepartmentItem: PropTypes.object,
  departments: PropTypes.array,
  addDepartment: PropTypes.func,
  submit: PropTypes.func
}
