// eslint-disable-next-line

import React from 'react'

import SimpleTable from '../../shared/SimpleTable/SimpleTable'
import SimplePopUp from '../../shared/SimplePopUp/SimplePopUp'
import SimpleDeletePopUp from '../../shared/SimpleDeletePopUp/SimpleDeletePopUp'
import axios from 'axios'
import { Redirect } from 'react-router'
import { Link, withRouter } from 'react-router-dom'
import styles from './PayrollList.module.css'
import ProgressBar from "../../shared/ProgressBar/ProgressBar";
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { IconButton, Button, Tooltip,Grid } from '@material-ui/core'
import PropTypes from 'prop-types'
import JournalEntriesDetailTab from "./PayrollsTab/JournalEntriesDetailTab";
import DeleteIcon from '@material-ui/icons/Delete'
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import SendOutlinedIcon from '@material-ui/icons/SendOutlined';
import LocalPrintshopOutlinedIcon from '@material-ui/icons/LocalPrintshopOutlined';
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import PayrollEmployeeInfo from "./PayrollEmployeeInfo";
import moment from "moment";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import UndoIcon from '@material-ui/icons/Undo';
import ExposureOutlinedIcon from '@material-ui/icons/ExposureOutlined';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import ReactToPrint from "react-to-print";
import * as XLSX from "xlsx";

export const Component = withRouter(() => {
})

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  }
}

class PayrollListShow extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      model: 'Payroll List',
      payrollLists: [],
      payrollIncluded: [],
      payrollExcluded: [],
      journalEntries: [],
      payrollAccounts: [],
      payrollAdjustment: [],
      value: 0,
      updateAccount: {},
      openPopup: false,
      openDelete: false,
      openSend: false,
      openApprove: false,
      openNotify: false,
      openReDraft: false,
      openReCompute: false,
      cancelJournalOpenPopup: false,
      postJournalOpenPopup: false,
      redirect: false,
      reload: false,
      payrollList: {},
      initialPayrollAccounts: {
        id: '',
        date_from: '',
        date_to: '',
        bir: false,
        pagibig: false,
        philhealth: false,
        sss: false
      },
      title: '',
      load: false,
      showPopUp: false,
      openAdjustment: false,
      tableHead: ['employee.reference_no', 'employee.name_formal', 'payroll_income', 'gross_income', 'payroll_deductions', 'net_pay'],
      withShow: false,
      withEdit: true,
      withShowPopUp: true,
      maxWidth: 'sm',
      error_messages: '',
      notify: {},
      forPrint: false,
      isOpen: false,
      message: '',
      type: '',
      hideButtons: false,
      selectedPayrolls: [],
      selectedPayrollIncluded: [],
      selectedPayrollExcluded: [],
      deletePayrollPopUp: false,
      payrollDetail: {},
      printEmployeePayroll: false,
      cancelPayrollPopup: false

    }
    this.handleDeleteItem = this.handleDeleteItem.bind(this)
    this.handleCreateorUpdateItem = this.handleCreateorUpdateItem.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleOpenDelete = this.handleOpenDelete.bind(this)
    this.handleDeleteClose = this.handleDeleteClose.bind(this)
    this.handleCloseAlert = this.handleCloseAlert.bind(this)
    this.handleSwitch = this.handleSwitch.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleShowPopUp = this.handleShowPopUp.bind(this)
    this.buttonClick = this.buttonClick.bind(this)
    this.handleOpenSendPopup = this.handleOpenSendPopup.bind(this)
    this.submitSend = this.submitSend.bind(this)
    this.handleOpenApprovePopup = this.handleOpenApprovePopup.bind(this)
    this.submitNotify = this.submitNotify.bind(this)
    this.handleOpenNotify = this.handleOpenNotify.bind(this)
    this.submitReDraft = this.submitReDraft.bind(this)
    this.handleOpenReDraftPopup = this.handleOpenReDraftPopup.bind(this)
    this.cancelJournalSubmit = this.cancelJournalSubmit.bind(this)
    this.postJournalSubmit = this.postJournalSubmit.bind(this)
    this.cancelJournal = this.cancelJournal.bind(this)
    this.activeJournal = this.activeJournal.bind(this)
    this.handleOpenRecomputePopup = this.handleOpenRecomputePopup.bind(this)
    this.loadPayrollLists = this.loadPayrollLists.bind(this)
    this.handleEdit = this.handleEdit.bind(this)
    this.handleCheck = this.handleCheck.bind(this)
    this.handleChangeInPayroll = this.handleChangeInPayroll.bind(this)
    this.handleCheckAll = this.handleCheckAll.bind(this)
    this.changePrintLayout = this.changePrintLayout.bind(this)
    this.handleOpenPayrollDetailsDeletePopup = this.handleOpenPayrollDetailsDeletePopup.bind(this)
    this.handleDeletePayrollDetails = this.handleDeletePayrollDetails.bind(this)
    this.printEmployeePayroll = this.printEmployeePayroll.bind(this)
    this.handleCancelPayroll = this.handleCancelPayroll.bind(this)
    this.cancelPayroll = this.cancelPayroll.bind(this)
    this.handleRecomputeJournal = this.handleRecomputeJournal.bind(this)
    this.numberFormatter = this.numberFormatter.bind(this)
    this.exportToExcel = this.exportToExcel.bind(this);
    this.formatDetailsToPrint = this.formatDetailsToPrint.bind(this)
    this.formatSssToPrint = this.formatSssToPrint.bind(this)
    this.formatHdmfToPrint = this.formatHdmfToPrint.bind(this)
    this.formatPhilhealthToPrint = this.formatPhilhealthToPrint.bind(this)
    this.formatBirToPrint = this.formatBirToPrint.bind(this)
    this.formatBankToPrint = this.formatBankToPrint.bind(this)
    this.formatJournalToPrint = this.formatJournalToPrint.bind(this)
    this.getTotalDebit = this.getTotalDebit.bind(this)
    this.getTotalCredit = this.getTotalCredit.bind(this)
  }

  componentDidMount() {
    this.loadPayrollLists('/v1/payrolls/' + this.props.match.params.id + '/payroll_details')
  }

  loadPayrollLists(url) {
    axios({
      method: 'get',
      url: url,
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {
      var newDataSort = resp.data.sort(function (a, b) {
        if (a.employee.name_formal.toLowerCase() < b.employee.name_formal.toLowerCase()) return -1;
        if (a.employee.name_formal.toLowerCase() > b.employee.name_formal.toLowerCase()) return 1;
        return 0;
      })
      this.setState({ payrollLists: newDataSort, payrollIncluded: newDataSort.filter(item => item.in_payroll === true), payrollExcluded: newDataSort.filter(item => item.in_payroll === false), load: true })
    }).catch(error => console.log(error))
    axios({
      method: 'get',
      url: '/v1/payrolls/' + this.props.match.params.id,
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {
      this.setState({ payrollList: resp.data })
    }).catch(error => console.log(error.response))
    axios({
      method: 'get',
      url: '/v1/payrolls/' + this.props.match.params.id + '/journal_entries',
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {
      this.setState({ journalEntries: resp.data })
    }).catch(error => console.log(error.response))
  }

  handleChange(event, newValue) {
    this.setState({ value: newValue })
  }

  handleDeleteItem() {
    const DeleteItemId = this.state.payrollList.id
    axios({
      method: 'delete',
      url: '/v1/payrolls/' + DeleteItemId,
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(() => {
      this.setState({
        payrollLists: this.state.payrollLists.filter(item => item.id !== DeleteItemId)
      })
      this.setState({ redirect: true })
      this.setState({ openDelete: false })
      this.setState({
        isOpen: true,
        message: 'Deleted Successfully',
        type: 'error'
      })
    }).catch(error => console.log(error.response))
  }

  submitSend() {
    const itemId = this.state.payrollList.id
    axios({
      method: 'put',
      url: '/v1/payrolls/' + itemId + '/submit',
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {
      this.setState({ openSend: false,payrollList: resp.data })
      this.setState({
        isOpen: true,
        message: 'Deleted Successfully',
        type: 'error'
      })
    }).catch(error => {
      if (error.response.status === 500) {
        this.setState(
          { reload: true },
          () => this.setState({ reload: false })
        )
      }
    })
  }

  submitApprove() {
    const itemId = this.state.payrollList.id
    axios({
      method: 'put',
      url: '/v1/payrolls/' + itemId + '/approve',
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {
      this.setState({ openApprove: false, payrollList: resp.data })
      this.setState({
        isOpen: true,
        message: 'Deleted Successfully',
        type: 'error'
      })
    }).catch(error => console.log(error.response))
  }

  submitNotify() {
    const itemId = this.state.payrollList.id
    axios({
      method: 'put',
      url: '/v1/payrolls/' + itemId + '/notify',
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {
      this.setState({ openNotify: false, payrollList: resp.data })
      this.setState({
        isOpen: true,
        message: 'Deleted Successfully',
        type: 'error'
      })
    }).catch(error => console.log(error.response))
  }

  submitReDraft() {
    const itemId = this.state.payrollList.id
    axios({
      method: 'put',
      url: '/v1/payrolls/' + itemId + '/redraft',
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {
      this.setState({ openReDraft: false,payrollList: resp.data  })
      this.setState({
        isOpen: true,
        message: 'Deleted Successfully',
        type: 'error'
      })
    }).catch(error => {
      if (error.response.status === 500) {
        this.setState(
          { reload: true },
          () => this.setState({ reload: false })
        )
      }
    })
  }

  handleClose() {
    this.setState({ openPopup: false, openReDraft: false, openApprove: false, openAdjustment: false, showPopUp: false, cancelPayrollPopup: false })
    this.setState({ openSend: false, submitReDraft: false, openNotify: false, postJournalOpenPopup: false, cancelJournalOpenPopup: false })
  }

  handleCreateorUpdateItem(item, isAdd, model) {
    var textTitle = ''
    if (isAdd) {
      textTitle = 'Create ' + model
    } else {
      textTitle = 'Edit ' + model
    }
    this.setState({ openPopup: true })
    this.setState({ payrollList: item })
    this.setState({ title: textTitle })
  }

  handleInputChange(e) {
    this.setState({
      payrollList: {
        ...this.state.payrollList,
        [e.target.name]: e.target.value
      }
    })
  }

  handleOpenDelete() {
    this.setState({ openDelete: true })
  }

  handleOpenSendPopup() {
    this.setState({ openSend: true })
  }

  handleOpenApprovePopup() {
    this.setState({ openApprove: true })
  }

  handleOpenReDraftPopup() {
    this.setState({ openReDraft: true })
  }

  handleOpenRecomputePopup() {
    this.setState({ value: 0,load: false })
    axios({
      method: 'put',
      url: '/v1/payrolls/' + this.props.match.params.id + '/recompute',
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {
      this.setState({ payrollList: resp.data,selectedPayrolls: [], hideButtons: false })
      this.setState({ tableHead: this.state.tableHead.filter(item => item !== 'check') })
      this.setState
      setTimeout(() => {
        axios({
          method: 'get',
          url: '/v1/payrolls/' + this.props.match.params.id + '/journal_entries',
          headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
        }).then(resp => {
          this.setState({ journalEntries: resp.data,load: true })
        }).catch(error => console.log(error.response))
      }, 500)
    }).catch(error => console.log(error.response))
    this.setState({ openReCompute: true })
  }

  handleRecomputeJournal() {
    this.setState({ load: false })
    axios({
      method: 'put',
      url: '/v1/payrolls/' + this.props.match.params.id + '/recompute_journal',
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {
      this.setState({ payrollList: resp.data, selectedPayrolls: [], hideButtons: false })
      this.setState({ tableHead: this.state.tableHead.filter(item => item !== 'check') })
      this.setState
      setTimeout(() => {
        axios({
          method: 'get',
          url: '/v1/payrolls/' + this.props.match.params.id + '/journal_entries',
          headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
        }).then(resp => {
          this.setState({ journalEntries: resp.data,load: true })
        }).catch(error => console.log(error.response))
      }, 500)

    }).catch(error => console.log(error.response))
  }

  handleOpenNotify() {
    this.setState({ openNotify: true })
  }

  handleDeleteClose() {
    this.setState({ openDelete: false, deletePayrollPopUp: false })
  }

  handleCloseAlert(event, reason) {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ isOpen: false })
  }

  handleSwitch(event) {
    const value = event.target.checked
    this.setState({
      payrollList: {
        ...this.state.payrollList,
        [event.target.name]: value
      }
    })
  }

  handleShowPopUp(item) {
    this.setState({ payrollEmployee: item })
    axios({
      method: 'get',
      url: '/v1/payroll_details/' + item.id + '/payroll_adjustments/',
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {
      this.setState({ payrollAdjustment: resp.data })
    }).catch(error => console.log(error.response))
    this.setState({ title: item.employee.name + ' / ' + item.employee.reference_no })
    this.setState({ showPopUp: true })
  }

  buttonClick(renderAction) {
    var redirectTo = ''
    var isRedirect = false
    if (renderAction === "back") {
      redirectTo = "/payrolls"
      isRedirect = true
    } else {
      this.setState({ openDelete: true })
    }
    this.setState({ redirect: isRedirect })
    this.setState({ pathName: redirectTo })
  }

  cancelJournalSubmit() {
    const itemId = this.state.payrollList.journal.id
    axios({
      method: 'put',
      url: '/v1/journals/' + itemId + '/unpost',
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(() => {
      axios({
        method: 'get',
        url: '/v1/payrolls/' + this.props.match.params.id,
        headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
      }).then((resp) => {
        this.setState({ payrollList: resp.data })
        this.setState({ cancelJournalOpenPopup: false })
        this.setState({
          isOpen: true,
          message: 'Submitted Successfully',
          type: 'success'
        })

      })
    })
  }

  postJournalSubmit() {
    const itemId = this.state.payrollList.journal.id
    axios({
      method: 'put',
      url: '/v1/journals/' + itemId + '/post',
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(() => {
      this.setState({ payrollList: this.state.payrollList })
      axios({
        method: 'get',
        url: '/v1/payrolls/' + this.props.match.params.id,
        headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
      }).then((resp) => {
        this.setState({ payrollList: resp.data })
        this.setState({
          isOpen: true,
          message: 'Submitted Successfully',
          type: 'success'
        })
        this.setState({ postJournalOpenPopup: false })
      })
    })
  }

  activeJournal() {
    this.setState({ postJournalOpenPopup: true })
  }

  cancelJournal() {
    this.setState({ cancelJournalOpenPopup: true })
  }

  handleEdit() {
    var val = !this.state.hideButtons
    this.setState({ hideButtons: val })
    if (val) {
      this.setState({ tableHead: ['check', ...this.state.tableHead] })
    } else {
      this.setState({ tableHead: this.state.tableHead.filter(item => item !== 'check') })
    }
  }

  handleCheck(e, item, model) {
    var includedItems = this.state.selectedPayrollIncluded.filter(a => a.id !== item.id)
    var excludedItems = this.state.selectedPayrollExcluded.filter(a => a.id !== item.id)
    if (model.includes('Included')) {
      if (e.target.checked) {
        this.setState({ selectedPayrollIncluded: [...includedItems, item] })
      } else {
        this.setState({ selectedPayrollIncluded: includedItems })
      }
    } else {
      if (e.target.checked) {
        this.setState({ selectedPayrollExcluded: [...excludedItems, item] })
      } else {
        this.setState({ selectedPayrollExcluded: excludedItems })
      }
    }
  }

  handleCheckAll(e, model) {
    var includedItems = []
    var excludedItems = []
    if (model.includes('Included')) {
      if (e.target.checked) {
        this.state.payrollLists.filter(item => item.in_payroll === true).map(item => includedItems.push(item))
        this.setState({ selectedPayrollIncluded: includedItems })
      } else {
        this.setState({ selectedPayrollIncluded: [] })
      }
    } else {
      if (e.target.checked) {
        this.state.payrollLists.filter(item => item.in_payroll === false).map(item => excludedItems.push(item))
        this.setState({ selectedPayrollExcluded: excludedItems })
      } else {
        this.setState({ selectedPayrollExcluded: [] })
      }
    }
  }

  async handleChangeInPayroll() {
    var selectedPayrolls = [...this.state.selectedPayrollIncluded, ...this.state.selectedPayrollExcluded]
    const responses = [];
    for (let i = 0; i < selectedPayrolls.length; i++) {
      var newItem = selectedPayrolls[i]
      newItem['in_payroll'] = !selectedPayrolls[i].in_payroll
      let res = await axios({ method: 'put', url: '/v1/payrolls/' + this.props.match.params.id + '/payroll_details/' + selectedPayrolls[i].id, headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }, data: selectedPayrolls[i] });
      responses.push(res);
    }
    this.setState({ load: false, selectedPayrollIncluded: [], selectedPayrollExcluded: [] })
    this.handleOpenRecomputePopup()
  }

  changePrintLayout() {
    return new Promise((resolve) => {
      this.setState({ forPrint: !this.state.forPrint}, () => resolve());
    });
  }

  handleOpenPayrollDetailsDeletePopup(item) {
    this.setState({ payrollDetail: item, deletePayrollPopUp: true,  })
  }

  handleDeletePayrollDetails() {
    axios({
      method: 'delete',
      url: '/v1/payrolls/' + this.props.match.params.id + '/payroll_details/' + this.state.payrollDetail.id,
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(() => {
      this.setState({ deletePayrollPopUp: false, payrollLists: this.state.payrollLists.filter(item => item.id !== this.state.payrollDetail.id) })
      this.setState({
        isOpen: true,
        message: 'Deleted Successfully',
        type: 'success'
      })

    })
  }

  printEmployeePayroll() {
    return new Promise((resolve) => {
      this.setState({ printEmployeePayroll: !this.state.printEmployeePayroll}, () => resolve());
    });
  }

  handleCancelPayroll(){
    this.setState({ cancelPayrollPopup: true })
  }

  cancelPayroll(){
    const itemId = this.state.payrollList.id
    axios({
      method: 'put',
      url: '/v1/payrolls/' + itemId + '/cancel',
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {
      this.setState({ cancelPayrollPopup: false,payrollList: resp.data })
      this.setState({
        isOpen: true,
        message: 'Deleted Successfully',
        type: 'error'
      })
    }).catch(error => {
      if (error.response.status === 500) {
        this.setState(
          { reload: true },
          () => this.setState({ reload: false })
        )
      }
    })
  }

  numberFormatter(value) {
    return value
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  exportToExcel() {
    const wb = XLSX.utils.book_new();

    const detailsSheet = XLSX.utils.json_to_sheet(this.formatDetailsToPrint(), { origin: 'A3',})
    detailsSheet['!cols'] = ['Employee No', 'Employee Name', 'Basic', 'Overtime', 'Other', 'Gross Income', 'HDMF', 'PHILHEALTH', 'SSS', 'Withholding Tax', 'Other ', 'Net Pay'].map((header) => ({ wch: header.length + 5 }));
    detailsSheet['!merges'] = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 1 } }, { s: { r: 1, c: 2 }, e: { r: 1, c: 4 } }, { s: { r: 1, c: 6 }, e: { r: 1, c: 10 } }]
    XLSX.utils.sheet_add_aoa(detailsSheet, [['Included in Payroll']], { origin: 'A1' });
    XLSX.utils.sheet_add_aoa(detailsSheet, [['', '', 'Income', '', '', '', 'Deductions']], { origin: 'A2' });
    XLSX.utils.book_append_sheet(wb, detailsSheet, "DETAILS");

    if (this.state.payrollList.sss) {
      const sssSheet = XLSX.utils.json_to_sheet(this.formatSssToPrint())
      sssSheet['!cols'] = ['Employee Name', 'SSS No.', "Employee's Contribution", "Employer's Contribution", 'ECC', 'Total'].map((header) => ({ wch: header.length + 5 }));
      XLSX.utils.book_append_sheet(wb, sssSheet, "SSS");
    } 
    if (this.state.payrollList.pagibig) {
      const hdmfSheet = XLSX.utils.json_to_sheet(this.formatHdmfToPrint())
      hdmfSheet['!cols'] = ['Employee Name', 'Pag-IBIG No.', "Employee's Contribution", "Employer's Contribution", 'Total'].map((header) => ({ wch: header.length + 5 }));
      XLSX.utils.book_append_sheet(wb, hdmfSheet, "HDMF");
    } 
    if (this.state.payrollList.philhealth) {
      const philhealthSheet = XLSX.utils.json_to_sheet(this.formatPhilhealthToPrint())
      philhealthSheet['!cols'] = ['Employee Name', 'PhilHealth No.', "Employee's Contribution", "Employer's Contribution", 'Total'].map((header) => ({ wch: header.length + 5 }));
      XLSX.utils.book_append_sheet(wb, philhealthSheet, "PHILHEALTH");
    } 
    const birSheet = XLSX.utils.json_to_sheet(this.formatBirToPrint())
    XLSX.utils.book_append_sheet(wb, birSheet, "BIR");
    birSheet['!cols'] = ['Employee Name', 'TIN No.', 'Total'].map((header) => ({ wch: header.length + 5 }));
    const bankSheet = XLSX.utils.json_to_sheet(this.formatBankToPrint())
    bankSheet['!cols'] = ['Employee Name', 'Account No.', 'Net Pay'].map((header) => ({ wch: header.length + 5 }));
    XLSX.utils.book_append_sheet(wb, bankSheet, "BANK");
    const journalSheet = XLSX.utils.json_to_sheet(this.formatJournalToPrint())
    journalSheet['!cols'] = ['Account Code', 'Account Title', 'Debit', 'Credit'].map((header) => ({ wch: header.length + (header === 'Account Title' ? 20 : 5) }));
    XLSX.utils.book_append_sheet(wb, journalSheet, "JOURNAL",);

    XLSX.writeFileXLSX(wb, "payroll_details.xlsx");
  }

  formatDetailsToPrint() {
    const payrollIncluded = this.state.payrollLists.filter(item => item.in_payroll === true)
    // const payrollExcluded = this.state.payrollLists.filter(item => item.in_payroll === false)
    const getBasic = payrollIncluded.reduce((sum, currentValue) => {
      return sum + parseFloat(currentValue.basic_income);
    }, 0)
    const overTime = payrollIncluded.reduce((sum, currentValue) => {
      return sum + parseFloat(currentValue.over_time);
    }, 0)
    const otherIncome = payrollIncluded.reduce((sum, currentValue) => {
      return sum + parseFloat(currentValue.other_income);
    }, 0)
    const gross = payrollIncluded.reduce((sum, currentValue) => {
      return sum + parseFloat(currentValue.gross_income);
    }, 0)
    const hdmf = payrollIncluded.reduce((sum, currentValue) => {
      return sum + parseFloat(currentValue.employee_pagibig);
    }, 0)
    const philHealth = payrollIncluded.reduce((sum, currentValue) => {
      return sum + parseFloat(currentValue.employee_philhealth);
    }, 0)
    const sss = payrollIncluded.reduce((sum, currentValue) => {
      return sum + parseFloat(currentValue.employee_sss);
    }, 0)
    const bir = payrollIncluded.reduce((sum, currentValue) => {
      return sum + parseFloat(currentValue.employee_bir);
    }, 0)
    const net = payrollIncluded.reduce((sum, currentValue) => {
      return sum + parseFloat(currentValue.net_pay);
    }, 0)
    const otherDec = payrollIncluded.reduce((sum, currentValue) => {
      return sum + parseFloat(currentValue.other_deductions);
    }, 0)

    const dataToPrint = payrollIncluded.map((payroll) => {
      return {
        'Employee No': payroll.employee.reference_no,
        'Employee Name': payroll.employee.name_formal,
        'Basic': this.numberFormatter(Number(payroll.basic_income)),
        'Overtime': this.numberFormatter(Number(payroll.over_time)),
        'Other': this.numberFormatter(Number(payroll.other_income)),
        'Gross Income': this.numberFormatter(Number(payroll.gross_income)),
        'HDMF': this.numberFormatter(Number(payroll.employee_pagibig)),
        'PHILHEALTH': this.numberFormatter(Number(payroll.employee_philhealth)),
        'SSS': this.numberFormatter(Number(payroll.employee_sss)),
        'Withholding Tax': this.numberFormatter(Number(payroll.employee_bir)),
        'Other ': this.numberFormatter(Number(payroll.other_deductions)),
        'Net Pay': this.numberFormatter(Number(payroll.net_pay)),
      }
    })
    
    return [...dataToPrint, {
      'Employee No': '',
      'Employee Name': 'Total',
      'Basic': getBasic.toLocaleString(navigator.language, { minimumFractionDigits: 2 }),
      'Overtime': overTime.toLocaleString(navigator.language, { minimumFractionDigits: 2 }),
      'Other': otherIncome.toLocaleString(navigator.language, { minimumFractionDigits: 2 }),
      'Gross Income': gross.toLocaleString(navigator.language, { minimumFractionDigits: 2 }),
      'HDMF': hdmf.toLocaleString(navigator.language, { minimumFractionDigits: 2 }),
      'PHILHEALTH': philHealth.toLocaleString(navigator.language, { minimumFractionDigits: 2 }),
      'SSS': sss.toLocaleString(navigator.language, { minimumFractionDigits: 2 }),
      'Withholding Tax': bir.toLocaleString(navigator.language, { minimumFractionDigits: 2 }),
      'Other ': otherDec.toLocaleString(navigator.language, { minimumFractionDigits: 2 }),
      'Net Pay': net.toLocaleString(navigator.language, { minimumFractionDigits: 2 }),
    }]
  }

  formatSssToPrint() {
    const payrollIncluded = this.state.payrollLists.filter(item => item.in_payroll === true)

    const dataToPrint = payrollIncluded.map((payroll) => {
      return {
        "Employee Name": payroll.employee.name_formal,
        "SSS No.": payroll.employee.sss_no,
        "Employee's Contribution": this.numberFormatter(Number(payroll.employee_sss)),
        "Employer's Contribution": this.numberFormatter(Number(payroll.employer_sss)),
        "ECC": payroll.employer_ecc,
        "Total": this.numberFormatter(Number(payroll.total_sss)),
      }
    })
    
    return dataToPrint
  }

  formatHdmfToPrint() {
    const payrollIncluded = this.state.payrollLists.filter(item => item.in_payroll === true)

    const dataToPrint = payrollIncluded.map((payroll) => {
      return {
        "Employee Name": payroll.employee.name_formal,
        "Pag-IBIG No.": payroll.employee.pagibig_no,
        "Employee's Contribution": this.numberFormatter(Number(payroll.employee_pagibig)),
        "Employer's Contribution": this.numberFormatter(Number(payroll.employer_pagibig)),
        "Total": this.numberFormatter(Number(payroll.total_pagibig)),
      }
    })
    
    return dataToPrint
  }

  formatPhilhealthToPrint() {
    const payrollIncluded = this.state.payrollLists.filter(item => item.in_payroll === true)

    const dataToPrint = payrollIncluded.map((payroll) => {
      return {
        "Employee Name": payroll.employee.name_formal,
        "PhilHealth No.": payroll.employee.philhealth_no,
        "Employee's Contribution": this.numberFormatter(Number(payroll.employee_philhealth)),
        "Employer's Contribution": this.numberFormatter(Number(payroll.employer_philhealth)),
        "Total": this.numberFormatter(Number(payroll.total_philhealth)),
      }
    })
    
    return dataToPrint
  }

  formatBirToPrint() {
    const payrollIncluded = this.state.payrollLists.filter(item => item.in_payroll === true)

    const dataToPrint = payrollIncluded.map((payroll) => {
      return {
        "Employee Name": payroll.employee.name_formal,
        "TIN No.": payroll.employee.tin_no,
        "Total": this.numberFormatter(Number(payroll.employee_bir)),
      }
    })
    
    return dataToPrint
  }

  formatBankToPrint() {
    const payrollIncluded = this.state.payrollLists.filter(item => item.in_payroll === true)

    const dataToPrint = payrollIncluded.map((payroll) => {
      return {
        "Employee Name": payroll.employee.name_formal,
        "Account No.": payroll.employee.bank_account_no,
        "Net Pay": this.numberFormatter(Number(payroll.net_pay)),
      }
    })
    
    return dataToPrint
  }

  formatJournalToPrint() {
    const journalEntries = this.state.journalEntries.sort(function (a, b) {
      if (a.account.code.toLowerCase() < b.account.code.toLowerCase()) return -1;
      if (a.account.code.toLowerCase() > b.account.code.toLowerCase()) return 1;
      return 0;
    })

    const datatoPrint = [ ...journalEntries.filter((journal) => journal.amount_credit==="0.0"), 
      ...journalEntries.filter((journal) => journal.amount_credit!=="0.0")
    ].map((entry) => {
      return {
        "Account Code": entry.account.code,
        "Account Title": entry.account.name,
        "Debit": parseFloat(entry.amount_debit).toLocaleString(navigator.language, {minimumFractionDigits: 2}),
        "Credit": parseFloat(entry.amount_credit).toLocaleString(navigator.language, {minimumFractionDigits: 2})
      }
    })

    return [...datatoPrint, {
      "Account Code": '',
      "Account Title": 'Total',
      "Debit": `₱ ${this.getTotalDebit().toLocaleString(navigator.language, {minimumFractionDigits: 2})}`,
      "Credit": `₱ ${this.getTotalCredit().toLocaleString(navigator.language, {minimumFractionDigits: 2})}`
    }]
  }

  getTotalDebit() {
    let totalDebit = 0
    if(this.state.journalEntries.length>0){
      this.state.journalEntries.map((content)=>{
        totalDebit = parseFloat(totalDebit) + parseFloat(content.amount_debit)
      })
    }
    return totalDebit
  }

  getTotalCredit() {
    let totalCredit = 0
    if(this.state.journalEntries.length>0){
      this.state.journalEntries.map((content)=>{
        totalCredit = parseFloat(totalCredit) + parseFloat(content.amount_credit)
      })
    }
    return totalCredit
  }

  render() {
    const { redirect } = this.state
    if (redirect)
      return (
        <Redirect to={{
          pathname: '/payrolls'
        }} />)
    var payrollIncluded = this.state.payrollLists.filter(item => item.in_payroll === true)
    var payrollExcluded = this.state.payrollLists.filter(item => item.in_payroll === false)
    var getBasic = payrollIncluded.reduce((sum, currentValue) => {
      return sum + parseFloat(currentValue.basic_income);
    }, 0)
    var overTime = payrollIncluded.reduce((sum, currentValue) => {
      return sum + parseFloat(currentValue.over_time);
    }, 0)
    var otherIncome = payrollIncluded.reduce((sum, currentValue) => {
      return sum + parseFloat(currentValue.other_income);
    }, 0)
    var gross = payrollIncluded.reduce((sum, currentValue) => {
      return sum + parseFloat(currentValue.gross_income);
    }, 0)
    var hdmf = payrollIncluded.reduce((sum, currentValue) => {
      return sum + parseFloat(currentValue.employee_pagibig);
    }, 0)
    var philHealth = payrollIncluded.reduce((sum, currentValue) => {
      return sum + parseFloat(currentValue.employee_philhealth);
    }, 0)
    var sss = payrollIncluded.reduce((sum, currentValue) => {
      return sum + parseFloat(currentValue.employee_sss);
    }, 0)
    var bir = payrollIncluded.reduce((sum, currentValue) => {
      return sum + parseFloat(currentValue.employee_bir);
    }, 0)
    var net = payrollIncluded.reduce((sum, currentValue) => {
      return sum + parseFloat(currentValue.net_pay);
    }, 0)
    var otherDec = payrollIncluded.reduce((sum, currentValue) => {
      return sum + parseFloat(currentValue.other_deductions);
    }, 0)

    const printPdf = <ReactToPrint
      trigger={() => {
        return <IconButton color="primary" ><LocalPrintshopOutlinedIcon/></IconButton>
      }}
      onBeforeGetContent={this.changePrintLayout}
      onAfterPrint={this.changePrintLayout}
      content={() => this.componentRef}
    />

    return (
      <>
        {
          this.state.load ? (
            <div ref={el => (this.componentRef = el)} className={this.state.forPrint ? styles.tableBodyHeight : null}>
              <div className={styles.showTitleHeader}>
                <div><h2 className={this.state.forPrint ? styles.noDisplay : null}>Payroll Details</h2></div>
                <div className={this.state.forPrint ? styles.noDisplay : null}>
                  <div className={styles.groupButtonShow}>
                    <Tooltip title="Back" arrow>
                      <IconButton
                        color="primary"
                        onClick={() => this.buttonClick("back")}
                      >
                        <ArrowBackIosOutlinedIcon />
                      </IconButton>
                    </Tooltip>

                    {
                      this.state.hideButtons ? <>

                        <Tooltip title="Save" arrow>
                          <IconButton
                            color="primary"
                            onClick={this.handleChangeInPayroll}
                          >
                            <SaveIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Cancel" arrow>
                          <IconButton
                            color='secondary'
                            onClick={this.handleCancelPayroll}
                          >
                            <CancelOutlinedIcon />
                          </IconButton>
                        </Tooltip>

                      </> :

                        <>
                        { this.state.payrollList.status !== "cancelled" && this.state.payrollList.status !== "notified" && this.state.payrollList.status !== "approved" ?
                          <Tooltip title="Edit" arrow>
                            <IconButton
                              color="primary"
                              onClick={this.handleEdit}
                            >
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                          :null}
                          {this.state.payrollList.status === "draft" ? (
                            <Tooltip title="Delete" arrow>
                              <IconButton
                                color="secondary"
                                onClick={() => this.buttonClick("delete")}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                          ) : null}
                          {this.state.payrollList.can_cancel ? (
                            <Tooltip title="Cancel" arrow>
                              <IconButton
                                onClick={this.handleCancelPayroll}
                                color="secondary"
                              >
                                <CancelOutlinedIcon />
                              </IconButton>
                            </Tooltip>
                          ) : null}
                          {this.state.payrollList.can_submit ? (
                            <Tooltip title="Submit" arrow>
                              <IconButton
                                onClick={this.handleOpenSendPopup}
                              >
                                <SendOutlinedIcon />
                              </IconButton>
                            </Tooltip>
                          ) : null}
                          {this.state.payrollList.can_approve ? (
                            <Tooltip title="Approve" arrow>
                              <IconButton
                                onClick={this.handleOpenApprovePopup}
                              >
                                <CheckCircleOutlineIcon />
                              </IconButton>
                            </Tooltip>
                          ) : null}
                          {this.state.payrollList.can_redraft ? (
                            <Tooltip title="Redraft" arrow>
                              <IconButton
                                onClick={this.handleOpenReDraftPopup}
                              >
                                <UndoIcon />
                              </IconButton>
                            </Tooltip>
                          ) : null}
                          {this.state.payrollList.can_notify ? (
                            <Tooltip title="Notify" arrow>
                              <IconButton
                                onClick={this.handleOpenNotify}
                              >
                                <EmailOutlinedIcon />
                              </IconButton>
                            </Tooltip>
                          ) : null}
                          {this.state.payrollList.can_recompute ?
                            <Tooltip title="Recompute Payroll Detail" arrow>
                              <IconButton
                                onClick={this.handleOpenRecomputePopup}
                              >
                                <ExposureOutlinedIcon />
                              </IconButton>
                            </Tooltip>
                          :null}
                          <Tooltip title="Print Payroll Detail" arrow>
                            <IconButton
                            >
                              {printPdf}
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Download Excel File" arrow>
                            <IconButton
                              onClick={this.exportToExcel}
                            >
                              <CloudDownloadOutlinedIcon />
                            </IconButton>
                          </Tooltip>
                          {this.state.payrollList.status === 'draft' ? (
                            <Tooltip title="Payroll Adjustment" arrow><IconButton><Link
                              to={{
                                pathname: `/payrolls/${this.state.payrollList.id}/payroll_adjustments/new`,
                              }}
                            >
                              <EqualizerIcon />
                            </Link>
                            </IconButton></Tooltip>
                          ) : null}
                        </>
                    }
                  </div>
                </div>
              </div>

              <div className={this.state.forPrint ? styles.noDisplay : styles.detailsBody}>
              <hr />
                  <Grid container spacing={1}>

                    <Grid item xs={12} sm={4}>
                      <Grid container spacing={1}>
                        <Grid item xs={12} sm={6}>
                          <b> Payroll Period :</b>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          {moment(this.state.payrollList.date_from).format('MMM DD')}-{moment(this.state.payrollList.date_to).format('DD YYYY')}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Grid container spacing={1}>
                      <Grid item xs={12} sm={6}>
                      <b> Status : </b>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <div className={styles.statusLabel}>{this.state.payrollList.status? this.state.payrollList.status.replace(/_/g, ' ') : ''}</div>
                        </Grid>
                      </Grid>
                    </Grid>
                    {this.state.payrollList.status === 'approved' || this.state.payrollList.status === 'notified' ? (
                    <Grid item xs={12} sm={4}>
                      <Grid container spacing={1}>
                        <Grid item xs={12} sm={6}>
                        <b>Post Status : </b>
                          </Grid>
                          <Grid item xs={12} sm={6}>

                              <div>&emsp; {this.state.payrollList.journal.is_posted == true ? 'Posted' : 'Not Posted'} </div>

                          </Grid>
                      </Grid>
                    </Grid>
                    ) : null}

                  </Grid>

              </div>
              <div className={this.state.forPrint ? styles.noDisplay : null}>
              <Tabs value={this.state.value} onChange={this.handleChange} indicatorColor="secondary"
                className={styles.tabBody} aria-label="simple tabs example">
                <Tab className={styles.tabList} label={<div className={styles.tabListTitle}>
                  {/*<PersonOutlineIcon /> */}
                  <span className={styles.tabLabel}>DETAILS</span></div>}{...a11yProps(0)} />
                {this.state.payrollList.sss === true ? (
                  <Tab className={styles.tabList} label={<div className={styles.tabListTitle}>
                    {/*<MenuBookIcon />*/}
                    <span className={styles.tabLabel}>SSS</span></div>}{...a11yProps(1)} />
                ) : null}
                {this.state.payrollList.pagibig === true ? (
                  <Tab className={styles.tabList} label={<div className={styles.tabListTitle}>
                    {/*<CalendarTodayIcon />*/}
                    <span className={styles.tabLabel}>HDMF</span></div>} {...a11yProps(2)} />
                ) : null}
                {this.state.payrollList.philhealth === true ? (
                  <Tab className={styles.tabList} label={<div className={styles.tabListTitle}>
                    {/*<CalendarTodayIcon />*/}
                    <span className={styles.tabLabel}>PHILHEALTH</span></div>} {...a11yProps(3)} />
                ) : null}
                <Tab className={styles.tabList} label={<div className={styles.tabListTitle}>
                  {/*<CalendarTodayIcon />*/}
                  <span className={styles.tabLabel}>BIR</span></div>} {...a11yProps(this.state.payrollList.sss !== true ? 1 : 4)} />
                <Tab className={styles.tabList} label={<div className={styles.tabListTitle}>
                  {/*<CalendarTodayIcon />*/}
                  <span className={styles.tabLabel}>BANK</span></div>} {...a11yProps(this.state.payrollList.pagibig !== true ? 2 : 5)} />
                <Tab className={styles.tabList} label={<div className={styles.tabListTitle}>
                  {/*<CalendarTodayIcon />*/}
                  <span className={styles.tabLabel}>JOURNAL</span></div>} {...a11yProps(this.state.payrollList.philhealth !== true ? 3 : 6)} />
              </Tabs>
              </div>
              <hr />
              <TabPanel value={this.state.value} index={0} className={styles.detailsTab}>
                <SimpleTable
                  // classNameHeader={true}
                  handleClick={this.handleCreateorUpdateItem}
                  initialItem={this.state.initialPayrollAccounts}
                  items={payrollIncluded}
                  model="Included in Payroll"
                  withShowPopUp={this.state.withShowPopUp}
                  headers={this.state.tableHead}
                  // removeLabel={true}
                  noActionHeader={true}
                  withLabel={true}
                  nodelete={true}
                  ifTotal={true}
                  handleShowPopUp={this.handleShowPopUp}
                  getBasic={getBasic.toLocaleString(navigator.language, { minimumFractionDigits: 2 })}
                  overTime={overTime.toLocaleString(navigator.language, { minimumFractionDigits: 2 })}
                  gross={gross.toLocaleString(navigator.language, { minimumFractionDigits: 2 })}
                  hdmf={hdmf.toLocaleString(navigator.language, { minimumFractionDigits: 2 })}
                  philHealth={philHealth.toLocaleString(navigator.language, { minimumFractionDigits: 2 })}
                  sss={sss.toLocaleString(navigator.language, { minimumFractionDigits: 2 })}
                  bir={bir.toLocaleString(navigator.language, { minimumFractionDigits: 2 })}
                  otherIncome={otherIncome.toLocaleString(navigator.language, { minimumFractionDigits: 2 })}
                  otherDec={otherDec.toLocaleString(navigator.language, { minimumFractionDigits: 2 })}
                  net={net.toLocaleString(navigator.language, { minimumFractionDigits: 2 })}
                  handleCheck={this.handleCheck}
                  selected={this.state.selectedPayrollIncluded}
                  handleCheckAll={this.handleCheckAll}
                />
                {
                  this.state.payrollExcluded.length > 0 ?
                    <SimpleTable
                      handleClick={this.handleCreateorUpdateItem}
                      initialItem={this.state.initialPayrollAccounts}
                      items={payrollExcluded}
                      model="Excluded in Payroll"
                      withShowPopUp={this.state.withShowPopUp}
                      headers={this.state.tableHead}
                      onOpenDeletePopup={this.handleOpenPayrollDetailsDeletePopup}
                      noActionHeader={true}
                      withLabel={true}
                      handleShowPopUp={this.handleShowPopUp}
                      handleCheck={this.handleCheck}
                      selected={this.state.selectedPayrollExcluded}
                      handleCheckAll={this.handleCheckAll}
                    />
                    : null
                }

              </TabPanel>
              {this.state.payrollList.sss === true ? (
                <TabPanel value={this.state.value} index={1} className={styles.TabBody}>
                  <h3 className={this.state.forPrint ? styles.showDisplayLabel : styles.noDisplay}>SSS</h3>
                  <SimpleTable
                    handleClick={this.handleCreateorUpdateItem}
                    initialItem={this.state.initialPayrollAccounts}
                    items={payrollIncluded}
                    model={this.state.model}
                    headers={['employee.name_formal', 'employee.sss_no', 'employee_sss', 'employer_sss', 'employer_ecc', 'total_sss']}
                    removeLabel={true}
                    forPrint={this.state.forPrint}
                  />
                </TabPanel>
              ) : null}
              {this.state.payrollList.pagibig === true ? (
                <TabPanel value={this.state.value} index={2} className={styles.TabBody}>
                  <h3 className={this.state.forPrint ? styles.showDisplayLabel : styles.noDisplay}>HDMF</h3>
                  <SimpleTable
                    initialItem={this.state.initialPayrollAccounts}
                    items={payrollIncluded}
                    model={this.state.model}
                    headers={['employee.name_formal', 'employee.pagibig_no', 'employee_pagibig', 'employer_pagibig', 'total_pagibig']}
                    removeLabel={true}
                    forPrint={this.state.forPrint}
                  />
                </TabPanel>
              ) : null}
              {this.state.payrollList.philhealth === true ? (
                <TabPanel value={this.state.value} index={3} className={styles.TabBody}>
                  <h3 className={this.state.forPrint ? styles.showDisplayLabel : styles.noDisplay}>PHILHEALTH</h3>
                  <SimpleTable
                    initialItem={this.state.initialPayrollAccounts}
                    items={payrollIncluded}
                    model={this.state.model}
                    headers={['employee.name_formal', 'employee.philhealth_no', 'employee_philhealth', 'employer_philhealth', 'total_philhealth']}
                    removeLabel={true}
                    forPrint={this.state.forPrint}
                  />
                </TabPanel>
              ) : null}
              <TabPanel value={this.state.value} index={this.state.payrollList.sss !== true ? 1 : 4} className={styles.TabBody}>
              <h3 className={this.state.forPrint ? styles.showDisplayLabel : styles.noDisplay}>BIR</h3>
                <SimpleTable
                  initialItem={this.state.initialPayrollAccounts}
                  items={payrollIncluded}
                  model={this.state.model}
                  headers={['employee.name_formal','employee.tin_no', 'employee_bir']}
                  removeLabel={true}
                  forPrint={this.state.forPrint}
                />
              </TabPanel>
              <TabPanel value={this.state.value} index={this.state.payrollList.pagibig !== true ? 2 : 5} className={styles.TabBody}>
                <h3 className={this.state.forPrint ? styles.showDisplayLabel : styles.noDisplay}>Bank</h3>
                <SimpleTable
                  initialItem={this.state.initialPayrollAccounts}
                  items={payrollIncluded}
                  model={this.state.model}
                  headers={['employee.name_formal', 'employee.bank_account_no', 'net_pay']}
                  removeLabel={true}
                />
              </TabPanel>
              <TabPanel value={this.state.value} index={this.state.payrollList.philhealth !== true ? 3 : 6} className={styles.journalTab}>
                <JournalEntriesDetailTab
                  journalEntries={this.state.journalEntries}
                  payrollList={this.state.payrollList}
                  activeJournal={this.activeJournal}
                  cancelJournal={this.cancelJournal}
                  forPrint={this.state.forPrint}
                  isPayrollDetails={true}
                  recomputeJournal={this.handleRecomputeJournal}
                />
              </TabPanel>
              <SimplePopUp
                openPopup={this.state.showPopUp}
                title={this.state.title}
                handleClose={this.handleClose}
                maxWidth={this.state.maxWidth}
                withPrint={true}
                print={this.printEmployeePayroll}
              >
                <PayrollEmployeeInfo
                  printEmployeePayroll={this.state.printEmployeePayroll}
                  payrollList={this.state.payrollList}
                  item={this.state.payrollEmployee}
                  payrollAdjustment={this.state.payrollAdjustment}
                />
              </SimplePopUp>
              <SimplePopUp
                openPopup={this.state.openSend}
                title="Payroll"
                handleClose={this.handleClose}
                maxWidth={this.state.maxWidth}
              >
                <div> <h3>Would you like to submit this Payroll?</h3></div>
                <div className={styles.actionButton}>
                  <Button variant="outlined" id="payrollAccount-submit" onClick={this.handleClose}>Cancel</Button>
                  <Button variant="outlined" id="payrollAccount-submit" color="primary" onClick={() => this.submitSend()}>Submit</Button>
                </div>
              </SimplePopUp>
              <SimplePopUp
                openPopup={this.state.openApprove}
                title="Payroll"
                handleClose={this.handleClose}
                maxWidth={this.state.maxWidth}
              >
                <div> <h3>Would you like to approve this Payroll?</h3></div>
                <div className={styles.actionButton}>
                  <Button variant="outlined" id="payrollAccount-submit" onClick={this.handleClose}>Cancel</Button>
                  <Button variant="outlined" id="payrollAccount-submit" color="primary" onClick={() => this.submitApprove()}>Save</Button>
                </div>
              </SimplePopUp>
              <SimplePopUp
                openPopup={this.state.openNotify}
                title="Payroll"
                handleClose={this.handleClose}
                maxWidth={this.state.maxWidth}
              >
                <div> <h3>Would you like to Notify Email this Payroll?</h3></div>
                <div className={styles.actionButton}>
                  <Button variant="outlined" id="payrollAccount-submit" onClick={this.handleClose}>Cancel</Button>
                  <Button variant="outlined" id="payrollAccount-submit" color="primary" onClick={() => this.submitNotify()}>Submit</Button>
                </div>
              </SimplePopUp>
              <SimplePopUp
                openPopup={this.state.openReDraft}
                title="Payroll"
                handleClose={this.handleClose}
                maxWidth={this.state.maxWidth}
              >
                <div> <h3>Would you like to redraft this Payroll?</h3></div>
                <div className={styles.actionButton}>
                  <Button variant="outlined" id="payrollAccount-submit" onClick={this.handleClose}>Cancel</Button>
                  <Button variant="outlined" id="payrollAccount-submit" color="primary" onClick={() => this.submitReDraft()}>Submit</Button>
                </div>
              </SimplePopUp>

              <SimplePopUp
                openPopup={this.state.postJournalOpenPopup}
                title="Journal"
                items={this.state.payrollList}
                handleClose={this.handleClose}
                maxWidth={this.state.maxWidth}
              >
                <div>
                  <span>
                    <b>Would you like to post this Payroll?</b>
                  </span>
                  <div
                    align="right"
                    className={styles.actionButton}>
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={this.postJournalSubmit}
                    >Submit</Button></div>
                </div>
              </SimplePopUp>
              <SimplePopUp
                openPopup={this.state.cancelJournalOpenPopup}
                title="Journal"
                items={this.state.payrollList}
                handleClose={this.handleClose}
                maxWidth={this.state.maxWidth}
              >
                <div>
                  <span>
                    <b>Would you like to unpost this Payroll?</b>
                  </span>
                  <div
                    align="right"
                    className={styles.actionButton}>
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={this.cancelJournalSubmit}
                    >Submit</Button></div>
                </div>
              </SimplePopUp>

              <SimplePopUp
                openPopup={this.state.cancelPayrollPopup}
                title="Cancel Payroll"
                handleClose={this.handleClose}
                maxWidth={this.state.maxWidth}
              >
                <div>
                  <span>
                    <b>Would you like to cancel this Payroll?</b>
                  </span>
                  <div
                    align="right"
                    className={styles.actionButton}>
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={this.cancelPayroll}
                    >Cancel Payroll</Button></div>
                </div>
              </SimplePopUp>


              <SimpleDeletePopUp
                openDeletePopup={this.state.openDelete}
                item={this.state.payrollList}
                delete={this.handleDeleteItem}
                handleDeleteClose={this.handleDeleteClose}
                model="Payroll"
              />

              <SimpleDeletePopUp
                openDeletePopup={this.state.deletePayrollPopUp}
                item={this.state.payrollDetail}
                delete={this.handleDeletePayrollDetails}
                handleDeleteClose={this.handleDeleteClose}
                model="Payroll Detail"
              />

            </div>
          ) : (
            <ProgressBar model={this.state.model} />
          )}
      </>
    )
  }
}

export default withRouter(PayrollListShow)

PayrollListShow.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.object.isRequired
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired
    })
  }),
  history: PropTypes.string
}
