import React from 'react'
import styles from './Sessions.module.css'

import axios from 'axios'
import {
  Button,Grid,FormControl,InputAdornment,InputLabel,IconButton,OutlinedInput
} from '@material-ui/core'
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import logo from '../../images/lexLogo.png'
import CompanyBG from '../../images/background-new-flip.png'
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';

import AlertMessage from '../Notify/AlertMessage'

import { setupAxios } from '../../../helpers/axios';

class ResetPassword extends React.Component {

  constructor (props) {
    super(props)
    this.state = {
      model: 'Expense Type',
      changePassword: {reset_password_token: '', password: '',password_confirmation: '' },
      showNewConfirmPassword: false,
      showNewPassword: false,
      error: false,
      errorMessages: {},
      message: '',
      type: '',
      user: {},
      username: ''
    }
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleClickShowConfirmPassword = this.handleClickShowConfirmPassword.bind(this)
    this.handleMouseDownPassword = this.handleMouseDownPassword.bind(this)
    this.handleClickShowNewPassword = this.handleClickShowNewPassword.bind(this)
    this.submit = this.submit.bind(this)
    this._handleKeyPress = this._handleKeyPress.bind(this)
  }

  componentDidMount () {
    const query = new URLSearchParams(location.search);
    this.setState({changePassword: {...this.state.changePassword, reset_password_token: query.get('reset_password_token')}})
    this.setState({ username: query.get('username') })
  }

  handleInputChange (e) {
    this.setState({ changePassword: { ...this.state.changePassword,  [e.target.name]: e.target.value } })
  }

  handleClickShowConfirmPassword () {
    this.setState({ showNewConfirmPassword: !this.state.showNewConfirmPassword })
  }

  handleClickShowNewPassword () {
    this.setState({ showNewPassword: !this.state.showNewPassword })
  }

  handleMouseDownPassword (event) {
    event.preventDefault();
  }

  validateForm() {
    return (
      this.state.changePassword.password.length > 0 &&
      this.state.changePassword.password === this.state.changePassword.password_confirmation
    );
  }

  fieldsError() {
    if(this.state.changePassword.password !== '' && this.state.changePassword.password_confirmation !== '') {
      return (
        this.state.changePassword.password === this.state.changePassword.password_confirmation
      )
    }else {
      return true
    }

  }

  submit() {
    setupAxios(this.state.username)

    const item = this.state.changePassword
    axios({
      method: 'put',
      url: '/v1/users/passwords',
      data: (item),

    }).then(() => {
      setTimeout(() => {
        location.href="/login"
      }, 1500, this.setState({
                  isOpen: true,
                  message: 'Password reset successfully',
                  type: 'success'
                }))
    }).catch(error => {
        if (error.response.status === 422) {
          if(error.response.data.errors[0].detail.reset_password_token !== undefined) {
            this.setState({isOpen: true,message: " Unexpected Error Occurred", type: 'error'})
            location.href="/login"
          }else {
            var errors = {}
            Object.keys(error.response.data.errors[0].detail).map((field)=>{
              errors[field] = error.response.data.errors[0].detail
             })
             this.setState({errorMessages: errors})
          }
        }
    })
  }

  _handleKeyPress(e) {
    if (e.key === 'Enter') {
      this.submit()
    }
  }

  render () {
    return (
      <div>
        <Grid container component="main" className={styles.root}>
          <CssBaseline/>
          <Grid item xs={false} sm={4} md={7}>
            <img src={process.env.REACT_APP_BG_URL?process.env.REACT_APP_BG_URL:CompanyBG} alt="Company Background" className={styles.image}/>
          </Grid>
          <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
            <div className={styles.paper}>
              <div className={styles.headerName}> {process.env.REACT_APP_TITLE_COMPANY?process.env.REACT_APP_TITLE_COMPANY: "LEXITECH" } Enterprise System</div>
              <div>
                <img src={process.env.REACT_APP_LOGO_URL? process.env.REACT_APP_LOGO_URL:logo } alt="Company Logo" className={styles.avatar}/>
              </div>
              <Typography component="h1" variant="h5">
                Reset Password
              </Typography>
              <form className={styles.form} noValidate>
                <FormControl variant="outlined" className={styles.textField} fullWidth>
                <InputLabel htmlFor="outlined-adornment-password">New Password</InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  label="New Password"
                  type={this.state.showNewPassword ? 'text' : 'password'}
                  value={this.state.changePassword.password}
                  name="password"
                  onKeyPress={this._handleKeyPress}
                  onChange={this.handleInputChange}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={this.handleClickShowNewPassword}
                        onMouseDown={this.handleMouseDownPassword}
                        edge="end"
                      >
                        {this.state.showNewPassword  ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                  labelWidth={70}
                />
              </FormControl>
              <div className={styles.errorMessage}>{this.state.errorMessages.password?'Password ' + this.state.errorMessages.password.password:null}</div>
            <FormControl variant="outlined" className={styles.textField} fullWidth>
              <InputLabel htmlFor="outlined-adornment-confirm-password">Confirm Password</InputLabel>
              <OutlinedInput
                id="outlined-adornment-confirm-password"
                label="Confirm Password"
                type={this.state.showNewConfirmPassword ? 'text' : 'password'}
                onKeyPress={this._handleKeyPress}
                value={this.state.changePassword.password_confirmation}
                name="password_confirmation"
                onChange={this.handleInputChange}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={this.handleClickShowConfirmPassword}
                      onMouseDown={this.handleMouseDownPassword}
                      edge="end"
                    >
                      {this.state.showNewConfirmPassword  ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                labelWidth={70}
              />
            </FormControl>
            <div className={styles.errorMessage}>{!this.fieldsError()? "Password Don't Match": null}</div>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={this.submit}
                className={styles.submit}
                disabled={!this.validateForm() || this.state.isOpen}
              >
                {this.state.isOpen? <CircularProgress size={20}/>:  'Reset Password'}
              </Button>

              </form>
            </div>
          </Grid>

          <AlertMessage
            notify={this.state.notify}
            handleCloseAlert={this.handleCloseAlert}
            isOpen={this.state.isOpen}
            type={this.state.type}
            message={this.state.message}
          />
        </Grid>
      </div>
    )
  }
}
export default ResetPassword
