// eslint-disable-next-line
import React from 'react'
import axios from 'axios'
import styles from '../PayrollList.module.css'
import ProgressBar from "../../../shared/ProgressBar/ProgressBar";
import { Table, TableHead, TableRow, TableCell, TableBody, Button, TextField, Grid } from '@material-ui/core'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import PropTypes from 'prop-types'
import NumberFormat from 'react-number-format'
import { Redirect } from 'react-router'

class PayrollAdjustmentShow extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      model: 'Payroll List',
      payrollLists: [],
      departments: [],
      payrollAccounts: [],
      payrollAdjustment: [],
      selectedPayrollLists: [],
      selectedAdjustments: [],
      selectedDeleteAdjustments: [],
      selectedDepartments: [],
      value: 0,
      updateAccount: {},
      openPopup: false,
      checked: false,
      openDeletePopup: false,
      payrollList: {},
      payrollAccount: {},
      redirect: false,
      initialPayrollAdjustment: { amount: '', no_of_hours: '', no_of_minutes: '' },
      initialPayrollAccounts: { id: '', date_from: '', date_to: '', bir: false, pagibig: false, philhealth: false, sss: false },
      title: '',
      load: false,
      openAdjustment: false,
      tableHead: ['employee.name', 'tax_status.name', 'basic_income', 'over_time', 'other_income', 'gross_income', 'employee_pagibig', 'employee_philhealth', 'employee_sss', 'employee_bir', 'other_income', 'net_pay'],
      withShow: false,
      withEdit: true,
      maxWidth: 'sm',
      error_messages: '',
      notify: {},
      isOpen: false,
      message: '',
      type: ''
    }
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleAllChecked = this.handleAllChecked.bind(this)
    this.handleToggle = this.handleToggle.bind(this)
    this.byDepartment = this.byDepartment.bind(this)
    this.handleSubmitCheck = this.handleSubmitCheck.bind(this)
    this.handleInputChangeTableAmount = this.handleInputChangeTableAmount.bind(this)
    this.buttonClick = this.buttonClick.bind(this)
  }

  componentDidMount() {
    this.loadPayrollLists('/v1/payrolls/' + this.props.match.params.id + '/payroll_details')
  }

  loadPayrollLists(url) {
    axios({
      method: 'get',
      url: url,
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {
      var newDataSort = resp.data.sort(function (a, b) {
        if (a.employee.name.toLowerCase() < b.employee.name.toLowerCase()) return -1;
        if (a.employee.name.toLowerCase() > b.employee.name.toLowerCase()) return 1;
        return 0;
      })
      this.setState({ payrollLists: newDataSort })
      axios({
        method: 'get',
        url: '/v1/payrolls/' + this.props.match.params.id + '/payroll_adjustments',
        headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
      }).then(resp => {
        if (this.props.match.params.accId !== undefined) {
          var selectedArray = []
          var perAdjustment = []
          resp.data.map((selected) => {
            if (selected.account_id === this.props.location.state.item.account_id) {
              perAdjustment.push(selected)
            }
          })
          newDataSort.map((pair) => {
            perAdjustment.map((selected) => {
              if (pair.id === selected.detail_id) {
                if (pair.rate == "0.0" || selected.amount) {
                  pair['amount'] = selected.amount
                  pair['select_id'] = selected.id
                } else {
                  pair['no_of_minutes'] = selected.no_of_minutes
                  pair['no_of_hours'] = selected.no_of_hours
                  pair['select_id'] = selected.id
                }
                selectedArray.push(pair)
              }
            })
          })
          this.setState({ selectedPayrollLists: selectedArray })
          this.setState({ selectedAdjustments: selectedArray })
        } else {
          this.setState({ selectedPayrollLists: [] })
        }
        this.setState({ load: true })
      }).catch(error => console.log(error.response))
    }).catch(error => console.log(error.response))
    axios({
      method: 'get',
      url: '/v1/payrolls/' + this.props.match.params.id,
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {
      this.setState({ payrollList: resp.data })
    }).catch(error => console.log(error.response))
    axios({
      method: 'get',
      url: '/v1/departments/',
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {
      this.setState({ departments: resp.data })
      var urlAccounts = ''
      if (this.props.location.state.item.id === undefined) {
        urlAccounts = '/v1/payroll_accounts/' + this.props.match.params.accId
      } else {
        urlAccounts = '/v1/payroll_accounts/' + this.props.location.state.item.id
      }
      axios({
        method: 'get',
        url: urlAccounts,
        headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
      }).then(resp => {
        this.setState({ payrollAccount: resp.data })
      }).catch(error => console.log(error.response))
    }).catch(error => console.log(error.response))
  }

  handleInputChange(e) {
    this.setState({
      initialPayrollAdjustment: {
        ...this.state.initialPayrollAdjustment,
        [e.target.name]: e.target.value
      }
    })
  }

  handleInputChangeTableAmount(event, idx, params) {
    const updatedVoucher = [...this.state.selectedPayrollLists]
    if (updatedVoucher[this.state.selectedPayrollLists.indexOf(this.state.payrollLists[idx])] !== undefined) {
      updatedVoucher[this.state.selectedPayrollLists.indexOf(this.state.payrollLists[idx])][params] = event.floatValue
    }
    this.setState({ selectedPayrollLists: updatedVoucher })
  }

  async handleApiPostRequests(items) {
    var responses = []
    if(items.length>0) {
      for( const item of items) {
        var result = {}
        if(!item.select_id)
          result = await axios({ method: 'post', url: '/v1/payroll_adjustments', data: item, headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') } })
        else
        result = await axios({ method: 'put', url: `/v1/payroll_adjustments/` + item.select_id, data: item, headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') } })
        responses.push(result)
      }
      if(responses.length === items.length){
        this.setState({redirect: true})
      }
    }
  }

  async handleSubmitCheck() {
    var adjustments = []
    var updatedAdjustments = []
    var redirectNow = false
    var accountId = ''
    if (this.props.location.state.item.id !== undefined) {
      accountId = this.props.location.state.item.id
    }
    else {
      accountId = this.props.match.params.accId
    }

    const item = this.state.selectedPayrollLists
    const Itemselected = this.state.selectedAdjustments
    const NoOfHours = this.state.initialPayrollAdjustment.no_of_hours
    const NoOfMinutes = this.state.initialPayrollAdjustment.no_of_minutes
    const amount = this.state.initialPayrollAdjustment.amount
    if (this.props.match.params.accId === undefined) {
      item.map((adjustment) => {
        var data = { account_id: accountId }
        data['employee_id']=adjustment.employee_id
        data['detail_id'] = adjustment.id
        if (amount === '') {
          data['amount'] = adjustment.amount
        } else {
          data['amount'] = amount
        }
        if (NoOfHours === '') {
          data['no_of_hours'] = adjustment.no_of_hours
        } else {
          data['no_of_hours'] = NoOfHours
        }
        if (NoOfMinutes === '') {
          data['no_of_minutes'] = adjustment.no_of_minutes
        } else {
          data['no_of_minutes'] = NoOfMinutes
        }
        adjustments.push(data)
        if (adjustments.length === item.length) {
          this.handleApiPostRequests(adjustments)
        }
      })
    }
    else {
      Itemselected.filter(item => item.amount !== "").map((adjustment) => {
        var data = { account_id: accountId, employee_id: adjustment.employee_id, detail_id: adjustment.id ,id: adjustment.id?adjustment.id:'' }
        data['select_id'] = adjustment.select_id
        if (amount === '') {
          data['amount'] = adjustment.amount
        } else {
          data['amount'] = amount
        }
        if (NoOfHours === '') {
          data['no_of_hours'] = adjustment.no_of_hours
        } else {
          data['no_of_hours'] = NoOfHours
        }
        if (NoOfMinutes === '') {
          data['no_of_minutes'] = adjustment.no_of_minutes
        } else {
          data['no_of_minutes'] = NoOfMinutes
        }
        updatedAdjustments.push(data)
        if (updatedAdjustments.length === Itemselected.length) {
          this.handleApiPostRequests(updatedAdjustments)
        }
      })
    }
    if (this.state.selectedDeleteAdjustments.length > 0) {
      this.state.selectedDeleteAdjustments.map((deleteData) => {
        setTimeout(() => {
          axios({
            method: 'delete',
            url: '/v1/payrolls/' + this.props.match.params.id + '/payroll_adjustments/' + deleteData.select_id,
            headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
          }).then(() => {
            this.setState({ redirect: true })
          }).catch(error => console.log(error.response))
        }, 200)
      })
    } else {
      this.setState({ redirect: redirectNow })
    }
  }

  handleToggle(event, item) {
    var selectedArray = this.state.selectedPayrollLists
    if (this.state.selectedPayrollLists.indexOf(item) <= -1) {
      selectedArray.push(item)
    } else {
      this.setState({ selectedPayrollLists: [...this.state.selectedPayrollLists, ...[item.amount = '']] })
      this.setState({ selectedDeleteAdjustments: [...this.state.selectedDeleteAdjustments, ...[item]] })
      selectedArray.splice(selectedArray.indexOf(item), 1)
    }
    this.setState({ selectedPayrollLists: selectedArray })
  }

  handleAllChecked() {
    var checkAll = []
    var unCheckAll = this.state.selectedPayrollLists
    var checkRemaining = this.state.selectedPayrollLists
    if (this.state.payrollLists.length > 0) {
      if (this.state.selectedPayrollLists.length <= 0) {
        this.state.payrollLists.map((list) => {
          checkAll.push(list)
        })
        return this.setState({ selectedPayrollLists: checkAll })
      } else if (this.state.selectedPayrollLists.length === this.state.payrollLists.length) {
        this.state.payrollLists.map((list) => {
          unCheckAll.splice(unCheckAll.indexOf(list.id), 1)
        })
        return this.setState({ selectedPayrollLists: unCheckAll })
      } else {
        this.state.payrollLists.map((list) => {
          var addState = false
          this.state.selectedPayrollLists.map((selectedId) => {
            if (selectedId !== list.id) {
              addState = true
            }
          })
          if (addState === true) {
            checkRemaining.push(list.id)
          }
        })
        return this.setState({ selectedPayrollLists: checkRemaining })
      }
    }
  }

  byDepartment(event, department) {
    var checkAllDepartment = this.state.selectedPayrollLists
    var unCheckAll = this.state.selectedPayrollLists
    var checkRemaining = this.state.selectedPayrollLists
    if (event.target.checked === true) {
      if (this.state.payrollLists.length > 0) {
        this.state.payrollLists.map((list) => {
          if (department.id === list.department_id) {
            checkAllDepartment.push(list)
          }
        })
        return this.setState({ selectedPayrollLists: checkAllDepartment })
      }
    }
    else {
      if (this.state.payrollLists.length > 0) {
        if (this.state.selectedPayrollLists.length > 0) {
          this.state.payrollLists.map((list) => {
            if (department.id === list.department_id) {
              checkAllDepartment.splice(checkAllDepartment.indexOf(list.id), 1)
            }
          })
          return this.setState({ selectedPayrollLists: checkAllDepartment })
        }
        else if (this.state.selectedPayrollLists.length === checkAllDepartment.length) {
          this.state.payrollLists.map((list) => {
            unCheckAll.splice(unCheckAll.indexOf(list.id), 1)
          })
          return this.setState({ selectedPayrollLists: unCheckAll })
        } else {
          this.state.payrollLists.map((list) => {
            var addState = false
            this.state.selectedPayrollLists.map((selectedId) => {
              if (selectedId !== list.id) {
                addState = true
              }
            })
            if (addState === true) {
              if (department.id === list.department_id) {
                checkRemaining.push(list)
              }
            }
          })
          return this.setState({ selectedPayrollLists: checkRemaining })
        }
      }
    }
  }

  buttonClick(renderAction) {
    var redirectTo = ''
    var isRedirect = false
    if (renderAction === "back") {
      redirectTo = "payroll_adjustments/new"
      isRedirect = true
    } else {
      this.setState({ openDelete: true })
    }
    this.setState({ redirect: isRedirect })
    this.setState({ pathName: redirectTo })
  }

  render() {
    const { redirect } = this.state
    if (redirect)
      return (<Redirect to={{
        pathname: '/payrolls/' + this.props.match.params.id + '/payroll_adjustments/new'
      }} />)
    const item = this.props.location.state.item
    return (
      <>
        {
          this.state.load ? (
            <div>
              <div className={styles.adjustmentBody}>
                <h2>{item.name === undefined ? item.account_name : item.name}</h2>
                <hr />
                <Grid container spacing={1}>
                  {this.state.payrollAccount.rate === "0.0" ? (
                    <Grid item xs={10}>
                      <TextField
                        autoComplete="off"
                        className={styles.textFieldAmountHeader}
                        variant="outlined"
                        type="number"
                        size="small"
                        name="amount"
                        label="Amount"
                        fullWidth="true"
                        onChange={this.handleInputChange}
                        value={this.state.initialPayrollAdjustment.amount}
                        id="amount"
                      />
                    </Grid>
                  ) : (
                    <>
                      <Grid item xs={5}>
                        <TextField
                          autoComplete="off"
                          className={styles.textFields}
                          variant="outlined"
                          type="number"
                          size="small"
                          name="no_of_hours"
                          fullWidth="true"
                          label="No. of Hours"
                          onChange={this.handleInputChange}
                          value={this.state.initialPayrollAdjustment.no_of_hours}
                          id="no_of_hours"
                        />
                      </Grid>
                      <Grid item xs={5}>
                        <TextField
                          autoComplete="off"
                          className={styles.textFields}
                          variant="outlined"
                          type="number"
                          size="small"
                          name="no_of_minutes"
                          fullWidth="true"
                          label="No. of Minutes"
                          onChange={this.handleInputChange}
                          value={this.state.initialPayrollAdjustment.no_of_minutes}
                          id="no_of_minutes"
                        />
                      </Grid>
                    </>
                  )}
                  <Grid item xs={2}>
                    <div className={styles.actionButton}>
                      <Button variant="outlined" disabled={this.props.match.params.accId !== undefined ? false : !this.state.selectedPayrollLists.length > 0} color="primary" onClick={() => this.handleSubmitCheck()}>Save</Button>
                      <Button variant="outlined" color="primary" onClick={() => this.buttonClick("back")}>Back</Button>
                    </div>
                  </Grid>
                </Grid>
                <div className={styles.departmentCheckBox}>
                  <div>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.selectedPayrollLists.length === this.state.payrollLists.length}
                          onClick={(event) => this.handleAllChecked(event)}
                          name="checked"
                          color="primary"
                        />
                      }
                      label='All'
                    />

                  </div>
                  {this.state.departments.sort(function (a, b) {
                    if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
                    if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
                    return 0;
                  }).map((department, i) => (
                    <div key={i}>
                      <div>
                        <FormControlLabel
                          control={
                            <Checkbox
                              // checked={this.state.selectedPayrollLists.length }
                              onClick={(event) => this.byDepartment(event, department)}
                              name="checked"
                              color="primary"
                            />
                          }
                          label={department.name}
                        />

                      </div>
                    </div>
                  ))}
                </div>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell><b>Employee Name</b></TableCell>
                      <TableCell><b>Department</b></TableCell>
                      {item.rate === "0.0" || item.amount ? (
                        <TableCell><b>Amount</b></TableCell>
                      ) : (
                        <>
                          <TableCell><b>No. of Hours</b></TableCell>
                          <TableCell><b> No.of Minutes</b></TableCell>
                        </>
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.payrollLists.sort(function (a, b) {
                      if (a.employee.name.toLowerCase() < b.employee.name.toLowerCase()) return -1;
                      if (a.employee.name.toLowerCase() > b.employee.name.toLowerCase()) return 1;
                      return 0;
                    }).map((list, idx) => (
                      <TableRow key={idx}>
                        <TableCell>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={this.state.selectedPayrollLists.indexOf(list) > -1}
                                onClick={(event) => this.handleToggle(event, list, 'amount')}
                                name={`selectedPayrollLists-${idx}`}
                                color="primary"
                              />
                            }
                          />
                        </TableCell>
                        <TableCell>{list.employee.name}</TableCell>
                        <TableCell>{list.employee.department.name}</TableCell>
                        {this.state.payrollAccount.rate === "0.0" ? (
                          <TableCell>

                            <NumberFormat
                              customInput={TextField}
                              autoComplete="off"
                              variant="outlined"
                              className={this.state.selectedPayrollLists.indexOf(list) > -1 ? styles.amount : styles.disable}
                              size="small"
                              fullWidth
                              name="amount"
                              thousandSeparator={true}
                              onValueChange={(event) => this.handleInputChangeTableAmount(event, idx, 'amount', list)}
                              value={list.amount !== undefined ? list.amount : ''}
                              decimalSeparator="."
                              fixedDecimalScale={true}
                            />
                          </TableCell>
                        ) : (
                          <>
                            <TableCell>
                              <NumberFormat
                                customInput={TextField}
                                autoComplete="off"
                                variant="outlined"
                                className={styles.amount}
                                size="small"
                                fullWidth
                                name="no_of_hours"
                                onValueChange={(event) => this.handleInputChangeTableAmount(event, idx, 'no_of_hours')}
                                value={list.employee.no_of_hours}
                              />
                            </TableCell>
                            <TableCell>
                              <NumberFormat
                                customInput={TextField}
                                autoComplete="off"
                                variant="outlined"
                                className={styles.amount}
                                size="small"
                                fullWidth
                                name="no_of_minutes"
                                onValueChange={(event) => this.handleInputChangeTableAmount(event, idx, 'no_of_minutes')}
                                value={list.employee.no_of_minutes ? list.employee.no_of_minutes : list.no_of_minutes}
                              />
                            </TableCell>
                          </>
                        )}
                      </TableRow>

                    ))}

                  </TableBody>
                </Table>
              </div>
            </div>
          ) : (
            <ProgressBar model={this.state.model} />
          )}
      </>
    )
  }
}

export default PayrollAdjustmentShow

PayrollAdjustmentShow.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.object.isRequired
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
      accId: PropTypes.string,
    })
  }),
  item: PropTypes.object,
  state: PropTypes.object
}
