// eslint-disable-next-line


import React from 'react'

import SimpleTable from '../../shared/SimpleTable/SimpleTable'

import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import axios from 'axios'
import ProgressBar from "../../shared/ProgressBar/ProgressBar";
import AlertMessage from '../../shared/Notify/AlertMessage';
import AttendanceForm from './AttendanceForm';
import SimplePopUp from '../../shared/SimplePopUp/SimplePopUp';
import ImportAttendanceForm from './ImportAttendanceForm'
import moment from 'moment'
import SimpleDeletePopUp from '../../shared/SimpleDeletePopUp/SimpleDeletePopUp';
import Cookies from 'js-cookie';
import PropTypes from 'prop-types';
import AttendanceSearchForm from './AttendanceSearchForm';
class Attendances extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      attendances: [],
      load: false,
      tableHead: ['employee.name_formal', 'date_worked', 'time_in', 'time_out'],
      withShow: false,
      withEdit: true,
      maxWidth: 'sm',
      error_messages: '',
      notify: {},
      isOpen: false,
      message: '',
      type: '',
      model: 'Attendance',
      attendance: {},
      openPopup: false,
      openImportForm: false,
      initialItem: { date_worked: moment().format('yyyy-MM-DD'), time_in: moment().format('HH:mm'), time_out: moment().add(8,'hours').format('HH:mm') },
      title: '',
      employee: {},
      employees: [],
      employeeView: false,
      searchItem: {date_worked: moment().format('yyyy-MM-DD')},
      searchUrl: '/v1/attendances'
    }

    this.onFileChange = this.onFileChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleOpenPopup = this.handleOpenPopup.bind(this)
    this.handleCreateorUpdateItem = this.handleCreateorUpdateItem.bind(this)
    this.handleAutocompleteChange = this.handleAutocompleteChange.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleSubmitAttendance = this.handleSubmitAttendance.bind(this)
    this.handleOpenDeletePopup = this.handleOpenDeletePopup.bind(this)
    this.handleDeleteItem = this.handleDeleteItem.bind(this)
    this.handleSearchAutocomplete = this.handleSearchAutocomplete.bind(this)
    this.handleSearchInputChange = this.handleSearchInputChange.bind(this)
    this.handleSearch = this.handleSearch.bind(this)
    this.clearSearch = this.clearSearch.bind(this)
  }

  componentDidMount() {
    var url = '/v1/attendances'
    if (Cookies.get('current_user')) {
      if (JSON.parse(Cookies.get('current_user')).is_employee) {
        this.setState({ employeeView: JSON.parse(Cookies.get('current_user')).is_employee, employee: JSON.parse(Cookies.get('current_user')) })
        url = '/v1/employees/attendances'
        this.setState({ tableHead: ['date_worked', 'time_in', 'time_out'] })
      }
    }
    // var searchUrl = this.state.searchUrl + this.props.location.search
    var searchParams = {}
    const params = new URLSearchParams(this.props.location.search)
    // This is to load all params based on URLSearchParams
    params.forEach(function (value, key) {
      if (key !== 'page' && key !== 'per_page') {
        if (key === 'code') {
          searchParams[key] = value.replace(/\*/g, '')
        } else if ( key === 'employee_id') {
          axios({
            method: 'get',
            url: '/v1/employees/' + value,
            headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
          }).then(resp => {
              searchParams['employee'] = resp.data
          })
        }
        else {
          searchParams[key] = value
        }
      }
    })
    if (params.get('per_page')) {
      this.setState({ perPage: params.get('per_page') })
    }

    this.setState({ searchItem: searchParams })

    this.loadAttendances(url)
  }

  loadAttendances(url) {
    axios({
      method: 'get',
      url: url,
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {
      if (this.state.employeeView) {
        this.setState({ attendances: resp.data })
      } else {
        axios({
          method: 'get',
          url: '/v1/employees',
          headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
        }).then(resp => {
          this.setState({ employees: resp.data })
        }).catch(error => { this.setState({ isOpen: true, message: error.response.status.toString() + " Unexpected Error Problem Occurred", type: 'error' }) })
        this.setState({ attendances: resp.data })
      }
      this.setState({ load: true })
    }).catch(error => { this.setState({ isOpen: true, message: error.response.status.toString() + " Unexpected Error Problem Occurred", type: 'error' }) })

  }

  onFileChange(e) {
    e.stopPropagation()
    e.preventDefault()
    this.setState({
      attendance: {
        ...this.state.attendance,
        file: e.target.files[0],
        title: e.target.files[0].name
      }
    })
  }

  handleSubmit() {
    const item = this.state.attendance
    const file = item.file
    const formdata = new FormData()
    formdata.append('file', file)
    axios({
      method: 'post',
      url: '/v1/attendances/import',
      data: (formdata),
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(() => {
      location.reload()
      this.setState({
        isOpen: true,
        message: 'Submitted Successfully',
        type: 'success'
      })
      this.setState({ openPopup: false })
    }).catch(error => { this.setState({ isOpen: true, message: error.response.status.toString() + " Unexpected Error Problem Occurred", type: 'error' }) })
  }



  handleClose() {
    this.setState({ openPopup: false, openImportForm: false, openDeletePopup: false })
  }

  handleOpenPopup() {
    this.setState({ openImportForm: true })
  }

  handleCreateorUpdateItem(item, isAdd) {
    var title = ""
    if (isAdd) {
      title = "Create Attendance"
      this.setState({ openPopup: true, attendance: item, title: title })
    } else {
      title = "Update Attendance"
      item['time_in'] = moment(item['time_in']).format('HH:mm')
      item['time_out'] = moment(item['time_out']).format('HH:mm')
      this.setState({ employee: item.employee })
      this.setState({ openPopup: true, attendance: item, title: title })
    }
  }

  handleInputChange(e) {
    this.setState({ attendance: { ...this.state.attendance, [e.target.name]: e.target.value } })
  }

  handleAutocompleteChange(e, value) {
    this.setState({ employee: value })
  }

  handleSubmitAttendance() {
    var item = this.state.attendance
    var method = ""
    var url = ""
    if (this.state.employee.id) {
      if (!item.id) {
        method = "post",
          url = "/v1/employees/" + this.state.employee.id + "/attendances"
      } else {
        method = "put",
          url = "/v1/employees/" + this.state.employee.id + "/attendances/" + item.id
      }
      axios({
        method: method,
        url: url,
        data: item,
        headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
      }).then((resp) => {
        var newData = resp.data
        this.setState({ attendances: this.state.attendances.filter(attendance => attendance.id !== newData.id) })
        this.setState({ attendances: [...this.state.attendances, newData] })
        this.setState({
          isOpen: true,
          message: 'Submitted Successfully',
          type: 'success'
        })
        this.setState({ openPopup: false })
      })
    }
  }

  handleOpenDeletePopup(item) {
    this.setState({ openDeletePopup: true, attendance: item, employee: item.employee })
  }

  handleDeleteItem() {
    var item = this.state.attendance
    axios({
      method: 'delete',
      url: '/v1/employees/' + this.state.employee.id + '/attendances/' + item.id,
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(() => {
      this.setState({ attendances: this.state.attendances.filter(attendance => attendance.id !== item.id) })
      this.setState({ isOpen: true, message: 'Deleted Successfully', type: 'success' })
      this.setState({ openDeletePopup: false })
    }).catch(error => { this.setState({ isOpen: true, message: error.response.status.toString() + " Unexpected Error Problem Occurred", type: 'error' }) })

  }

  handleSearchAutocomplete(e, value) {
    this.setState({ searchItem: {...this.state.searchItem, employee: value}})
  }

  handleSearchInputChange(e){
    this.setState({ searchItem: {...this.state.searchItem, [e.target.name]: e.target.value}})
  }

  handleSearch() {
    const { history } = this.props
    var searchParams = this.getSearchParams().join("&")
    const params = new URLSearchParams()
    params.append("/search", searchParams)
    history.push({ search: searchParams })
    this.loadAttendances([this.state.searchUrl, searchParams].join("?"))
  }

  clearSearch() {
    const { history } = this.props
    history.push({ search: '' })
    this.setState({ withResultsLabel: false })
    this.loadAttendances(this.state.searchUrl)
    this.setState({ searchItem: {}})
  }

  getSearchParams() {
    var searchParams = []
    // This is to load all params based on searchParams state.
    Object.entries(this.state.searchItem).map(([key, value]) => {
      if (value != '' && value != undefined && value != null && key !== 'employee') {
        searchParams.push([key, value].join("="))
      }
      else if ( key === 'employee' && value.id) {
        searchParams.push("employee_id=" + value.id)
      }
    })
    return searchParams
  }

  render() {
    return (
      <>
        {
          this.state.load ? (
            <>
              <AttendanceSearchForm
                employees={this.state.employees}
                item={this.state.searchItem}
                handleChange={this.handleSearchInputChange}
                handleAutocompleteChange={this.handleSearchAutocomplete}
                search={this.handleSearch}
                clearSearch={this.clearSearch}
              />

              <SimpleTable
                handleClick={this.handleCreateorUpdateItem}
                initialItem={this.state.initialItem}
                items={this.state.attendances.sort((a, b) => { return new Date(b.date_worked) - new Date(a.date_worked) })}
                model={this.state.model}
                onOpenDeletePopup={this.handleOpenDeletePopup}
                headers={this.state.tableHead}
                withShow={this.state.withShow}
                icon={<AssignmentIndIcon fontSize="large" />}
                withAttendanceImport={true}
                handleAttendanceImport={this.handleOpenPopup}
                noCreateAction={false}
                withEdit={true}
                nodelete={false}
              />
              <SimplePopUp
                openPopup={this.state.openImportForm}
                title="Import Attendance File (.csv)"
                items={this.state.attendances}
                handleClose={this.handleClose}
                maxWidth="sm"
              >
                <ImportAttendanceForm
                  attachment={this.state.attendance}
                  error={this.state.error_messages}
                  onFileChange={this.onFileChange}
                  submit={this.handleSubmit}
                />
              </SimplePopUp>

              <SimplePopUp
                openPopup={this.state.openPopup}
                title={this.state.title}
                items={this.state.attendances}
                handleClose={this.handleClose}
                maxWidth="sm"
              >
                <AttendanceForm
                  item={this.state.attendance}
                  handleChange={this.handleInputChange}
                  handleAutocompleteChange={this.handleAutocompleteChange}
                  employees={this.state.employees}
                  submit={this.handleSubmitAttendance}
                  employee={this.state.employee}
                  employeeView={this.state.employeeView}

                />
              </SimplePopUp>
              <SimpleDeletePopUp
                openDeletePopup={this.state.openDeletePopup}
                item={this.state.attendance}
                delete={this.handleDeleteItem}
                handleDeleteClose={this.handleClose}
                model={this.state.model}
              />
            </>
          ) : (
            <ProgressBar model={this.state.model} />
          )}
        <AlertMessage
          notify={this.state.notify}
          handleCloseAlert={this.handleCloseAlert}
          isOpen={this.state.isOpen}
          type={this.state.type}
          message={this.state.message}
        />
      </>
    )
  }
}

export default Attendances
Attendances.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object
}
