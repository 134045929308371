import React from 'react'

import PropTypes from 'prop-types'
import styles from '../PayrollList.module.css'

import {
  Table, TableHead, TableBody, TableCell, TableRow
} from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import PrintIcon from '@material-ui/icons/Print';

import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ExposureOutlinedIcon from '@material-ui/icons/ExposureOutlined';

import CancelIcon from '@material-ui/icons/Cancel';
class JournalEntriesDetailTab extends React.Component {
  getTotalDebit () {
    var totalDebit = 0
    if(this.props.journalEntries.length>0){
      this.props.journalEntries.map((content)=>{
        totalDebit = parseFloat(totalDebit) + parseFloat(content.amount_debit)
      })
    }
    return totalDebit
  }

  getTotalCredit () {
    var totalCredit = 0
    if(this.props.journalEntries.length>0){
      this.props.journalEntries.map((content)=>{
        totalCredit = parseFloat(totalCredit) + parseFloat(content.amount_credit)
      })
    }
    return totalCredit
  }
  render() {
    return (
      <div>
        <div className={styles.checkDetailsHeader}>
          <div>
            <h3>
              Journal Entries
            </h3>
          </div>
          <div className={this.props.forPrint? styles.noDisplay : null}>
            {this.props.payrollList.journal !==null?(
              this.props.payrollList.journal.is_posted !== true? (
            this.props.payrollList.journal.can_post === true ? (
              <Tooltip title="Post Journal" arrow>
                <IconButton onClick={this.props.activeJournal}>
                  <CheckCircleOutlineIcon/>
                </IconButton>
              </Tooltip>
            ):null
            ): this.props.payrollList.journal.can_unpost === true ? (
              <Tooltip title="Post Journal" arrow>
                <IconButton onClick={this.props.cancelJournal}>
                  <CancelIcon/>
                </IconButton>
              </Tooltip>
            ):null):null}
            {this.props.isPayrollDetails? null:
              <Tooltip title="Print Journal" arrow>
                <IconButton>
                  <a href={`/payrolls/${this.props.payrollList.id}/journal.pdf`} target="_blank"
                    rel="noopener noreferrer">
                    <PrintIcon/>
                  </a>
                </IconButton>
              </Tooltip>
            }
             {this.props.payrollList.can_recompute_journal?
              <Tooltip title="Recompute Journal" arrow>
                <IconButton
                  onClick={this.props.recomputeJournal}
                  color="primary"
                  small
                >
                  <ExposureOutlinedIcon/>
                </IconButton>
              </Tooltip>
              :null
            }
          </div>
        </div>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>
               <b> Account Code</b>
              </TableCell>
              <TableCell>
                <b>Account Title</b>
              </TableCell>
              <TableCell align="right">
                <b>Debit</b>
              </TableCell>
              <TableCell align="right">
                <b>Credit</b>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {this.props.journalEntries.sort(function (a, b) {
              if (a.account.code.toLowerCase() < b.account.code.toLowerCase()) return -1;
              if (a.account.code.toLowerCase() > b.account.code.toLowerCase()) return 1;
              return 0;
            }).map((journal) => (
                journal.amount_credit==="0.0" &&
            <TableRow  key={journal.id}>
              <TableCell>
                {journal.account.code}
              </TableCell>

              <TableCell>
                {journal.account.name}
              </TableCell>
              <TableCell align="right">
                {parseFloat(journal.amount_debit).toLocaleString(navigator.language, {minimumFractionDigits: 2})}
              </TableCell>
              <TableCell align="right">
                {parseFloat(journal.amount_credit).toLocaleString(navigator.language, {minimumFractionDigits: 2})}
              </TableCell>

            </TableRow>
            ))}
            {this.props.journalEntries.sort(function (a, b) {
              if (a.account.code.toLowerCase() < b.account.code.toLowerCase()) return -1;
              if (a.account.code.toLowerCase() > b.account.code.toLowerCase()) return 1;
              return 0;
            }).map((journal) => (
              journal.amount_credit!=="0.0"&&
              <TableRow  key={journal.id}>
                <TableCell>
                  {journal.account.code}
                </TableCell>
                <TableCell>
                  {journal.account.name}
                </TableCell>
                <TableCell align="right">
                  {parseFloat(journal.amount_debit).toLocaleString(navigator.language, {minimumFractionDigits: 2})}
                </TableCell>
                <TableCell align="right">
                  {parseFloat(journal.amount_credit).toLocaleString(navigator.language, {minimumFractionDigits: 2})}
                </TableCell>

              </TableRow>
            ))}
            <TableRow>
            <TableCell></TableCell>
            <TableCell align="right"><b> Total :</b></TableCell>
            <TableCell align="right"><b>₱ {this.getTotalDebit().toLocaleString(navigator.language, {minimumFractionDigits: 2})}</b></TableCell>
            <TableCell align="right"><b>₱ {this.getTotalCredit().toLocaleString(navigator.language, {minimumFractionDigits: 2})}</b></TableCell>
          </TableRow>

          </TableBody>
        </Table>
      </div>
    )
  }
}

export default JournalEntriesDetailTab

JournalEntriesDetailTab.propTypes = {
  classes: PropTypes.object,
  payrollList: PropTypes.object,
  journalEntries: PropTypes.array,
  activeJournal: PropTypes.func,
  cancelJournal: PropTypes.func,
  isPayrollDetails: PropTypes.bool,
  forPrint: PropTypes.bool,
  recomputeJournal: PropTypes.func
}
