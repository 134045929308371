import {
  Box,
  LinearProgress,
  List,
  ListItem,
  Typography,
} from "@material-ui/core";
import React, { Component } from "react";
import PropTypes from "prop-types";
import style from "./Annualization.module.css";
import CompanyHeader from "./../../shared/CompanyHeader/CompanyHeader";
import moment from "moment";
import axios from "axios";

class PayrollSlip extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetchingDepartment: true,
      department: {},
    };
    this.loadDepartments = this.loadDepartments.bind(this);
    this.numberFormatter = this.numberFormatter.bind(this);
  }

  componentDidMount() {
    const { selectedPayroll } = this.props;
    this.loadDepartments(selectedPayroll.department_id);
  }

  loadDepartments(id) {
    axios({
      method: "get",
      url: `/v1/departments/${id}`,
      headers: { "X-API-ACCESS-TOKEN": localStorage.getItem("api_key") },
    })
      .then((resp) => {
        this.setState({
          isFetchingDepartment: false,
          department: resp.data,
        });
      })
      .catch((error) => {
        this.setState({
          isFetchingDepartment: false,
          fetchError: true,
          errorMessage: error.message,
        });
      });
  }

  numberFormatter(value) {
    return value
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  render() {
    const { department, isFetchingDepartment } = this.state;
    const { selectedPayroll } = this.props;
    const {
      work_hours,
      payroll,
      taxable_income,
      other_income,
      employee_sss,
      employee_pagibig,
      employee_philhealth,
      employee_bir,
      total_deductions,
      position,
      employee,
    } = selectedPayroll;

    return (
      <>
        {isFetchingDepartment ? (
          <LinearProgress />
        ) : (
          <Box style={{ height: "100%" }}>
            <CompanyHeader forPrint={true} />
            <Typography variant="h2" className={style.sectionHeader}>
              Employee Information
            </Typography>
            <Box
              style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)" }}
            >
              <List>
                <ListItem style={{ gap: "10px", paddingInline: 0 }}>
                  <Typography component="span" style={{ fontWeight: 600 }}>
                    Employee:
                  </Typography>
                  <Typography component="span">{employee.name}</Typography>
                </ListItem>
                <ListItem style={{ gap: "10px", paddingInline: 0 }}>
                  <Typography component="span" style={{ fontWeight: 600 }}>
                    Position:
                  </Typography>
                  <Typography component="span">{position}</Typography>
                </ListItem>
              </List>
              <List>
                <ListItem style={{ gap: "10px", paddingInline: 0 }}>
                  <Typography component="span" style={{ fontWeight: 600 }}>
                    Department:
                  </Typography>
                  <Typography component="span">{department.name}</Typography>
                </ListItem>
                <ListItem style={{ gap: "10px", paddingInline: 0 }}>
                  <Typography component="span" style={{ fontWeight: 600 }}>
                    Payroll Period:
                  </Typography>
                  <Typography component="span">
                    {`${moment(payroll.date_from).format("MMM DD")} -
                ${moment(payroll.date_to).format("DD, YYYY")}`}
                  </Typography>
                </ListItem>
              </List>
            </Box>
            <Typography variant="h2" className={style.sectionHeader}>
              Work Hours
            </Typography>
            <List>
              <ListItem
                style={{
                  justifyContent: "space-between",
                  padding: 0,
                  paddingLeft: 200,
                }}
              >
                <Typography component="span" style={{ fontWeight: 600 }}>
                  Regular Hours
                  <span style={{ fontWeight: 300 }}> ({work_hours})</span>
                </Typography>
                <Typography component="span">
                  {this.numberFormatter(Number(taxable_income))}
                </Typography>
              </ListItem>
            </List>
            <Typography variant="h2" className={style.sectionHeader}>
              Other Income
            </Typography>
            <List>
              <ListItem
                style={{
                  justifyContent: "space-between",
                  padding: 0,
                  paddingLeft: 200,
                }}
              >
                <Typography component="span" style={{ fontWeight: 600 }}>
                  Meal Allowance:
                </Typography>
                <Typography component="span">
                  {this.numberFormatter(Number(other_income))}
                </Typography>
              </ListItem>
              <ListItem
                style={{
                  justifyContent: "space-between",
                  padding: 0,
                  paddingLeft: 200,
                  color: "green",
                }}
              >
                <Typography component="span" style={{ fontWeight: 600 }}>
                  Gross Income:
                </Typography>
                <Typography component="span" style={{ fontWeight: 600 }}>
                  {this.numberFormatter(
                    Number(taxable_income) + Number(other_income)
                  )}
                </Typography>
              </ListItem>
            </List>
            <Typography variant="h2" className={style.sectionHeader}>
              Deductions
            </Typography>
            <List>
              <ListItem
                style={{
                  justifyContent: "space-between",
                  padding: 0,
                  paddingLeft: 200,
                }}
              >
                <Typography component="span" style={{ fontWeight: 600 }}>
                  SSS:
                </Typography>
                <Typography component="span">
                  {this.numberFormatter(Number(employee_sss))}
                </Typography>
              </ListItem>
              <ListItem
                style={{
                  justifyContent: "space-between",
                  padding: 0,
                  paddingLeft: 200,
                }}
              >
                <Typography component="span" style={{ fontWeight: 600 }}>
                  PAGIBIG:
                </Typography>
                <Typography component="span">
                  {this.numberFormatter(Number(employee_pagibig))}
                </Typography>
              </ListItem>
              <ListItem
                style={{
                  justifyContent: "space-between",
                  padding: 0,
                  paddingLeft: 200,
                }}
              >
                <Typography component="span" style={{ fontWeight: 600 }}>
                  PHILHEALTH:
                </Typography>
                <Typography component="span">
                  {this.numberFormatter(Number(employee_philhealth))}
                </Typography>
              </ListItem>
              <ListItem
                style={{
                  justifyContent: "space-between",
                  padding: 0,
                  paddingLeft: 200,
                }}
              >
                <Typography component="span" style={{ fontWeight: 600 }}>
                  WITHOLDING TAX:
                </Typography>
                <Typography component="span">
                  {this.numberFormatter(Number(employee_bir))}
                </Typography>
              </ListItem>
              <ListItem
                style={{
                  justifyContent: "space-between",
                  padding: 0,
                  paddingLeft: 200,
                  color: "red",
                }}
              >
                <Typography component="span" style={{ fontWeight: 600 }}>
                  Total Deduction:
                </Typography>
                <Typography
                  component="span"
                  style={{ borderBottom: "2px solid maroon" }}
                >
                  {this.numberFormatter(Number(total_deductions))}
                </Typography>
              </ListItem>
              <ListItem
                style={{
                  justifyContent: "space-between",
                  padding: 0,
                  paddingLeft: 200,
                  color: "green",
                }}
              >
                <Typography component="span" style={{ fontWeight: 600 }}>
                  Net Pay:
                </Typography>
                <Typography
                  component="span"
                  style={{ borderBottom: "4px double maroon" }}
                >
                  {this.numberFormatter(
                    Number(taxable_income) +
                      Number(other_income) -
                      Number(total_deductions)
                  )}
                </Typography>
              </ListItem>
            </List>
          </Box>
        )}
      </>
    );
  }
}

export default PayrollSlip;

PayrollSlip.propTypes = {
  selectedPayroll: PropTypes.object,
};
