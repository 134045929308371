import React from 'react'
import styles from './CompanyHeader.module.css'
import PropTypes from 'prop-types'
class CompanyHeader extends React.Component {
  render () {
    return (
      <div>
        <div className={this.props.forPrint? styles.pdfHeader:styles.noDisplay}>
          <img src={'https://cdn-lexitech.sgp1.digitaloceanspaces.com/images/lexitech.png'} alt="Logo" style={{width: '30%'}}/>
            <div >
                <h2 style={{margin: '0'}}>Lex Integritas Technology Innovations, Inc.</h2>
            </div>
            <div>66 A &amp; B San Rafael St.,Brgy Kapitolyo, Pasig City 1603 <br/>
                Tel. No. (02) 8634 7141
            </div>
        </div>
      </div>
    )
  }
}
export default CompanyHeader

CompanyHeader.propTypes = {
  forPrint: PropTypes.bool

}
