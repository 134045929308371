import React from 'react'
import PropTypes from 'prop-types'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import { Typography, Grid } from '@material-ui/core'
import { Tooltip, Button, Menu, MenuItem } from '@material-ui/core'
import ExitToAppSharpIcon from '@material-ui/icons/ExitToAppSharp'
import style from '../../App.module.css'
import clsx from 'clsx'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Fade from '@material-ui/core/Fade'
import RefreshIcon from '@material-ui/icons/Refresh'

import LockOpenIcon from '@material-ui/icons/LockOpen'
import Divider from '@material-ui/core/Divider'
class Appbar extends React.Component {
    render() {
        return (
            <AppBar
                position="fixed"
                className={clsx(style.appBar, {
                    [style.appBarShift]: this.props.open
                })}
            >
                <Toolbar>
                    <Grid container spacing={1} alignItems="center">
                        <Grid item xs={12} sm={6}>
                            <div style={{ display: 'flex' }}>

                                <IconButton
                                    color="inherit"
                                    size="small"
                                    aria-label="open drawer"
                                    onClick={this.props.toggleDrawer}
                                    edge="start"
                                    className={clsx(style.menuButton, {
                                        [style.hide]: this.props.open
                                    })}
                                >
                                    <MenuIcon />
                                </IconButton>
                                <Typography variant="h6" noWrap
                                    style={{ flexGrow: 1 }}
                                >
                                    {this.props.title}
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6} className={style.userButtons}>

                            <h3 style={{ color: '#fff', margin: '8px' }}><b> Welcome, <span>{this.props.currentUser.name}</span></b></h3>
                            <Tooltip title="manage account" arrow>
                                <Button aria-controls="fade-menu" aria-haspopup="true" onClick={this.props.toggleDropdown}>
                                    {this.props.anchorEl ? <ExpandLessIcon style={{ color: '#fff' }} /> : <ExpandMoreIcon style={{ color: '#fff' }} />}
                                </Button>
                            </Tooltip>
                            <Menu
                                id="fade-menu"
                                anchorEl={this.props.anchorEl}
                                keepMounted
                                open={Boolean(this.props.anchorEl)}
                                onClose={this.props.handleClose}
                                TransitionComponent={Fade}
                                className={style.accountBody}
                            >

                                <MenuItem onClick={this.props.handleChangePassword} className={style.accountButton}><LockOpenIcon /> Change Password</MenuItem>
                                <Divider light />
                                <MenuItem onClick={this.props.logOut} className={style.accountButton}><ExitToAppSharpIcon />Log Out</MenuItem>
                            </Menu>
                            <Tooltip arrow title="Refresh Storage Data"><IconButton size="small" onClick={this.props.handleRefreshStorage} style={{ color: '#fff' }}><RefreshIcon /></IconButton></Tooltip>
                        </Grid>
                    </Grid>



                </Toolbar>
            </AppBar>
        )
    }
}
export default Appbar

Appbar.propTypes = {
    toggleDrawer: PropTypes.func,
    logOut: PropTypes.func,
    open: PropTypes.bool,
    title: PropTypes.string,
    currentUser: PropTypes.object,
    handleRefreshStorage: PropTypes.func,
    toggleDropdown: PropTypes.func,
    anchorEl: PropTypes.object,
    handleChangePassword: PropTypes.func,
    handleClose: PropTypes.func
}
